import React, { Component } from 'react';
import { change } from 'redux-form'
import { withLocalize, Translate } from "react-localize-redux";
import { withRouter } from 'react-router'

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';


// REDUX
import { connect } from 'react-redux';
import { compose } from 'redux'
import { orderActions, snackActions } from "../../_actions";

import { Headline } from '../../components/Headline';
import { Timeline } from '../../components/Timeline';
import { OrderReview } from '../../components/OrderReview';
import BasisForm from '../../components/OrderAddForm/BasisForm';
import FilesForm from '../../components/OrderAddForm/FilesForm';
import { OrderFiles } from './OrderFiles';
import FileDetailDialog from './components/FileDetailDialog';


const styles = theme => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper,
  },
  submitButton: {
    marginTop: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2
  },
});

function TabContainer(props) {
  return (
    <div>{props.children}</div>
  )
}


class EditOrder extends Component {
  constructor(props){
    super(props);
    this.openDialog = this.openDialog.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.handleSubmitReview = this.handleSubmitReview.bind(this);
    this.handleShowFormClick = this.handleShowFormClick.bind(this);
  }

  componentDidMount(){
    const { id } = this.props.match.params;
    this.props.getOne(id);
  }

  componentWillReceiveProps(nextProps){
    this.setState({ accepted: nextProps.order.status === "accepted" });
    // console.log('{ accepted: nextProps.order.status === "accepted" }',{ accepted: nextProps.order.status === "accepted" })
    // console.log('nextProps.order.status',nextProps.order.status)
  }

  state = {
    tabIndex: 0,
    dialogOpen: false,
    accepted: false,
    comment: "",
    showForm: false
  }

  openDialog(index) {
    // console.log({ dialogOpen: file });
    this.setState({ dialogOpen: !!String(index), fileIndex: index });
  }

  handleCheckbox(e) {
    console.log(e.target.name,e.target.checked);
    this.setState({ accepted: e.target.checked})
  }

  handleChangeComment(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleShowFormClick(){
    this.setState({ showForm: !this.state.showForm })
  }

  handleSubmitUploadClick() {
    const { values } = this.props.form.orderForm;
    const { order } = this.props;
    var formData = new FormData();

    if(values.newFiles)
      values.newFiles.forEach(file => {
        formData.append("files", file);
      });
    formData.append("history", JSON.stringify(order.history));
    formData.append("_id", order._id);
    this.props.updateOne(formData);
    console.log("clearing files now");
    this.props.change("newFiles", []);
  }

  handleSubmitBasicClick() {
    const { values } = this.props.form.orderForm;
    var formData = new FormData();

    Object.keys(values).forEach(key => {
      if(key !== 'newFiles' && key !== 'history'){
        if(key === 'packshot' || key === 'change')
          formData.append(key, JSON.stringify(values[key]));
        else
          formData.append(key, values[key]);
      }
    });
    formData.append("history", JSON.stringify(values.history));
    this.props.updateOne(formData);
    this.setState({ showForm: false });
  }

  handleTabChange = (event, step) => {
    const { id } = this.props.match.params;
    this.props.history.push(`/order/${id}/${step}`);
    if(step === 0)
      this.props.getOne(id);
    // this.setState({ tabIndex });
  };

  handleSubmitReview = (e) => {
    // console.log(this.state.accepted ? "accepted" : "rejected", this.props.order._id);
    this.props.updateReview(this.props.order._id, { status: this.state.accepted ? "accepted" : "rejected", comment: this.state.comment})
    this.props.history.push(`/order/${this.props.order._id}/0`);
  }

  next = () => {
    this.setState({
      fileIndex: this.state.fileIndex < this.props.order.files.length-1 ? this.state.fileIndex + 1 : 0
    });
  }

  prev = () => {
    this.setState({
      fileIndex: this.state.fileIndex > 0 ? this.state.fileIndex - 1 : this.props.order.files.length - 1
    });
  }

  remove = (fileId) => {
    const { order } = this.props;
    this.setState({ dialogOpen: false });
    this.props.removeFile(order._id, fileId);
  }

  render(){
    const { classes, order, form, isLoading, isSuperUser } = this.props;
    // const { id } = this.props.match.params;
    const { accepted, showForm } = this.state;
    const step = parseInt(this.props.match.params.step);
    const newFilesLength = (form.orderForm && form.orderForm.values && form.orderForm.values.newFiles) ? form.orderForm.values.newFiles.length : 0;

    if(order.name)
    return(
      <div className={classes.root}>
        <Headline variant="h5"><Translate id="order.edit.title" data={{ name: order ? order.name : null}} /></Headline>
        <Paper className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <AppBar position="static" className={classes.appBar}>
                <Tabs value={step} onChange={this.handleTabChange}>
                  <Tab label="Basisdaten" />
                  <Tab label={<Translate id="order.edit.tabs.files.tabtitle" data={{ filelength: order.files ? order.files.length : 0 }} />} />
                  <Tab label="Freigabe & Korrekturen" disabled={order.status === "new" } />
                  <Tab label="Verlauf" />
                </Tabs>
              </AppBar>
              { step === 0 && <TabContainer>
                  { showForm ? <BasisForm handleShowForm={this.handleShowFormClick}/> : <OrderReview handleShowForm={this.handleShowFormClick}/>}

                  {showForm && 
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.submitButton}
                      onClick={this.handleSubmitBasicClick.bind(this)}
                      disabled={isLoading}
                    >
                      Änderungen speichern
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.submitButton}
                      onClick={() => this.setState({ showForm: false })}
                      disabled={isLoading}
                    >
                      Abbrechen
                    </Button>
                  </div>
                  }
                </TabContainer>}
              { step === 1 && <TabContainer>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <OrderFiles openDialog={this.openDialog} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                      <FilesForm/>  
                    </div>
                    
                    <Button
                      onClick={this.handleSubmitUploadClick.bind(this)}
                      disabled={newFilesLength === 0}
                      variant="contained"
                      color="primary"
                    >
                      <Translate
                        id="order.edit.tabs.files.uploadBtn"
                        data={{
                          filelength: newFilesLength === 0 ? "" : newFilesLength
                        }}
                      />
                     </Button>
                  </Grid>
                </Grid>

              </TabContainer>}

              { step === 2 && <TabContainer>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12}>
                    <p>&nbsp;</p>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={accepted}
                            onChange={this.handleCheckbox}
                            color="primary"
                            name="accepted"
                          />
                        }
                        label="Freigeben"
                      />
                    </div>
                    <div>
                      { !this.state.accepted &&
                      <TextField
                        name="comment"
                        label="Korrektur"
                        multiline
                        rows="4"
                        value={this.state.comment}
                        onChange={this.handleChangeComment}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />}
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSubmitReview}
                      disabled={isLoading}
                    >
                      { accepted ? "Freigeben" : "Ablehnen"}
                    </Button>
                  </Grid>
                </Grid>
              </TabContainer>}
                
              { step === 3 && <TabContainer>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12}>
                    <Timeline order={order}/>
                    {/*order.history.map(h => (
                      <p key={h._id}>
                        {(h.context && h.context.was) ? `order.history.${h.action}` : `order.history.${h.action}_0`} - 
                        <Translate
                          id={(h.context && h.context.was) ? `order.history.${h.action}` : `order.history.${h.action}_0`}
                          data={{
                            username: h.username,
                            von: h.context ? h.context.was : "" || "",
                            auf: h.context ? h.context.is  : "" || ""
                          }}
                        /> 
                      </p>
                    ))*/}
                  </Grid>
                </Grid>
              </TabContainer>}
              
            </Grid>
          </Grid>
        </Paper>
        <FileDetailDialog
          index={this.state.fileIndex}
          open={this.state.dialogOpen}
          prev={this.prev}
          next={this.next}
          remove={this.remove}
          files={this.props.order.files}
          isSuperUser={isSuperUser}
          handleClose={() => this.setState({ dialogOpen: false })}
        />
      </div>
    )
    else return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Headline variant="h5">Auftrag nicht gefunden</Headline>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang,
    form: state.form,
    order: state.order.order,
    isLoading: state.order.isLoading,
    isSuperUser: state.authentication.user.isSuperUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOne: (orderId) => dispatch(orderActions.getOne(orderId)),
    updateOne: (order) => dispatch(orderActions.updateOne(order)),
    removeFile: (orderId, fileId) => dispatch(orderActions.removeFile(orderId, fileId)),
    updateReview: (orderId, data) => dispatch(orderActions.updateReview(orderId, data)),
    snack: (msg, variant) => dispatch(snackActions.show(msg, variant)),
    change: (field, value) => dispatch(change(field, value)),
  };
};

EditOrder = compose(
 withRouter,
 withLocalize,
 withStyles(styles),
 connect(mapStateToProps, mapDispatchToProps)
)(EditOrder);

export { EditOrder }