import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
// import { renderToStaticMarkup } from "react-dom/server";

// MUI
import { withStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
import CardHeader from '@material-ui/core/CardHeader';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// Components
import Logo from '../../img/logo.png';
import { Footer } from '../../components/Footer';

// Redux
// import { userActions } from '../../_actions';
import { uiSetLang } from '../../_actions/ui.actions';

const styles = theme => ({
  ...theme,
  root: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100vh',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'url(https://jde-mediabox.com/img)',
  },
  card: {
    ...theme.mixins.gutters(),
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing.unit * 2,
    float: 'none',
    minWidth: 360,
    maxWidth: 420,
    overflow: 'auto',
    
    [theme.breakpoints.between('xs','sm')]: {
      maxHeight: `calc(100vh - 0em)`,
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: `calc(100vh - 0em)`,
      marginTop: '0em',
      marginBottom: 110,
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: `calc(100vh - 3em)`,
      marginTop: '3em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '6em',
      maxHeight: `calc(100vh - 6em)`,
    },
    zIndex: 2,
  },
  formControl: {
    // margin: theme.spacing.unit,
    width: '100%'
  },
  button: {
    // marginTop: 20
  },
  regButton: {
    marginBottom: theme.spacing.unit*2
  },
  media: {
    height: 140,
    width: 140,
    margin: '0 auto',
    marginTop: 10
  },
  headText: {
    textAlign: 'center',
    lineHeight: '2.5em',
  },
  text: {
    textAlign: 'justify',
    lineHeight: '1.4em',
  },
  textP: {
    textAlign: 'justify',
    lineHeight: '2.5em',
  },
  header: {
    paddingRight: 0,
    height: 0,
  }
});

// const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;


class CookiesView extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount(){
    if(window.location.href.indexOf(".com") > 0 && !localStorage.getItem("lang")){
      this.props.setActiveLanguage("en");
      this.props.uiSetLang("en");
    }
    if(window.location.href.indexOf(".de") > 0 && !localStorage.getItem("lang")){
      this.props.setActiveLanguage("de");
      this.props.uiSetLang("de");
    }
  }

  render() {
    const { classes, loggedIn } = this.props;

    return(
      <div className={classes.root}>
        <Card className={classes.card} elevation={1}>
          { loggedIn &&
          <CardHeader
            className={classes.header}
            action={
              <IconButton component={Link} to="/">
                <CloseIcon/>
              </IconButton>
            }
          />
          }
          <CardMedia
            className={classes.media}
            image={Logo}
            title="JDE mediaBOX"
          />
          <Typography className={classes.headText} variant="h5" component="h3">
            Cookie Policy for JDE mediaBOX
          </Typography>

          <Typography variant="body2" className={classes.text}>This is the Cookie Policy for JDE mediaBOX, accessible from https://www.jde-mediabox.com</Typography>

          <Typography variant="body2" className={classes.text}><strong>What Are Cookies</strong></Typography>

          <Typography variant="body2" className={classes.text}>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</Typography>

          <Typography variant="body2" className={classes.text}>For more general information on cookies see the Wikipedia article on HTTP Cookies.</Typography>

          <Typography variant="body2" className={classes.text}><strong>How We Use Cookies</strong></Typography>

          <Typography variant="body2" className={classes.text}>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</Typography>

          <Typography variant="body2" className={classes.text}><strong>Disabling Cookies</strong></Typography>

          <Typography variant="body2" className={classes.text}>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</Typography>

          <Typography variant="body2" className={classes.text}><strong>The Cookies We Set</strong></Typography>

          <ul>


          <li>
              <Typography variant="body2" className={classes.text}>Login related cookies</Typography>
              <Typography variant="body2" className={classes.text}>We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</Typography>
          </li>





          <li>
              <Typography variant="body2" className={classes.text}>Site preferences cookies</Typography>
              <Typography variant="body2" className={classes.text}>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</Typography>
          </li>

          </ul>

          <Typography variant="body2" className={classes.text}><strong>Third Party Cookies</strong></Typography>

          <Typography variant="body2" className={classes.text}>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</Typography>

          <ul>


          <li>
              <Typography variant="body2" className={classes.text}>Third party analytics are used to track and measure usage of this site so that we can continue to produce engaging content. These cookies may track things such as how long you spend on the site or pages you visit which helps us to understand how we can improve the site for you.</Typography>
          </li>








          </ul>

          <Typography variant="body2" className={classes.text}><strong>More Information</strong></Typography>

          <Typography variant="body2" className={classes.text}>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. This Cookies Policy was created with the help of the Generator of <a href="https://cookiepolicygenerator.com">GDPR Cookies Policy</a> and the <a href="https://privacypolicygenerator.info">GDPR Privacy Policy</a>.</Typography>

          <Typography variant="body2" className={classes.text}>However if you are still looking for more information then you can contact us through one of our preferred contact methods:</Typography>

          <Typography variant="body2" className={classes.text}>Email: <strong>support@jde-mediabox.com</strong></Typography>


          <Typography className={classes.headText} variant="h5" component="h3">&nbsp;</Typography>
        </Card>
        <Footer layout="static" active="cookies" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authentication.loggedIn,
    lang: state.lang,
    localize: state.localize,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetLang: (lang) => dispatch(uiSetLang(lang)),
    // logout: () => dispatch(userActions.logout()),
    // login: (username,password) => dispatch(userActions.login(username,password)),
  };
};

// const Login = withStyles(styles)(CookiesView);
// export { Login }

const connectedCookiesPage =
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withLocalize
  )(CookiesView)

export { connectedCookiesPage as Cookies }; 