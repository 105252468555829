import React from 'react';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from "react-localize-redux";
import classNames from 'classnames';

import { connect } from "react-redux";
import { compose } from 'redux';
import { uiSetLang, uiGetLang } from '../../_actions';

const styles = theme => ({
  root: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 25,
    },
    paddingTop: 10,
    // fontSize: 85%,
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: '#614d45',
    color: 'rgba(255,255,255,.5)',
    fontSize: 12,
    position: 'fixed',
    
    left: 0,
    right: 0,
    bottom: 0,
  },
  dashboard: {
    zIndex: 1,
    display: 'flex',
  },
  static: {
    display: 'flex',
  },
  [theme.breakpoints.down('sm')]: {
    link: {
      color: 'rgba(255,255,255,0.5)',
      textDecoration: 'none',
      listStyle: 'none',
      paddingLeft: '0.5em',
      cursor: 'pointer',
      display: 'block',
    },
    links: {
      display: 'block',
      lineHeight: '1.25em',
    },
    root: {
      height: 100,
    }
  },
  [theme.breakpoints.up('md')]: {
    link: {
      color: 'rgba(255,255,255,.5)',
      textDecoration: 'none',
      listStyle: 'none',
      paddingLeft: '1em',
      cursor: 'pointer',
    },
    links: {
      display: 'flex',
    },
    root: {
      height: 50,
    }
  },
  hover: {
    '&:hover': {
      color: 'white'
    }
  },
  active: {
    color: 'white'
  }
});

class Footer extends React.Component {

  componentDidMount(){
    this.props.uiGetLang();
  }

  handleClick(lang) {
    // console.log(lang);
    this.props.setActiveLanguage(lang);
    this.props.uiSetLang(lang);
  }

  render(){
    const { classes, layout, translate, localize, loggedIn } = this.props;
    const link = classNames(classes.link, classes.hover);
    
    // if(layout === "dashboard")
    //   return(
    //     <div className={classNames(classes.root, classes.dashboard)}>
    //       <p className={link}><Link to="/" style={{textDecoration: 'none', color: 'inherit'}}>©{new Date().getFullYear()} JACOBS DOUWE EGBERTS</Link></p>
    //       <ul className={classes.links}>
    //         <li className={classes.link}>{translate("languages.title")}:</li>
    //         {
    //           localize.languages.map(l => (
    //             <li
    //               key={l.code}
    //               onClick={() => this.handleClick(l.code)}
    //               className={classNames(link,{ [classes.active]: l.active})}
    //             >
    //               {translate(`languages.${l.code}`)}
    //             </li>
    //           ))
    //         }
    //       </ul>
    //     </div>
    //   )

    return (
      <div className={classNames(classes.root, layout === "dashboard" ? classes.dashboard : classes.static)}>
        <p className={link}><Link to={loggedIn ? "/" : "/login"} style={{textDecoration: 'none', color: 'inherit'}}>©{new Date().getFullYear()} JACOBS DOUWE EGBERTS</Link></p>
        <ul className={classes.links}>
          { !loggedIn && <li className={classNames(link,{ [classes.active]: (this.props.active === 'login') })}><Link to="/login" style={{textDecoration: 'none', color: 'inherit'}}>{translate("login.title")}</Link></li> }
          <li className={classNames(link,{ [classes.active]: (this.props.active === 'imprint') })}><Link to="/imprint" style={{textDecoration: 'none', color: 'inherit'}}>{translate("imprint.title")}</Link></li>
          <li className={classNames(link,{ [classes.active]: (this.props.active === 'privacy') })}><Link to="/privacy" style={{textDecoration: 'none', color: 'inherit'}}>{translate("privacy.title")}</Link></li>
          {/*<li className={classNames(link,{ [classes.active]: (this.props.active === 'cookies') })}><Link to="/cookies" style={{textDecoration: 'none', color: 'inherit'}}>{translate("cookies.title")}</Link></li>*/}
          <li className={classNames(link,{ [classes.active]: (this.props.active === 'termsofservice') })}><Link to="/termsofservice" style={{textDecoration: 'none', color: 'inherit'}}>{translate("termsofservice.title")}</Link></li>
          <li className={classNames(link,{ [classes.active]: (this.props.active === 'contact') })}><Link to="/contact" style={{textDecoration: 'none', color: 'inherit'}}>{translate("contact.title")}</Link></li>
        </ul>

        <ul className={classes.links}>
          <li className={classes.link}>{translate("languages.title")}:</li>
          {
            localize.languages.map(l => (
              <li
                key={l.code}
                onClick={() => this.handleClick(l.code)}
                className={classNames(link,{ [classes.active]: l.active})}
              >
                {translate(`languages.${l.code}`)}
              </li>
            ))
          }
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.authentication.loggedIn,
    lang: state.lang,
    localize: state.localize,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetLang: (lang) => dispatch(uiSetLang(lang)),
    uiGetLang: () => dispatch(uiGetLang()),
  };
};

Footer = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withLocalize
)(Footer);

export { Footer }