import React from 'react';

import Grid from '@material-ui/core/Grid';

// import GridList from '@material-ui/core/GridList';

import { AssetCard } from '../AssetCard';

const GridView = (props) => {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={16}
      >
      { props.assets.map(item => (
        <Grid key={item.id} item>
          <AssetCard asset={item} />
        </Grid>
      ))}
      </Grid>
    </Grid>
  )
}


export { GridView }
