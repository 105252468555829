import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from "react-localize-redux";

// MUI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// ICONS
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';


// LOCAL
import {DownloadButton} from '../DownloadButton';

import {
  // cartGetOne,
  // cartRemoveOne,
  cartAddOneFormat,
  cartRemoveOneFormat,
  // cartDownload,
} from '../../_actions/cart.actions';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    width: '100%',
  },
  cell: {
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  }
});

// const handleCheckboxes = (assetId, mediaType, cartItems) => {
//   // const { cartItems } = this.props;
//   // console.log('handleCheckboxes',assetId, mediaType);
//   if(cartItems){
//     for(let item of cartItems) {
//       for(let file of item.files) {
//         if(item.id === assetId && mediaType === file.mediaType){
//           // console.log(assetId,mediaType,true);
//           return true;
//         }   
//       }
//     }
//     // console.log(assetId,mediaType,false);
//   }
// }


function handleChange(asset, e, props){
  // console.log(asset.id, e.target.name, e.target.checked)
  // console.log(asset.id, e.target.name);
  if(e.target.checked)
    props.cartAddOneFormat(asset.id, e.target.name);
  else
    props.cartRemoveOneFormat(asset.id, e.target.name);
}

function SimpleTable(props) {
  const { classes, asset, translate, lang, cartCheckboxState, jde } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>{translate("asset.downloadOptions")}</TableCell>
            <TableCell className={classes.cell}></TableCell>
            <TableCell className={classes.cell}></TableCell>
            <TableCell className={classes.cell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell} component="th" scope="row">{asset[`mediaTypeName_${lang}`]}</TableCell>
            <TableCell className={classes.cell}>
              { asset.fileProperties &&
                (`${asset.fileProperties.width}x${asset.fileProperties.height}px ${parseInt(asset.fileProperties.dpiX) > 0 ? `(${asset.fileProperties.dpiX} dpi)` : ""} ${asset.fileProperties.colorspace ? asset.fileProperties.colorspace : ""}` )
              }
            </TableCell>
            <TableCell className={classes.cell}>
              <DownloadButton
                link={asset.originalTicket}
                name={asset.name}
                jde={jde}
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={String(asset.mediaType)}
                    onChange={ (e) => handleChange(asset,e, props)}
                    color="primary"
                    // checked={true}
                    checked={!!(cartCheckboxState[`${asset.id}.${asset.mediaType}`])}
                  />
                }
                label={<AddShoppingCartIcon fontSize="small"/>}
              />
            </TableCell>
          </TableRow>
          { Object.keys(asset.relatedAsset).length > 0 &&
          <TableRow>
            <TableCell className={classes.cell} component="th" scope="row">{asset.relatedAsset[`mediaTypeName_${lang}`]}</TableCell>
            <TableCell className={classes.cell}>
            { asset.relatedAsset.fileProperties &&
              (`${asset.relatedAsset.fileProperties.width}x${asset.relatedAsset.fileProperties.height}px ${parseInt(asset.relatedAsset.fileProperties.dpiX) > 0 ? `(${asset.relatedAsset.fileProperties.dpiX} dpi)` : ""} ${asset.relatedAsset.fileProperties.colorspace ? asset.relatedAsset.fileProperties.colorspace : ""}` ) //``
            }
            </TableCell>
            <TableCell className={classes.cell}>
              <DownloadButton
                link={asset.relatedAsset.originalTicket}
                name={asset.name}
                jde={jde}
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={String(asset.relatedAsset.mediaType)}
                    onChange={ (e) => handleChange(asset,e, props)}
                    color="primary"
                    checked={!!(cartCheckboxState[`${asset.id}.${asset.relatedAsset.mediaType}`])}
                  />
                }
                label={<AddShoppingCartIcon fontSize="small"/>}
              />
            </TableCell>
          </TableRow>
          }
        </TableBody>
      </Table>
    </Paper>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    asset: state.asset,
    cartItems: state.cart.cartItems,
    lang: state.lang,
    cartCheckboxState: state.cartCheckboxState,
    jde: state.authentication.user.jde,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // cartGetOne: (id) => dispatch(cartGetOne(id)),
    cartAddOneFormat: (id, mediaType) => dispatch(cartAddOneFormat(id, mediaType)),
    cartRemoveOneFormat: (id, mediaType) => dispatch(cartRemoveOneFormat(id, mediaType)),
    // cartRemoveOne: (id) => dispatch(cartRemoveOne(id)),
    // cartDownload: () => dispatch(cartDownload()),
  };
};

const DetailViewDownloadOptions = withLocalize(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SimpleTable)));

export { DetailViewDownloadOptions }