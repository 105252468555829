import React from 'react';
import Typography from '@material-ui/core/Typography';

const PrivacyFrench = props => {
  const { classes } = props;
  return(
    <div>
          <Typography variant="body2" className={classes.text}>Nous nous réjouissons de votre visite sur nos pages internet et souhaitons vous informer sur le traitement de vos données personnelles conformément à l´article 13 du Règlement général sur la protection des données (RGPD).</Typography>
          
          <Typography variant="h6">Responsable du traitement</Typography>
          <Typography variant="body2" className={classes.text}>Le responsable de la collecte et du traitement des données décrits ci-dessous est la société mentionnée dans les mentions <a href="/imprint">légales</a>.</Typography>

          <Typography variant="h6">Enregistrement de votre adresse IP</Typography>
          <Typography variant="body2" className={classes.text}>Nous enregistrons l´adresse IP fournie par votre navigateur internet pendant une durée de sept jours, uniquement aux fins d´identifier, de contenir et d´éliminer des cyber-attaques contre notre site internet. Nous supprimons ou procédons à l´anonymisation de votre adresse IP à l´issue de ce délai. La base juridique de ce traitement est l´article 6 paragraphe 1 point f) du RGPD.</Typography>
          
          <Typography variant="h6">Données de connexion</Typography>
          <Typography variant="body2" className={classes.text}>Lors de votre visite sur nos pages internet, des données appelées données de connexion sont enregistrées temporairement sur notre serveur web à des fins statistiques, afin d´améliorer la qualité de nos pages internet. Cet ensemble de données est constitué de:</Typography>
          <Typography variant="body2" className={classes.text}>- la page à partir de laquelle le fichier a été demandé,</Typography>
          <Typography variant="body2" className={classes.text}>- le nom du fichier,</Typography>
          <Typography variant="body2" className={classes.text}>- la date et l´heure de la requête,</Typography>
          <Typography variant="body2" className={classes.text}>- le volume des données transférées,</Typography>
          <Typography variant="body2" className={classes.text}>- le statut d´accès (fichier transmis, fichier non trouvé),</Typography>
          <Typography variant="body2" className={classes.text}>- le type de navigateur internet utilisé,</Typography>
          <Typography variant="body2" className={classes.text}>- l´adresse IP tronquée de l´ordinateur utilisé, de sorte qu´il n´est plus possible d´établir un rapprochement avec une personne identifiable. </Typography>
          <Typography variant="body2" className={classes.text}>Ces données sont anonymisées avant leur enregistrement.</Typography>

          <Typography variant="h6">Prestataires de service</Typography>
          <Typography variant="body2" className={classes.text}>Nous faisons dans certains cas appel à des prestataires de service qui nous apportent assistance dans l´exploitation de nos pages internet (par ex. prestataire d´hébergement hosting, agences). Nos prestataires sont contractuellement strictement tenus au respect de nos instructions.</Typography>
          
          <Typography variant="h6">Cookies</Typography>
          <Typography variant="body2" className={classes.text}>Nous utilisons des cookies sur nos pages internet. Les cookies sont de petits fichiers textes qui sont déposés sur votre appareil et qui peuvent être lus. On différencie les cookies de session, qui sont supprimés dès que vous fermez votre navigateur, des cookies permanents, qui sont sauvegardés même après la fermeture de la session. Les cookies peuvent contenir des données permettant la reconnaissance de l´appareil utilisé. Certains cookies contiennent simplement des informations sur certains paramètres, qui n´ont pas de lien avec une personne identifiable.</Typography>
          <Typography variant="body2" className={classes.text}>Nous plaçons des cookies uniquement en rapport avec le service d´analyse web « Matomo » (cf. en détail ci-dessous).</Typography>
          <Typography variant="body2" className={classes.text}>Vous pouvez paramétrer votre navigateur de sorte qu´il vous informe lorsque des cookies sont déposés, ce qui vous permet un usage des cookies en toute transparence. Vous pouvez, en outre, à tout moment supprimer les cookies et empêcher le placement de nouveaux cookies en utilisant les fonctions de paramétrage de votre navigateur. Veuillez noter que dans ce cas l´affichage de nos pages internet peut ne pas être optimal et que certaines fonctionnalités peuvent techniquement ne plus être disponibles.</Typography>

          <Typography variant="h6">Matomo</Typography>
          <Typography variant="body2" className={classes.text}>Afin d´adapter nos pages internet aux besoins de nos visiteurs, nous établissons des profils d´utilisateur pseudonymisés à l´aide du service d´analyse web Matomo. Matomo utilise des cookies, lesquels sont placés sur votre appareil et qui peuvent être lus par nous. Ceci nous permet de reconnaître des visiteurs récurrents et de les comptabiliser comme tels. Aucun enregistrement de l´adresse IP non tronquée n´est effectué. Le traitement des données a pour base juridique votre consentement selon l´article 6 paragraphe 1 point a) RGPD.</Typography>
          <Typography variant="body2" className={classes.text}>Vous pouvez à tout moment vous opposer au traitement. Cochez à cette fin la case ci-dessous. Un cookie d´exclusion (opt-out cookie) sera alors installé dans votre navigateur, de sorte que Matomo ne collectera aucune donnée de votre session. Attention, si vous supprimez vos cookies, le cookie d´exclusion (opt-out cookie) sera également supprimé ; vous devrez, le cas échéant, de nouveau l´activer.</Typography>


          <iframe
            title="Opt-Out"
            style={{border: 0, height: '12em', width: '100%'}}
            src="https://tracking.jde-mediabox.com/index.php?module=CoreAdminHome&action=optOut&language=fr&backgroundColor=&fontSize=0.875rem&fontFamily=%22Roboto%22%2C%20%22Helvetica%22%2C%20%22Arial%22%2C%20sans-serif">
          </iframe>


          <Typography variant="h6">Espace d´accès réservé</Typography>
          <Typography variant="body2" className={classes.text}>Si vous souhaitez avoir accès aux illustrations actuelles de nos produits, vous devez au préalable vous enregistrer. Nous collectons alors vos données sur le fondement de l´article 6 paragraphe 1 point b) RGPD. Les données que vous nous avez communiquées lors de votre enregistrement, ou à l´occasion d´une demande d´information, sont utilisées pour vous identifier dans le système et pour vous fournir un contenu adapté conformément aux autorisations fournies. Vous pouvez, dans votre compte, modifier ou supprimer votre profil à tout moment. Les données sont supprimées automatiquement de notre système informatique, dès que les délais de conservation sont expirés, sauf, au cas particulier, où les données sont encore nécessaires (par exemple pour le recouvrement de créances en cas de créances impayées).</Typography>

          <Typography variant="h6">Sécurité des données</Typography>
          <Typography variant="body2" className={classes.text}>Afin de protéger vos données le mieux possible de tout accès non autorisé, nous mettons en œuvre des mesures techniques et organisationnelles. Nous utilisons pour nos pages internet une procédure de cryptage. Vos données sont transmises par internet de votre ordinateur à notre serveur, et inversement, au moyen d´un cryptage TLS. Vous pouvez vous en assurer en observant la barre d´état de votre navigateur, qui affiche le symbole du cadenas verrouillé, et la barre d´adresse, débutant par https://.</Typography>

          <Typography variant="h6">Vos droits en tant qu'utilisateur</Typography>
          <Typography variant="body2" className={classes.text}>Lors du traitement de vos données personnelles, le RGPD vous accorde certains droits en tant qu'utilisateur de site internet:</Typography>
          <Typography variant="body2" className={classes.textP}>1. Droit d´accès (article 15 RGPD):</Typography>
          <Typography variant="body2" className={classes.text}>Vous avez le droit d´obtenir la confirmation que des données à caractère personnel vous concernant sont ou ne sont pas traitées ; dans l´affirmative, vous disposez d´un droit d´accès à ces données personnelles ainsi qu´aux informations décrites à  l´article 15 RGPD.</Typography>
          <Typography variant="body2" className={classes.textP}>2. Droit de rectification et à l´effacement (articles 16 et 17 DSGVO):</Typography>
          <Typography variant="body2" className={classes.text}>Vous avez le droit d´obtenir dans les meilleurs délais la rectification des données personnelles vous concernant qui sont inexactes et, le cas échéant, d´obtenir que les données personnelles incomplètes soient complétées.</Typography>
          <Typography variant="body2" className={classes.text}>Vous disposez en outre du droit d´obtenir que les données personnelles vous concernant soient effacées dans les meilleurs délais, lorsque l´un des motifs visés à l´article 17 RGPD s´applique, par exemple, lorsque les données ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées.</Typography>
          <Typography variant="body2" className={classes.textP}>3. Droit à la limitation du traitement (article 18 RGPD):</Typography>
          <Typography variant="body2" className={classes.text}>Vous avez le droit d´obtenir la limitation du traitement lorsque l´une des conditions énoncées à l´article 18 RGPD est remplie, par exemple, lorsque vous vous êtes opposé au traitement en vertu de l´article 21 RGPD ou pendant la durée d´une vérification éventuelle, portant sur le point de savoir si nos intérêts légitimes prévalent sur vos intérêts en tant que personne concernée.</Typography>
          <Typography variant="body2" className={classes.textP}>4. Droit à la portabilité des données (article 20 RGPD):</Typography>
          <Typography variant="body2" className={classes.text}>Dans certains cas, tels que décrits à l´article 20 RGPD, vous disposez du droit de recevoir les données à caractère personnel vous concernant dans un format structuré, couramment utilisé et lisible par machine et, le cas échéant, du droit d´obtenir que ces données soient transmises à un tiers.</Typography>
          <Typography variant="body2" className={classes.textP}>5. Droit d´opposition (article 21 RGPD):</Typography>
          <Typography variant="body2" className={classes.text}>Si des données sont collectées sur le fondement de l´article 6 paragraphe 1 point f) RGPD (traitement de données nécessaire aux fins d´intérêts légitimes), vous disposez du droit, pour des raisons tenant à votre situation particulière, de vous opposer à tout moment au traitement. Nous ne traitons alors plus les données à caractère personnel, sauf s´il existe des motifs démontrables légitimes et impérieux pour le traitement qui prévalent sur vos intérêts, droits et libertés ou si le traitement sert à la constatation, l´exercice ou la défense de droits en justice.</Typography>
          <Typography variant="body2" className={classes.textP}>6. Droit d´introduire une réclamation auprès d´une autorité de contrôle:</Typography>
          <Typography variant="body2" className={classes.text}>Conformément à l´article 77 RGPD, vous disposez du droit d´introduire une réclamation auprès d´une autorité de contrôle, si vous êtes de l´avis que le traitement des données personnelles vous concernant contrevient à la réglementation relative à la protection des données. Le droit à réclamation peut être exercé auprès d´une autorité de contrôle en particulier de l´Etat membre dans lequel se trouve votre résidence habituelle, votre lieu de travail ou le lieu où la violation aurait été commise.</Typography>
          <Typography variant="body2" className={classes.text}>Coordonnées du délégué à la protection des données</Typography>
          <Typography variant="body2" className={classes.text}>Notre délégué à la protection des données se tient à votre disposition pour recueillir vos suggestions ou vous fournir des informations sur le thème de la protection des données:</Typography>
          <Typography variant="body2" className={classes.text}>Dr. Uwe Schläger</Typography>
          <Typography variant="body2" className={classes.text}>datenschutz nord GmbH</Typography>
          <Typography variant="body2" className={classes.text}>Konsul-Smidt-Straße 88</Typography>
          <Typography variant="body2" className={classes.text}>28217 Bremen, Allemagne</Typography>
          <Typography variant="body2" className={classes.text}>site web: <a href="https://www.datenschutz-nord-gruppe.de">www.datenschutz-nord-gruppe.de</a></Typography>
          <Typography variant="body2" className={classes.text}>e-mail: <a href="mailto:office@datenschutz-nord.de">office@datenschutz-nord.de</a></Typography>
    </div>
  )
}

export { PrivacyFrench }