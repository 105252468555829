import "isomorphic-fetch";
import axios from 'axios';
import { devendpoint } from '../api';
// import { authHeader } from '../_helpers';
import { handleResponse } from '../_helpers';


export const userService = {
    login,
    logout,
    // getAll,
    getInfo
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  return fetch(`${devendpoint}/user/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }
      return user;
    });
}

function logout() {
  // invalidate Token
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user && user.token ? user.token : ""}`
  }
  const requestOptions = {
    method: 'POST',
    headers
  };
  return fetch(`${devendpoint}/user/logout`, requestOptions).then(handleResponse)
  .catch(err => { localStorage.removeItem('user'); });
}

// function getAll() {
//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader()
//   };

//   return fetch(`${devendpoint}/user`, requestOptions).then(handleResponse);
// }

function getInfo() {
  const url = `${devendpoint}/user/`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'GET',
    headers,
    url,
  };
  return axios(options);
}