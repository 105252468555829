import "isomorphic-fetch";
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
// import { connect } from 'react-redux';
// import { compose } from 'redux'

// MUI
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
// import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// Components
import Logo from '../../img/logo.png';

const styles = theme => ({
  ...theme,
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'url(https://jde-mediabox.com/img)',
  },
  card: {
    ...theme.mixins.gutters(),
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing.unit * 2,
    float: 'none',
    minWidth: 300,
    maxWidth: 360,
    
    [theme.breakpoints.between('xs','sm')]: {
      marginTop: '0em',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '1em',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '3em',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '6em',
    },
  },
  formControl: {
    // margin: theme.spacing.unit,
    width: '100%'
  },
  button: {
    // marginTop: 20
  },
  regButton: {
    marginBottom: theme.spacing.unit*2
  },
  media: {
    height: 140,
    width: 140,
    margin: '0 auto',
    marginTop: 10
  },
  text: {
    textAlign: 'center',
    paddingBottom: 10,
    paddingTop: 10,
  },
  pwLostbutton: {
    padding: 0,
    textTransform: 'none'
  }, 
  pwLostaction: {
    float: 'right',
    padding: 0,
    paddingBottom: theme.spacing.unit * 2
  },
  actions: {
    display: 'block',
    padding: 0,
    paddingBottom: theme.spacing.unit * 2
  }
});

class ResetPasswordView extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  
    this.state = {
      error: true,
      isLoading: true,
      success: false,
      passwordsMatch: true,
      passwordIsStrong: true,
      password: '',
      passwordRepeat: ''
    }
  }

  componentDidMount() {
    document.title = `JDE mediaBOX | ${this.props.translate("passwordreset.title")}`;
    const { uuid } = this.props.match.params;
    this.setState({ isLoading: true })
    fetch(`https://api.jde-mediabox.com/user/reset_password/${uuid}`, {
      method: 'GET'
    })
    .then(response => response.json())
    .then(response => {
      this.props.setActiveLanguage(response.lang);
      this.setState({ error: !response.success, isLoading: false });
    })    
  }

  handleClick(event) {
    // event.preventDefault();
    const { uuid } = this.props.match.params;
    const { password } = this.state;
    
    this.setState({
      isLoading: true,
      error: false
    });

    fetch(`https://api.jde-mediabox.com/user/reset_password/${uuid}`, {
      method: 'POST',
      body: JSON.stringify({ password }),
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    })
    .then(response => response.json())
    .then(response => {
      const { success } = response;
      console.log(response);
      this.setState({ success: success, error: !success, isLoading: false });
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      passwordsMatch: !(e.target.name === 'passwordRepeat' && e.target.value !== this.state.password)
    });
    if(e.target.name === 'password'){
      if(e.target.value.length >= 8)
        this.setState({ passwordIsStrong: true })
      else
        this.setState({ passwordIsStrong: false })
    }

  }


  render() {
    const { classes, translate } = this.props;
    const { error, isLoading, success, password, passwordRepeat, passwordsMatch, passwordIsStrong } = this.state;

    const disabled = () => {
      if(error){
        // console.log('error',error);
        return true;
      }
      if(isLoading){
        // console.log('isLoading',isLoading);
        return true;
      }
      if(passwordRepeat.length <= 0){
        // console.log('passwordRepeat.length',passwordRepeat.length <= 0);
        return true;
      }
      if(!passwordsMatch){
        // console.log('passwordsMatch',passwordsMatch);
        return true;
      }
      if(!passwordIsStrong){
        // console.log('passwordIsStrong',passwordIsStrong);
        return true;
      }
      return false;
    }
    return(
      <div className={classes.root}>
        <Card className={classes.card} elevation={1}>
          <CardMedia
            className={classes.media}
            image={Logo}
            title="JDE mediaBOX"
          />
          { !error ? success ?
          <div>
            <Typography className={classes.text} variant="subtitle1" component="h3">
              {translate("passwordreset.successMsg")}
            </Typography>
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/login"
                fullWidth
              >
              Login
              </Button>
            </CardActions>
          </div>
              :
          <div>
            <Typography className={classes.text} variant="subtitle1" component="h3">
              {translate("passwordreset.title")}
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              name="password"
              label={translate("passwordreset.newPassword")}
              type="password"
              onChange={this.handleChange.bind(this)}
              fullWidth
              error={(!passwordsMatch || !passwordIsStrong)}
              value={password}
            />
            <TextField
              margin="dense"
              name="passwordRepeat"
              label={translate("passwordreset.newPasswordRepeat")}
              type="password"
              onChange={this.handleChange.bind(this)}
              fullWidth
              error={(!passwordsMatch || !passwordIsStrong)}
              value={passwordRepeat}
            />
            { !passwordsMatch && <FormHelperText error={!passwordsMatch}>{translate("passwordreset.errors.passNotMatching")}</FormHelperText> }
            { !passwordIsStrong && <FormHelperText error={!passwordIsStrong}>{translate("passwordreset.errors.passMinChar")}</FormHelperText> }
            <CardActions className={classes.actions}>
              <Button
                onClick={this.handleClick}
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={disabled()}
                fullWidth
              >
                {isLoading && (
                  <CircularProgress size={25} thickness={2} />
                )}
                {translate("passwordreset.submitBtn")}
              </Button>
            </CardActions>
          </div>
          : <Typography className={classes.text} variant="subtitle1" component="h3">
              {translate("passwordreset.errors.linkInvalid")}
            </Typography>
          }
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang
  }
}

const ResetPassword = connect(mapStateToProps)(withLocalize(withStyles(styles)(ResetPasswordView)));
export { ResetPassword }

