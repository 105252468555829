import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
// import { renderToStaticMarkup } from "react-dom/server";

// MUI
import { withStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
import CardHeader from '@material-ui/core/CardHeader';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// Components
import Logo from '../../img/logo.png';
import { Footer } from '../../components/Footer';
import { PrivacyGerman } from './PrivacyGerman';
import { PrivacyEnglish } from './PrivacyEnglish';
import { PrivacyFrench } from './PrivacyFrench';

// Redux
// import { userActions } from '../../_actions';
import { uiSetLang } from '../../_actions/ui.actions';

const styles = theme => ({
  ...theme,
  root: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100vh',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'url(https://jde-mediabox.com/img)',
  },
  card: {
    ...theme.mixins.gutters(),
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing.unit * 2,
    float: 'none',
    minWidth: 360,
    maxWidth: 420,
    overflow: 'auto',
    
    [theme.breakpoints.between('xs','sm')]: {
      maxHeight: `calc(100vh - 0em)`,
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: `calc(100vh - 0em)`,
      marginTop: '0em',
      marginBottom: 110,
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: `calc(100vh - 3em)`,
      marginTop: '3em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '6em',
      maxHeight: `calc(100vh - 6em)`,
    },
    zIndex: 2,
  },
  formControl: {
    // margin: theme.spacing.unit,
    width: '100%'
  },
  button: {
    // marginTop: 20
  },
  regButton: {
    marginBottom: theme.spacing.unit*2
  },
  media: {
    height: 140,
    width: 140,
    margin: '0 auto',
    marginTop: 10
  },
  headText: {
    textAlign: 'center',
    lineHeight: '2.5em',
  },
  text: {
    textAlign: 'justify',
    lineHeight: '1.4em',
  },
  textP: {
    textAlign: 'justify',
    lineHeight: '2.5em',
  },
  header: {
    paddingRight: 0,
    height: 0,
  }
});

// const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

class PrivacyView extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount(){
    document.title = `JDE mediaBOX | ${this.props.translate("privacy.title")}`;
    if(window.location.href.indexOf(".com") > 0 && !localStorage.getItem("lang")){
      this.props.setActiveLanguage("en");
      this.props.uiSetLang("en");
    }
    if(window.location.href.indexOf(".de") > 0 && !localStorage.getItem("lang")){
      this.props.setActiveLanguage("de");
      this.props.uiSetLang("de");
    }
  }

  render() {
    const { classes, translate, loggedIn, lang } = this.props;
    return(
      <div className={classes.root}>
        <Card className={classes.card} elevation={1}>
          { loggedIn &&
          <CardHeader
            className={classes.header}
            action={
              <IconButton component={Link} to="/">
                <CloseIcon/>
              </IconButton>
            }
          />
          }
          <CardMedia
            className={classes.media}
            image={Logo}
            title="JDE mediaBOX"
          />
          <Typography className={classes.headText} variant="h5" component="h3">
            {translate("privacy.title")}
          </Typography>
          { lang === "de" && <PrivacyGerman {...this.props}/> }
          { lang === "en" && <PrivacyEnglish {...this.props}/> }
          { lang === "fr" && <PrivacyFrench {...this.props}/> }
          <Typography className={classes.headText} variant="h5" component="h3">&nbsp;</Typography>
        </Card>
        <Footer layout="static" active="privacy" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authentication.loggedIn,
    lang: state.lang,
    localize: state.localize,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetLang: (lang) => dispatch(uiSetLang(lang)),
    // logout: () => dispatch(userActions.logout()),
    // login: (username,password) => dispatch(userActions.login(username,password)),
  };
};

// const Login = withStyles(styles)(PrivacyView);
// export { Login }

const connectedPrivacyPage =
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withLocalize
  )(PrivacyView)

export { connectedPrivacyPage as Privacy }; 