import axios from 'axios';
import { devendpoint } from './';

export const assetApi = {
  requestRemove
};


// function getAll() {
//   const url = `${devendpoint}/aset`;
//   const user = JSON.parse(localStorage.getItem('user'));
//   const headers = {
//     'Accept': 'application/json',
//     'Content-Type': 'application/json',  
//     'Authorization': `Bearer ${user.token}`
//   }
//   const options = {
//     method: 'GET',
//     headers,
//     url,
//   };
//   return axios(options);
// }

// function getOne(asetId) {
//   const url = `${devendpoint}/aset/${asetId}`;
//   const user = JSON.parse(localStorage.getItem('user'));
//   const headers = {
//     'Accept': 'application/json',
//     'Content-Type': 'application/json',  
//     'Authorization': `Bearer ${user.token}`
//   }
//   const options = {
//     method: 'GET',
//     headers,
//     url,
//   };
//   return axios(options);
// }

function requestRemove(assetId) {
  const url = `${devendpoint}/asset/${assetId}`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    // 'Accept': 'application/json',
    // 'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'DELETE',
    headers,
    data: { assetId },
    url,
  };
  return axios(options);
}
