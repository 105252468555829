import React, {Component} from 'react';
// import PropTypes from 'prop-types';

// import { withStyles } from '@material-ui/core/styles';
// import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

// REDUX
import { cartAddOne } from '../../_actions/'
import { connect } from 'react-redux';

class AddButtonComponent extends Component {
  render(){
    const { assetId, cartAddOne, view, isLoading } = this.props;
    return(
      <IconButton size="small" aria-label="Add" onClick={() => cartAddOne(assetId)} disabled={isLoading || this.props.disabled}>
        <AddIcon fontSize={view === "list" ? "small" : "default"}/>
      </IconButton>  
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    cartAddOne: (id) => dispatch(cartAddOne(id))
  };
};

const mapStateToProps = state => {
  return {
    view: state.view,
    isLoading: state.cartIsLoading,
  }
}

const AddButton = connect(mapStateToProps, mapDispatchToProps)(AddButtonComponent)
export { AddButton }