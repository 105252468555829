import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';
import ReactPiwik from 'react-piwik';

const DownloadButton = (props) => {
  const { link, name, jde } = props;
  const handleClick = () => {
    ReactPiwik.push(['trackEvent', name ? name : window.document.title, jde ? "Intern" : "Extern" ]);
    window.open(link);
  }
  return(
    <IconButton aria-label="Download" onClick={handleClick}><DownloadIcon fontSize="small"/></IconButton>
  )
}

export { DownloadButton }