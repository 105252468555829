// import React from 'react';
import { connect } from 'react-redux';
import { uiSetLang } from '../../_actions';
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";

function setLanguage(props){
  const { lang } = props;
  props.uiSetLang(lang);
  props.setActiveLanguage(lang);
  props.history.push('/');
  return null;
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetLang: (lang) => dispatch(uiSetLang(lang)),
  };
};

const SetLang = withLocalize(withRouter(connect(null,mapDispatchToProps)(setLanguage)));

export { SetLang }
