export const orderConstants = {
  ORDERS_REQUEST: 'ORDERS_REQUEST',
  ORDERS_SUCCESS: 'ORDERS_SUCCESS',
  ORDERS_FAILURE: 'ORDERS_FAILURE',
  
  ORDER_REQUEST: 'ORDER_REQUEST',
  ORDER_SUCCESS: 'ORDER_SUCCESS',
  ORDER_FAILURE: 'ORDER_FAILURE',
  
  ORDER_UPT_REQUEST: 'ORDER_UPT_REQUEST',
  ORDER_UPT_SUCCESS: 'ORDER_UPT_SUCCESS',
  ORDER_UPT_FAILURE: 'ORDER_UPT_FAILURE',
  
  ORDER_REMOVE_FILE_REQUEST: 'ORDER_REMOVE_FILE_REQUEST',
  ORDER_REMOVE_FILE_SUCCESS: 'ORDER_REMOVE_FILE_SUCCESS',
  ORDER_REMOVE_FILE_FAILURE: 'ORDER_REMOVE_FILE_FAILURE',
  
  ORDER_UPLOAD_LOADING: 'ORDER_UPLOAD_LOADING',
  ORDER_UPLOAD_SUCCESS: 'ORDER_UPLOAD_SUCCESS',
  
  ORDER_SET_STATUS_REQUEST: 'ORDER_SET_STATUS_REQUEST',
  ORDER_SET_STATUS_SUCCESS: 'ORDER_SET_STATUS_SUCCESS',
  ORDER_SET_STATUS_FAILURE: 'ORDER_SET_STATUS_FAILURE',  
};
