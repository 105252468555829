import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'
import MetisMenu from 'react-metismenu';
import { connect } from "react-redux";
// import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import { withLocalize } from "react-localize-redux";
import { withRouter } from 'react-router'
// import LinearProgress from '@material-ui/core/LinearProgress';

import {
  uiSetCurrentContainer,
  // uiSetCountry,
  // nodesFetchData,
  // setRootId
} from '../../_actions/';

import RouterLink from 'react-metismenu-router-link';

/* LOCAL */
import { SearchFilter } from '../SearchFilter';
import { CountrySwitcher } from '../CountrySwitcher';

/* CSS */
import './style.css'
import './font-awesome.min.css'

const menuStyle = theme => ({
  root: {
    display: 'block',
    height: 'auto',
    overflow: 'hidden'
  },
  back: {
    fontSize: '0.9em',
    paddingLeft: theme.spacing.unit * 2,
  }
});

class CustomLink extends React.Component {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    if (this.props.hasSubMenu) this.props.toggleSubMenu(e);
    else {
      /*
       * your own operation using "to"
       * myGotoFunc(this.props.to);
       * or
       * this.props.activateMe(Parameters to pass "onSelected" event listener);
       */

      this.props.activateMe({
        active: true,
        newLocation: this.props.to,
        selectedMenuLabel: this.props.label,
      });
      console.log(this.props);
      // this.setState({ activeLinkId: parseInt(this.props.to) });
    }
  }

  render() {
    var className = 'metismenu-link';
    if (this.props.active)
      className += ' ' + this.props.classNameActive;
    let children = [...this.props.children];
    // children[1] = children[1].replace(/#?([0-9]{3})\s/,"XXX")
    if(children[1])
      children[1] = String(children[1].replace(/#?([0-9]{3})\s/,""))
    return (
      <Link className={className} onClick={this.onClick} to={`/${this.props.id}`}>
        {children}
      </Link>
    );
  }
};

const trunc = (str,n) => {
  return (str.length > n) ? str.substr(0, n-1) + '…' : str;
}


class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
    }
  }

  shouldComponentUpdate(nextProps){
    // if(nextProps.container !== this.props.container){
    //   return true;
    // }
    return true;
  }

  render(){
    const { classes, nodes, nodesIsLoading, container, searchActive, lang, rootId } = this.props;
    const {pathname} = this.props.location;

    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    }

    // const { activeLinkId } = this.state;
    let content = [];
    if(!nodes[rootId])
      return null;
    for (var i = 0; i < nodes[rootId].length; i++) {
      var node = nodes[rootId][i];
    // }
    // nodes[rootId].forEach(node => {
      let label = node.name[lang] ? trunc(node.name[lang], (node.name[lang].match(/#?([0-9]{3})\s/) ? 30 : 25)) : trunc(node.name["de"],(node.name["de"].match(/#?([0-9]{3})\s/) ? 30 : 25))
      let obj = {
        id: node.id,
        label,
        hasChildren: node.hasChildren
      };
      if(!node.hasChildren)
        obj.to = String(node.id);
      else
        obj.hasSubMenu = true;
      if(node.parentId !== rootId)
        obj.parentId = node.parentId ;
      content.push(obj);
    // });
    }

    if(nodesIsLoading)
      return (
        <div>
        </div>
      );

      // return <LinearProgress color="primary"  />
    if(pathname.indexOf("/order") >= 0)
      return (
        <div>
          <Link to="/" className={'metismenu-link '+classes.back}>Zurück</Link>
          <Divider/>
          <MetisMenu
            // className={classes.root}
            // activeLinkId={"orders"}
            activeLinkFromLocation
            content={[
              { to: "/orders", label: "Aktuelle Aufträge", hasChildren: false },
              { to: "/order/add", label: "Auftrag erstellen", hasChildren: false },
            ]}
            // ref="menu"
            // LinkComponent={CustomLink}
            LinkComponent={RouterLink}
          />
        </div>
      );



    return (
      <div>
        <CountrySwitcher {...this.props} />
        <MetisMenu
          className={classes.root}
          // activeLinkId={activeLinkId}
          activeLinkId={container}
          content={content.sort(compare)}
          ref="menu"
          LinkComponent={CustomLink}
        />
        { searchActive &&
          <div>
            <Divider/>
            <SearchFilter/>
          </div>
        }
      </div>
    )
  }
}
Menu = withStyles(menuStyle)(Menu);

const mapStateToProps = state => {
  return {
    nodes: state.nodes,
    asset: state.asset,
    container: state.container,
    nodesIsLoading: state.nodesIsLoading,
    nodesHasErrored: state.nodesHasErrored,
    authentication: state.authentication,
    metisMenuStore: state.metisMenuStore,
    searchActive: state.searchActive,
    search: state.search,
    lang: state.lang,
    country: state.country,
    rootId: state.rootId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetCurrentContainer: (id) => dispatch(uiSetCurrentContainer(id)),
  };
};

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(Menu)));
// export { Menu }