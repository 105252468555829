import axios from 'axios';
import { devendpoint } from './';
// import {connect} from 'react-redux';
// import { orderActions } from '../_actions'
import configureStore from '../_store/configureStore';

const store = configureStore();


export const orderApi = {
  getAll,
  getOne,
  addOne,
  search,
  updateOne,
  updateReview,
  removeFile,
  deactivateOne,
};


function getAll() {
  const url = `${devendpoint}/order`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'GET',
    headers,
    url,
  };
  return axios(options);
}

function getOne(orderId) {
  const url = `${devendpoint}/order/${orderId}`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'GET',
    headers,
    url,
  };
  return axios(options);
}

function addOne(order) {
  const url = `${devendpoint}/order`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    // 'Accept': 'application/json',
    // 'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'PUT',
    headers,
    data: order,
    url,
  };
  return axios(options);
}

function removeFile(orderId, fileId) {
  const url = `${devendpoint}/order`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'DELETE',
    headers,
    data: {orderId, fileId},
    url,
  };
  return axios(options);
}

function deactivateOne(orderId) {
  const url = `${devendpoint}/order/${orderId}`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'DELETE',
    headers,
    data: {orderId},
    url,
  };
  return axios(options);
}

function updateOne(order) {
  const url = `${devendpoint}/order`;
  // console.log(url);
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    // 'Accept': 'application/json',
    // 'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const action = (type, loaded) => store.dispatch({type, loaded})
  const options = {
    method: 'POST',
    headers,
    data: order,
    url,
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      // console.log({percentCompleted});
      action('ORDER_UPLOAD_LOADING', percentCompleted);
      // return percentCompleted;
    }
  };
  return axios(options);
}

function updateReview(orderId, data) {
  const url = `${devendpoint}/order/${orderId}`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = { 
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'POST',
    headers,
    data,
    url
  };
  return axios(options);
}

function search(data, customField) {
  const url = `${devendpoint}/order/search`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'POST',
    headers,
    data: { data, customField },
    url
  };
  return axios(options);
}

// store.subscribe(updateOne);

// const mapStateToProps = state => ({
//   order: state.order.order
// })

// const mapDispatchToProps = dispatch => ({
//   updateLoading: (percentage) => dispatch(orderActions.updateLoading(percentage))
// })

// const updateOne = connect(mapStateToProps,mapDispatchToProps)(updateOneFn);
