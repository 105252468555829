import React from 'react';

import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import Slide from '@material-ui/core/Slide';
// import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

import { withLocalize } from "react-localize-redux";
import { withRouter } from 'react-router'
import { isMobile } from "react-device-detect";
import { compose } from 'redux';

// REDUX STUFF
import { connect } from 'react-redux';
import {
  cartGetOne,
  // cartRemoveOne,
  // cartAddOneFormat,
  // cartRemoveOneFormat,
  cartDownload,
  cartClear,
  cartSend,
  cartSendReset,
  cartDownloadList
} from '../../_actions/cart.actions';

// COMPONENTS
import { CartTable } from '../../components/CartTable';
import { Headline } from '../../components/Headline';
import { SnackBar } from '../../components/SnackBar';
import { Tooltip } from '@material-ui/core';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

const isEmail = (email = null) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const SendDialog = props => {
  const { copyCheckbox, handleCopyCheckboxChange, handleChangeForm, sendForm, translate, isLoading, cartSent } = props;
  const open = props.openSendDialog;
  const handleClose = props.handleOpenSendDialogClick;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={isLoading}
    >
      <DialogTitle id="form-dialog-title">
        { cartSent ? translate("cart.sendForm.successTitle") : translate("cart.sendForm.title") }
      </DialogTitle>
      <DialogContent>
        <TextField
          error={!isEmail(sendForm["email"]) && sendForm["email"].length > 0}
          disabled={cartSent}
          onChange={ (e) => handleChangeForm(e) }
          autoFocus
          margin="dense"
          name="email"
          value={sendForm["email"]}
          label={translate("cart.sendForm.receiver")}
          type="email"
          fullWidth
        />
        <TextField
          disabled={cartSent}
          onChange={ (e) => handleChangeForm(e) }
          margin="dense"
          name="subject"
          value={sendForm["subject"]}
          label={translate("cart.sendForm.subject")}
          type="text"
          fullWidth
        />
        <TextField
          disabled={cartSent}
          onChange={ (e) => handleChangeForm(e) }
          margin="dense"
          name="text"
          value={sendForm["text"]}
          label={translate("cart.sendForm.text")}
          type="text"
          fullWidth
          multiline
          rows="4"
        />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              disabled={cartSent}
              checked={copyCheckbox}
              onChange={handleCopyCheckboxChange}
              value="copy"
            />
          }
          label={translate("cart.sendForm.copy")}
        />
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          { cartSent ? translate("cart.sendForm.closeBtn") : translate("cart.sendForm.cancelBtn") }
        </Button>
        <Button onClick={props.handleSendCart} color="primary" disabled={isLoading || cartSent || !isEmail(sendForm["email"]) }>
          {translate("cart.sendForm.sendBtn")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DownloadOptions = (props) => {
  const { classes, isLoading, cartItems, translate, showClearMessage, handleDownloadClick, handleDownloadListClick, handleOpenSendDialogClick, handleClearClick } = props;
  let files = cartItems || []
  // console.log(cartItems);
  files = files.filter(i=> i.files.length > 0);
  return (
    <div>
      <Tooltip title={translate("cart.tooltip.download")}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleDownloadClick}
          disabled={isMobile || isLoading || (files.length === 0)}
        >
          { isLoading ? translate("cart.loading") : translate("cart.download") }
          <DownloadIcon className={classes.rightIcon} />
        </Button>
      </Tooltip>

      <Tooltip title={translate("cart.tooltip.list")}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleDownloadListClick}
          disabled={isMobile || isLoading || (files.length === 0)}
        >
          { isLoading ? translate("cart.loading") : translate("cart.list") }
          <DescriptionIcon className={classes.rightIcon} />
        </Button>
      </Tooltip>

      <Tooltip title={translate("cart.tooltip.send")}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleOpenSendDialogClick}
          disabled={isLoading || (files.length === 0)}
          >
          {translate("cart.send")}
          <SendIcon className={classes.rightIcon}/>
        </Button>
      </Tooltip>
      
      <Tooltip title={translate("cart.tooltip.clear")}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={ () => handleClearClick(false) }
          disabled={isLoading}
          >
          {translate("cart.clear.btn")}
          <DeleteIcon className={classes.rightIcon}/>
        </Button>
      </Tooltip>

        {showClearMessage && 
        <span>
          {translate("cart.clear.sure")}
          <Button size="small" className={classes.button} variant="contained" color="primary" onClick={ () => handleClearClick(true) }>
            {translate("cart.clear.yes")}
          </Button>
          <Button size="small" className={classes.button} variant="contained" color="primary" onClick={ () => handleClearClick(false) }>
            {translate("cart.clear.no")}
          </Button>
        </span>
        }


      <SendDialog {...props} />
    </div>
    )
}

class CartView extends React.Component {
  constructor(props){
    super(props);
    // this.props.cartGetOne();
    // this.handleDelete = this.handleDelete.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handleDownloadListClick = this.handleDownloadListClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleOpenSendDialogClick = this.handleOpenSendDialogClick.bind(this);
    this.handleCopyCheckboxChange = this.handleCopyCheckboxChange.bind(this);
    this.handleChangeForm = this.handleChangeForm.bind(this);
    this.handleSendCart = this.handleSendCart.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  }

  componentDidMount(){
    this.props.cartSendReset();
    document.title = `JDE mediaBOX | ${this.props.translate("cart.title")}`;
  }

  state = {
    showClearMessage: false,
    openSendDialog: false,
    sendForm: {
      email: "",
      subject: "",
      text: "",
      copyCheckbox: false,
    }
  }

  // handleDelete(id) {
  //   this.props.cartRemoveOne(id);
  // }

  handleChangeForm(e){
    var sendForm = {...this.state.sendForm};
    sendForm[e.target.name] = e.target.value;
    this.setState({ sendForm });

  }

  handleOpenSendDialogClick(){
    this.setState({ openSendDialog: !this.state.openSendDialog });
    if(!this.state.openSendDialog){
      this.setState({ sendForm: { email: "", subject: "", text: "", copyCheckbox: false } });
      this.props.cartSendReset();
    }
  }

  handleClearClick(bool) {
    if(bool) {
      this.props.cartClear();
      // console.log("BOOM!!");
    }
    this.setState({ showClearMessage: !this.state.showClearMessage });
  }

  handleDownloadClick() {
    this.props.cartDownload();
  }
  
  handleDownloadListClick() {
    this.props.cartDownloadList();
    // console.log(this.props);
  }

  handleCopyCheckboxChange(){
    var sendForm = {...this.state.sendForm};
    sendForm.copyCheckbox = !this.state.sendForm.copyCheckbox;
    this.setState({ sendForm });
  }

  handleSendCart(){
    this.props.cartSend(this.state.sendForm, this.props.lang);
    this.setState({ openSendDialog: !this.state.openSendDialog });
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      this.props.cartSendReset();
      // console.log('clickaway')
      // return;
    }
    this.props.cartSendReset();
    // this.setState({ open: false });
  };

  render(){
    const { cartItems, cartSent, translate } = this.props;
    const { showClearMessage, openSendDialog, copyCheckbox, sendForm } = this.state;
    // console.log('cartItems',cartItems);
    return (
      <div className="root">
        <Headline>{translate("cart.title")}</Headline>
        <CartTable />
        { !cartItems || cartItems.length === 0 ? translate("cart.emptyMsg") :
        <DownloadOptions
          {...this.props}
          handleDownloadClick={this.handleDownloadClick}
          handleDownloadListClick={this.handleDownloadListClick}
          handleClearClick={this.handleClearClick}
          showClearMessage={showClearMessage}
          handleOpenSendDialogClick={this.handleOpenSendDialogClick}
          openSendDialog={openSendDialog}
          handleCopyCheckboxChange={this.handleCopyCheckboxChange}
          copyCheckbox={copyCheckbox}
          handleChangeForm={this.handleChangeForm}
          sendForm={sendForm}
          handleSendCart={this.handleSendCart}
        />
        }
        <SnackBar
          open={cartSent}
          message={translate("cart.sendForm.successTitle")}
          variant="success"
          onClose={this.handleSnackbarClose}
        />
      </div>
    )
  }  
}

const mapDispatchToProps = (dispatch) => {
  return {
    cartGetOne: (id) => dispatch(cartGetOne(id)),
    cartDownload: () => dispatch(cartDownload()),
    cartDownloadList: () => dispatch(cartDownloadList()),
    cartClear: () => dispatch(cartClear()),
    cartSend: (form, lang) => dispatch(cartSend(form, lang)),
    cartSendReset: () => dispatch(cartSendReset()),
  };
};

const mapStateToProps = state => {
  // console.log('mapStateToProps',state);
  let cartItems;
  if(state.cart.cartItems)
    cartItems = state.cart.cartItems;
  return {
    cart: state.cart,
    cartSent: state.cartSent,
    isLoading: state.cartDownloadIsLoading || state.cartIsLoading || state.cartDownloadListIsLoading,
    cartItems: cartItems,
    lang: state.lang,
  }
}

const Cart = compose(
  withStyles(styles),
  withRouter,
  withLocalize,
  connect(mapStateToProps, mapDispatchToProps)
)(CartView);
export { Cart }