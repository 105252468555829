import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
// import { renderToStaticMarkup } from "react-dom/server";

// MUI
import { withStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
import CardHeader from '@material-ui/core/CardHeader';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// Components
import Logo from '../../img/logo.png';
import { Footer } from '../../components/Footer';


// Redux
// import { userActions } from '../../_actions';
import { uiSetLang } from '../../_actions/ui.actions';

const styles = theme => ({
  ...theme,
  root: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100vh',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'url(https://jde-mediabox.com/img)',
  },
  card: {
    ...theme.mixins.gutters(),
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing.unit * 2,
    float: 'none',
    minWidth: 360,
    maxWidth: 420,
    overflow: 'auto',
    
    [theme.breakpoints.between('xs','sm')]: {
      maxHeight: `calc(100vh - 0em)`,
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: `calc(100vh - 0em)`,
      marginTop: '0em',
      marginBottom: 110,
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: `calc(100vh - 3em)`,
      marginTop: '3em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '6em',
      maxHeight: `calc(100vh - 6em)`,
    },
    zIndex: 2,
  },
  formControl: {
    // margin: theme.spacing.unit,
    width: '100%'
  },
  button: {
    // marginTop: 20
  },
  regButton: {
    marginBottom: theme.spacing.unit*2
  },
  media: {
    height: 140,
    width: 140,
    margin: '0 auto',
    marginTop: 10
  },
  headText: {
    textAlign: 'center',
    lineHeight: '2.5em',
  },
  text: {
    textAlign: 'justify',
    lineHeight: '1.4em',
  },
  textP: {
    textAlign: 'justify',
    lineHeight: '2.5em',
  },
  header: {
    paddingRight: 0,
    height: 0,
  }
});

// const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

class TermsOfServiceView extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount(){
    if(window.location.href.indexOf(".com") > 0 && !localStorage.getItem("lang")){
      this.props.setActiveLanguage("en");
      this.props.uiSetLang("en");
    }
    if(window.location.href.indexOf(".de") > 0 && !localStorage.getItem("lang")){
      this.props.setActiveLanguage("de");
      this.props.uiSetLang("de");
    }
  }

  render() {
    const { classes, translate, loggedIn, lang } = this.props;
    return(
      <div className={classes.root}>
        <Card className={classes.card} elevation={1}>
          { loggedIn &&
          <CardHeader
            className={classes.header}
            action={
              <IconButton component={Link} to="/">
                <CloseIcon/>
              </IconButton>
            }
          />
          }
          <CardMedia
            className={classes.media}
            image={Logo}
            title="JDE mediaBOX"
          />
          <Typography className={classes.headText} variant="h5" component="h3">
            {translate("termsofservice.title")}
          </Typography>
          { lang === "de" && 
          <div id="german">
            <Typography variant="body2" className={classes.text}>Alle Inhalte, die in der JACOBS DOUWE EGBERTS Deutschland Mediabox abrufbar sind, sind geistiges Eigentum der JACOBS DOUWE EGBERTS Deutschland GmbH. Sämtliche Packungsbilder und sonstigen grafischen Gestaltungen sowie die individuellen Produktbeschreibungen sind urheberrechtlich geschützt. Alle in der JACOBS DOUWE EGBERTS Deutschland Mediabox genannten Produktnamen sind Marken der JACOBS DOUWE EGBERTS Deutschland GmbH bzw. ihrer Lizenzgeber, gleichgültig, ob sie herausgestellt und mit dem Symbol ® gekennzeichnet sind oder nicht. </Typography>
            <p>&nbsp;</p>
            <Typography variant="body2" className={classes.text}>Die Verwendung der Mediabox-Inhalte (Logos, Produkt- und Promotionsabbildungen, Insertionsvorlagen) der JACOBS DOUWE EGBERTS Deutschland Mediabox ist nur im Rahmen von Werbemaßnahmen der Handels- und Ausstellungspartner sowie für die Arbeit der Agenturen gestattet und auf die Zeit der jeweiligen Zusammenarbeit mit JACOBS DOUWE EGBERTS Deutschland begrenzt. Eine Bearbeitung, Umgestaltung oder sonstige Änderung der Mediabox-Inhalte ist nicht erlaubt. Ausgenommen ist die Schwarz-/Weiß-Wiedergabe der Logos, Insertionsvorlagen, Produkt- und Promotionsabbildungen sowie die Veränderung der Abbildungsgröße bis max. 150 %. Außerhalb dieser Nutzungserlaubnis ist der Gebrauch der Mediabox-Inhalte ausdrücklich untersagt. JACOBS DOUWE EGBERTS Deutschland behält sich ausdrücklich das Recht vor, seine Einwilligung zur Verwendung der Inhalte der JACOBS DOUWE EGBERTS Deutschland Mediabox zu widerrufen, sofern der Nutzungsberechtigte dieser Nutzungserlaubnis zuwider handelt.</Typography>
            <p>&nbsp;</p>
            <Typography variant="body2" className={classes.text}>Die in der JACOBS DOUWE EGBERTS Deutschland Mediabox enthaltenen Produktinformationen und Abbildungen wurden auf ihre Richtigkeit eingehend geprüft. JACOBS DOUWE EGBERTS Deutschland kann jedoch nicht völlig ausschließen, dass die Angaben zu den Artikeln und die damit verbundenen Grafiken, die in der JACOBS DOUWE EGBERTS Deutschland Mediabox veröffentlicht werden, technische oder typografische Fehler enthalten. JACOBS DOUWE EGBERTS Deutschland behält sich das Recht vor, jederzeit Änderungen vorzunehmen. Der Download erfolgt auf eigenes Risiko. Schadensersatzansprüche können nur geltend gemacht werden, soweit sie (I) auf Vorsatz oder grober Fahrlässigkeit von JACOBS DOUWE EGBERTS Deutschland GmbH oder seiner Erfüllungsgehilfen, (II) auf einer fahrlässigen Verletzung einer vertragswesentlichen Pflicht von JACOBS DOUWE EGBERTS Deutschland GmbH oder seiner Erfüllungsgehilfen, (III) auf dem Fehlen einer zugesicherten Eigenschaft oder (IV) auf einer zwingenden gesetzlichen Haftung beruhen. Bei leichter Fahrlässigkeit ist die Haftung von JACOBS DOUWE EGBERTS Deutschland GmbH auf den Ersatz des vertragstypischen, voraussehbaren Schadens beschränkt. JACOBS DOUWE EGBERTS Deutschland GmbH haftet in dem Umfang, wie sein Verschulden im Verhältnis zu anderen Ursachen an der Entstehung des Schadens mitgewirkt hat. Im Übrigen ist die Haftung von JACOBS DOUWE EGBERTS Deutschland GmbH ausgeschlossen. Alle Einschränkungen der Haftung gelten nicht, soweit es um Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit geht.</Typography>
            <p>&nbsp;</p>
            <Typography variant="body2" className={classes.text}>Copyright © {new Date().getFullYear()}, JACOBS DOUWE EGBERTS Deutschland GmbH. Alle Rechte vorbehalten</Typography>
          </div>
          }
          { lang === "en" && 
          <div id="german">
            <Typography variant="body2" className={classes.text}>All content available in the JACOBS DOUWE EGBERTS Deutschland Mediabox is the intellectual property of JACOBS DOUWE EGBERTS Deutschland GmbH. All packaging images and other graphic designs as well as the individual product descriptions are protected by copyright. All product names mentioned in the JACOBS DOUWE EGBERTS Deutschland Mediabox are trademarks of JACOBS DOUWE EGBERTS Deutschland GmbH or its licensors, regardless of whether they are highlighted and marked with the ® symbol or not.</Typography>
            <p>&nbsp;</p>
            <Typography variant="body2" className={classes.text}>The use of the Mediabox contents (logos, product and promotion images, induction_templates) of the JACOBS DOUWE EGBERTS Deutschland Mediabox is only permitted within the scope of advertising measures of the trade and exhibition partners as well as for the work of the agencies and is limited to the time of the respective cooperation with JACOBS DOUWE EGBERTS Deutschland. Editing, redesigning or any other modification of the Mediabox content is not permitted. This excludes the black/white reproduction of logos, insertion templates, product and promotion images as well as changes in image size up to a maximum of 150 %. The use of the Mediabox contents is expressly prohibited outside of this usage permit. JACOBS DOUWE EGBERTS Deutschland expressly reserves the right to revoke its consent to the use of the contents of the JACOBS DOUWE EGBERTS Deutschland Mediabox, provided that the person entitled to use the contents contravenes this permission.</Typography>
            <p>&nbsp;</p>
            <Typography variant="body2" className={classes.text}>The product information and illustrations contained in the JACOBS DOUWE EGBERTS Deutschland Mediabox have been thoroughly checked for correctness. However, JACOBS DOUWE EGBERTS Deutschland cannot completely exclude the possibility that the information on the articles and related graphics published in the JACOBS DOUWE EGBERTS Deutschland Mediabox may contain technical or typographical errors. JACOBS DOUWE EGBERTS Deutschland reserves the right to make changes at any time. The download is at your own risk. Claims for damages can only be asserted if (I) they are based on intent or gross negligence on the part of JACOBS DOUWE EGBERTS Deutschland GmbH or its vicarious agents, (II) they are based on a negligent breach of a material contractual obligation by JACOBS DOUWE EGBERTS Deutschland GmbH or its vicarious agents, (III) they are based on the absence of a warranted quality or (IV) they are based on mandatory statutory liability. In the event of slight negligence, the liability of JACOBS DOUWE EGBERTS Deutschland GmbH shall be limited to compensation for the foreseeable damage typical of the contract. JACOBS DOUWE EGBERTS Deutschland GmbH shall be liable to the extent that its fault in relation to other causes has contributed to the occurrence of the damage. Otherwise the liability of JACOBS DOUWE EGBERTS Deutschland GmbH is excluded. All limitations of liability shall not apply in the case of damage resulting from injury to life, limb or health.</Typography>
            <p>&nbsp;</p>
            <Typography variant="body2" className={classes.text}>Copyright © {new Date().getFullYear()}, JACOBS DOUWE EGBERTS Deutschland GmbH. All rights reserved.</Typography>
          </div>
          }
          { lang === "fr" && 
          <div id="german">
            <Typography variant="body2" className={classes.text}>Tous les contenus disponibles dans la JACOBS DOUWE EGBERTS Deutschland Mediabox sont la propriété intellectuelle de JACOBS DOUWE EGBERTS Deutschland GmbH. Toutes les images d'emballage et autres graphiques ainsi que les descriptions individuelles des produits sont protégées par le droit d'auteur. Tous les noms de produits mentionnés dans la JACOBS DOUWE EGBERTS Deutschland Mediabox sont des marques déposées de JACOBS DOUWE EGBERTS Deutschland GmbH ou de ses concédants de licence, qu'ils soient ou non mis en évidence et signalés par le symbole ®.</Typography>
            <p>&nbsp;</p>
            <Typography variant="body2" className={classes.text}>L'utilisation des contenus Mediabox (logos, images de produits et de promotion, modèles d'induction_) de la JACOBS DOUWE EGBERTS Deutschland Mediabox n'est autorisée que dans le cadre des mesures publicitaires des partenaires commerciaux et d'exposition ainsi que pour le travail des agences et est limitée au temps de la coopération respective avec JACOBS DOUWE EGBERTS Deutschland. L'édition, la refonte ou toute autre modification du contenu de Mediabox n'est pas autorisée. Ceci exclut la reproduction en noir et blanc des logos, des gabarits d'insertion, des images de produits et de promotion ainsi que les changements de taille d'image jusqu'à un maximum de 150 %. L'utilisation des contenus de Mediabox est expressément interdite en dehors de cette autorisation d'utilisation. JACOBS DOUWE EGBERTS Deutschland se réserve expressément le droit de révoquer son consentement à l'utilisation du contenu de la JACOBS DOUWE EGBERTS Deutschland Mediabox, à condition que la personne autorisée à utiliser le contenu viole cette autorisation.</Typography>
            <p>&nbsp;</p>
            <Typography variant="body2" className={classes.text}>Les informations sur les produits et les illustrations contenues dans la JACOBS DOUWE EGBERTS Deutschland Mediabox ont fait l'objet d'un contrôle approfondi de leur exactitude. Toutefois, JACOBS DOUWE EGBERTS Deutschland ne peut exclure complètement la possibilité que les informations sur les articles et les graphiques associés publiés dans la JACOBS DOUWE EGBERTS Deutschland Mediabox puissent contenir des erreurs techniques ou typographiques. JACOBS DOUWE EGBERTS Deutschland se réserve le droit d'apporter des modifications à tout moment. Le téléchargement se fait à vos propres risques. Les droits à dommages-intérêts ne peuvent être revendiqués que si (I) ils sont fondés sur une faute intentionnelle ou une négligence grave de la part de JACOBS DOUWE EGBERTS Deutschland GmbH ou de ses auxiliaires d'exécution, (II) ils sont fondés sur une violation par négligence d'une obligation contractuelle essentielle par JACOBS DOUWE EGBERTS Deutschland GmbH ou ses auxiliaires d'exécution, (III) ils sont basés sur une qualité non garantie, (IV) ils sont fondés sur une responsabilité légale obligatoire. En cas de négligence légère, la responsabilité de JACOBS DOUWE EGBERTS Deutschland GmbH est limitée à la réparation du dommage prévisible typique du contrat. JACOBS DOUWE EGBERTS Deutschland GmbH est responsable dans la mesure où sa faute par rapport à d'autres causes a contribué à l'apparition du dommage. Dans le cas contraire, la responsabilité de JACOBS DOUWE EGBERTS Deutschland GmbH est exclue. Toutes les limitations de responsabilité ne s'appliquent pas en cas de dommages résultant d'une atteinte à la vie, à l'intégrité physique ou à la santé.</Typography>
            <p>&nbsp;</p>
            <Typography variant="body2" className={classes.text}>Copyright © {new Date().getFullYear()}, JACOBS DOUWE EGBERTS Deutschland GmbH. Tous droits réservés.</Typography>
          </div>
          }
          <Typography className={classes.headText} variant="h5" component="h3">&nbsp;</Typography>
        </Card>
        <Footer layout="static" active="termsofservice" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authentication.loggedIn,
    lang: state.lang,
    localize: state.localize,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetLang: (lang) => dispatch(uiSetLang(lang)),
    // logout: () => dispatch(userActions.logout()),
    // login: (username,password) => dispatch(userActions.login(username,password)),
  };
};

// const Login = withStyles(styles)(TermsOfServiceView);
// export { Login }

const connectedTermsOfServicePage =
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withLocalize
  )(TermsOfServiceView)

export { connectedTermsOfServicePage as TermsOfService }; 