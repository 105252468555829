import { orderConstants } from '../_constants'

const initialState = {
  orders:[],
  order: {},
  upload: {
    loaded: 0
  }
}

export function order(state = initialState, action) {
  switch (action.type) {
    case orderConstants.ORDERS_SUCCESS:
      return Object.assign({}, state, { orders: action.orders })
    case orderConstants.ORDERS_REQUEST:
      return Object.assign({}, state, { isLoading: action.isLoading })
    case orderConstants.ORDERS_HAS_ERRORED:
      return Object.assign({}, state, { hasErrored: action.hasErrored })

    case orderConstants.ORDER_SUCCESS:
      return Object.assign({}, state, { order: action.order })
    case orderConstants.ORDER_REQUEST:
      return Object.assign({}, state, { isLoading: action.isLoading })
    case orderConstants.ORDER_HAS_ERRORED:
      return Object.assign({}, state, { hasErrored: action.hasErrored })

    case "@@redux-form/RESET":
      return Object.assign({}, state, { order: {} })
    
    case orderConstants.ORDER_UPT_SUCCESS:
      return Object.assign({}, state, { order: action.order })
    case orderConstants.ORDER_UPT_REQUEST:
      return Object.assign({}, state, { isLoading: action.isLoading })
    case orderConstants.ORDER_UPT_HAS_ERRORED:
      return Object.assign({}, state, { hasErrored: action.hasErrored })
    
    case orderConstants.ORDER_REMOVE_FILE_SUCCESS:
      return Object.assign({}, state, { order: action.order })
    case orderConstants.ORDER_REMOVE_FILE_REQUEST:
      return Object.assign({}, state, { isLoading: action.isLoading })
    case orderConstants.ORDER_REMOVE_FILE_HAS_ERRORED:
      return Object.assign({}, state, { hasErrored: action.hasErrored })
    
    case orderConstants.ORDER_UPLOAD_LOADING:
      return Object.assign({}, state, { upload: { loaded: action.loaded } })
    case orderConstants.ORDER_UPLOAD_SUCCESS:
      return Object.assign({}, state, { upload: { success: action.success } })

    case orderConstants.ORDER_ADD:
      return Object.assign({}, state, {
        orders: [
          ...state.orders,
          action.order
        ]
      })
    default:
      return state;
  }
}
