import React, { Component } from 'react'
import { connect } from 'react-redux';

/* MUI */
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

/* COMPONENTS */
// import { cartGetOne } from '../../_actions/cart.actions';
// import { downloadGetAll } from '../../_actions/download.actions';
import { RootCard } from '../../components/RootCard';
// import { assetFetchData } from '../../_actions/asset.actions';

// import './style.css'

class HomeView extends Component {
  // constructor(props){
  //   super(props);
  // }

  componentDidMount() {
    // this.props.cartGetOne();
    // this.props.downloadGetAll();
    document.title = `JDE mediaBOX | Home`;
  }

  // componentDidMount() {
  //   const { id } = this.props.match.params;
  //   this.props.fetchData(id);
  // }

  render() {
    const { nodes, nodesIsLoading, rootId } = this.props;
    
    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const nameA = a.name["de"].toUpperCase();
      const nameB = b.name["de"].toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    }

    if(!nodes[rootId]) return null;
    const shown = nodes[rootId].filter(node => (node.parentId === rootId)).sort(compare);

    return (
      <Grid container style={{flexGrow: 1}} spacing={16} >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={16}
          >
          { nodesIsLoading ? <CircularProgress/> : 
             shown.map(item => (
              <RootCard key={item.id} node={item}/>
            ))
          }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}


// const mapStateToProps = (state) => {
//   return {
//     asset: state.asset,
//     hasErrored: state.assetHasErrored,
//     isLoading: state.assetIsLoading
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     fetchData: (id) => dispatch(assetFetchData(id))
//   };
// };

function mapStateToProps(state) {
  // const { authentication } = state;
  // const { user } = authentication;
  return {
    nodes: state.nodes,
    nodesIsLoading: state.nodesIsLoading,
    rootId: state.rootId,
  };
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     cartGetOne: (id) => dispatch(cartGetOne(id)),
//     downloadGetAll: () => dispatch(downloadGetAll()),
//   };
// };


// const Home = connect(mapStateToProps, mapDispatchToProps)(HomeView)
// export { Home }

const connectedHomePage = connect(mapStateToProps)(HomeView);
export { connectedHomePage as Home };
