import axios from 'axios';
import { devendpoint, devheaders } from './';
export const cartApi = {
  getOne,
  getAll,
  addOne,
  addOneFormat,
  delOne,
  delOneFormat,
  clear,
  send,
  list,
  download,
  addAllFormat,
  delAllFormat,
  addManyFormat,
  delManyFormat,
};

function getOne(cartId = "") {
  const url = `${devendpoint}/cart/${cartId}`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'GET',
    headers,
    url,
  };
  return axios(options);
}

function getAll() {
  const url = `${devendpoint}/cart/all`;
  const options = {
    method: 'GET',
    headers: devheaders,
    // data: qs.stringify(data),
    url,
  };
  return axios(options); 
}

function addOne(assetId) {
  const url = `${devendpoint}/cart/`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'PUT',
    headers,
    data: { assetId },
    url,
  };
  return axios(options);
}

function addOneFormat(assetId, format) {
  const url = `${devendpoint}/cart/`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'PUT',
    headers,
    data: { assetId, format },
    url,
  };
  return axios(options);
}

function addManyFormat(assetIds, format) {
  const url = `${devendpoint}/cart/`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'PUT',
    headers,
    data: { assetIds, format },
    url,
  };
  return axios(options);
}

function delOneFormat(assetId, format) {
  const url = `${devendpoint}/cart/`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'DELETE',
    headers,
    data: { assetId, format },
    url,
  };
  return axios(options);
}

function delManyFormat(assetIds, format) {
  const url = `${devendpoint}/cart/`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'DELETE',
    headers,
    data: { assetIds, format },
    url,
  };
  return axios(options);
}


function delOne(assetId) {
  const url = `${devendpoint}/cart/`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'DELETE',
    headers,
    data: { assetId: assetId },
    url,
  };
  return axios(options);
}

function clear() {
  const url = `${devendpoint}/cart/clear`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'POST',
    headers,
    url,
  };
  return axios(options);
}

function send(form, lang) {
  const url = `${devendpoint}/cart/send`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'POST',
    headers,
    data: { form, lang },
    url,
  };
  // console.log(options);
  return axios(options);
}

function list() {
  const url = `${devendpoint}/cart/list`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'GET',
    headers,
    url,
    responseType: 'blob'
  };
  return axios(options);
}

function download(cartId) {
  const url = `${devendpoint}/cart/download`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'GET',
    headers,
    url,
  };
  return axios(options);
}

function addAllFormat(assetIds, format) {
  const url = `${devendpoint}/cart/`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'PUT',
    headers,
    data: { assetIds, format },
    url,
  };
  return axios(options);
}

function delAllFormat(assetIds, format) {
  const url = `${devendpoint}/cart/`;
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',  
    'Authorization': `Bearer ${user.token}`
  }
  const options = {
    method: 'DELETE',
    headers,
    data: { assetIds, format },
    url,
  };
  return axios(options);
}