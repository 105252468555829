import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { withLocalize, Translate } from "react-localize-redux";

import { DetailView } from '../../components/DetailView';
import { GridView } from '../../components/GridView';

import { connect } from 'react-redux';
// import {  } from '../../_actions/asset.actions';
// import {  } from '../../_actions/assets.actions';
import { assetsFetchData, assetFetchData, uiSetCurrentContainer } from '../../_actions/';

// import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class AssetView extends Component {
  // constructor(props){
  //   super(props);
  // }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchData(id);
    this.props.getAssets(this.props.container);
    // console.log('this.props.getAssets',this.props.container);
    // this.props.uiSetCurrentContainer(this.props.asset.container);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.asset.container && this.props.container !== nextProps.asset.container){
      // console.log('Asset.js sets container from',this.props.container,'to',nextProps.asset.container);
      this.props.uiSetCurrentContainer(nextProps.asset.container);   
    }
    if(nextProps.asset.name)
      document.title = `JDE mediaBOX | ${nextProps.asset.name}`;
    const currentContainer = this.props.container;
    const nextContainer = nextProps.container;
    if(currentContainer !== nextContainer)
      this.getAssets(nextProps);
    const current = `${this.props.location.pathname}${this.props.location.search}`
    const next = `${nextProps.location.pathname}${nextProps.location.search}`
    if (current === next) {
     return
    }
    this.getAsset(nextProps);
  }

  getAsset(props) {
    const { id } = props.match.params;
    this.props.fetchData(id);
  }

  getAssets(props) {
    // console.log('this.getAssets',props.container);
    const { container } = props;
    this.props.getAssets(container);
  }

  render () {
    const { nodes, rootId, container, hasErrored,  asset, translate, assets, lang, isLoading } = this.props;

    const containerName = () => {
      if(!nodes[rootId])
        return "";
      const c = nodes[rootId].filter(node => node.id === container);
      if(c[0])
        return c[0].name[lang] ? c[0].name[lang] : c[0].name["de"];
      return "";
    }

    if(hasErrored)
      return <p>{translate("asset.errors.notFound")}</p>
    if(asset.mediaType !== 1100)
      return <p></p>;
    if(isLoading)
      return <Grid container spacing={0}><CircularProgress style={{margin: '0 auto'}} /></Grid>;
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <DetailView asset={asset} isLoading={isLoading} />
          <Typography variant="h5" style={{marginBottom: '0.5em', marginTop: '1em'}}>
            { (!isLoading && assets.length > 0) ?
            <Translate
              id={assets.length-1 === 1 ? "asset.moreAsset" : "asset.moreAssets"}
              data={{
                num: assets.length-1,
                containerName: containerName()
              }}
            /> : null
            }
          </Typography>
          <GridView assets={assets.filter(a => a.id !== asset.id)}/>
        </Grid>
      </Grid>
    );
  
  }
 
}

const mapStateToProps = (state) => {
  return {
    assets: state.assets,
    asset: state.asset,
    lang: state.lang,
    hasErrored: state.assetHasErrored,
    isLoading: (state.assetIsLoading || state.assetsIsLoading),
    container: state.container,
    nodes: state.nodes,
    rootId: state.rootId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAssets: (id) => dispatch(assetsFetchData(id)),
    fetchData: (id) => dispatch(assetFetchData(id)),
    uiSetCurrentContainer: (id) => dispatch(uiSetCurrentContainer(id))
  };
};


const Asset = withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetView)))

export { Asset }
