import React from 'react';

// HOC
import { connect } from 'react-redux';
import { withLocalize } from "react-localize-redux";
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

// MUI
import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';

// LOCAL
import {
  uiSetCountry,
  nodesFetchData,
  setRootId  
} from '../../_actions';

function Flag({country, children, checked}) {
  let icon;
  if(country === "germany" || country === "de")
    icon = <svg enableBackground="new 0 0 512 335.4" viewBox="0 0 512 335.4" xmlns="http://www.w3.org/2000/svg"><path d="m512 111.8h-512v-103c0-4.8 4-8.8 8.8-8.8h494.3c4.9 0 8.8 4 8.8 8.8z" fill="#464655"/><path d="m503.2 335.4h-494.4c-4.9 0-8.8-4-8.8-8.8v-103h512v103c0 4.9-4 8.8-8.8 8.8z" fill="#ffe15a"/><path d="m0 111.8h512v111.8h-512z" fill="#ff4b55"/></svg>
  else if(country === "austria" || country === "at")
    icon = <svg enableBackground="new 0 0 512.001 512.001" viewBox="0 0 512.001 512.001" xmlns="http://www.w3.org/2000/svg"><path d="m512 200.093h-512v-102.989c0-4.875 3.953-8.828 8.828-8.828h494.345c4.875 0 8.828 3.953 8.828 8.828z" fill="#ff4b55"/><path d="m503.172 423.725h-494.344c-4.875 0-8.828-3.953-8.828-8.828v-102.988h512v102.988c0 4.876-3.953 8.828-8.828 8.828z" fill="#ff4b55"/><path d="m0 200.091h512v111.81h-512z" fill="#f5f5f5"/></svg>
  else if(country === "switzerland" || country === "ch")
    icon = <svg enableBackground="new 0 0 512.001 512.001" viewBox="0 0 512.001 512.001" xmlns="http://www.w3.org/2000/svg"><path d="m503.172 423.725h-494.344c-4.875 0-8.828-3.953-8.828-8.828v-317.793c0-4.875 3.953-8.828 8.828-8.828h494.345c4.875 0 8.828 3.953 8.828 8.828v317.793c-.001 4.876-3.954 8.828-8.829 8.828z" fill="#ff4b55"/><path d="m353.103 220.691h-61.793v-61.793c0-4.875-3.953-8.828-8.828-8.828h-52.966c-4.875 0-8.828 3.953-8.828 8.828v61.793h-61.793c-4.875 0-8.828 3.953-8.828 8.828v52.966c0 4.875 3.953 8.828 8.828 8.828h61.793v61.793c0 4.875 3.953 8.828 8.828 8.828h52.966c4.875 0 8.828-3.953 8.828-8.828v-61.795h61.793c4.875 0 8.828-3.953 8.828-8.828v-52.966c0-4.874-3.953-8.826-8.828-8.826z" fill="#f5f5f5"/></svg>

  return (
    <span>
      <SvgIcon style={{marginBottom: -6, paddingRight: 8}}>
        {icon}
      </SvgIcon>
      {children}
    </span>
  );
}


const styles = theme => ({
  root: {
    display: 'flex !important',
    // paddingLeft: theme.spacing.unit*2
  },
  select: {
    paddingLeft: theme.spacing.unit*2
  }
})

const handleCountryChange = (e, props) => {
  const { nodes } = props;
  const { value } = e.target;
  let id = 1;
  props.uiSetCountry(value);
  if(value === "germany")
    id=1;
  else if(value === "switzerland")
    id=658;
  else if(value === "austria")
    id=123;
  if(!nodes[id])
    props.nodesFetchData(id);
  else
    props.setRootId(id)
};

//<InputLabel htmlFor="country-simple">{translate("AppBar.country.title")}</InputLabel>
let CountrySwitcher = props => {
  const { translate, country, classes } = props;
  return(
    <FormControl className={classes.root}>
      
      <Select
        className={classes.select}
        value={country}
        renderValue={value => <Flag country={value}>{translate(`AppBar.country.${value}`)}</Flag>}
        onChange={ (e) => handleCountryChange(e,props)}
        inputProps={{
          name: 'country',
          id: 'country-simple',
        }}
      >
        <MenuItem value="germany"><Flag country="germany">{translate(`AppBar.country.germany`)}</Flag></MenuItem>
        <MenuItem value="austria"><Flag country="austria">{translate(`AppBar.country.austria`)}</Flag></MenuItem>
        <MenuItem value="switzerland"><Flag country="switzerland">{translate(`AppBar.country.switzerland`)}</Flag></MenuItem>
      </Select>
    </FormControl>
  )
}

const mapStateToProps = state => {
  return {
    nodes: state.nodes,
    country: state.country,
    rootId: state.rootId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetCountry: (id) => dispatch(uiSetCountry(id)),
    nodesFetchData: (id) => dispatch(nodesFetchData(id)),
    setRootId: (id) => dispatch(setRootId(id))
  };
};

CountrySwitcher = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withLocalize,
)(CountrySwitcher);
export { CountrySwitcher, Flag }
