import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CartIcon from '@material-ui/icons/ShoppingCart';
import { withLocalize } from "react-localize-redux";

// LOCAL
// import { RemoveButton } from '../RemoveButton';
import { AssetName } from '../AssetName';

// REDUX
import { connect } from "react-redux";
import { compose } from 'redux'
import { uiSetCartQuickViewOpen } from '../../_actions/ui.actions';

const styles = theme => ({
  root: {
    border: '1px solid rgba(0,0,0,0.25)',
    borderRadius: 5
  }
});

const Title = props => {
  return <div style={{ textDecoration: 'underline', fontSize: 16 }}>{props.name}</div>;
}

class ControlledOpenSelect extends React.Component {
  // state = {
  //   age: '',
  //   open: false,
  // };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = () => {
    this.props.setCartQuickViewOpen(null);
    // this.setState({ open: false });
  };

  // handleOpen = () => {
  //   this.props.setCartQuickViewOpen(true);
  //   // this.setState({ open: true });
  // };

  render() {
    const { classes, cartQuickViewOpen, cart, translate } = this.props;

    return (
      <div>
        <Popover
          open={Boolean(cartQuickViewOpen)}
          anchorEl={cartQuickViewOpen}
          onClose={this.handleClose.bind(this)}
          
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List className={classes.root} dense={true}>
            <ListItem component={Link} button to="/cart" onClick={this.handleClose.bind(this)}>
              <ListItemIcon>
                <CartIcon/>  
              </ListItemIcon>
              <ListItemText primary={<Title name={`${translate("cart.title")} (${cart.items.length})`}/>}/>
            </ListItem>
            <Divider/>
        {
          cart.items.map((item, index) => (
            <div key={item.id}>
              <ListItem
                component={Link}
                button
                to={`/asset/${item.id}`}
              >
  {/*              <ListItemIcon>
                  <RemoveButton asset={item} view="list"/>
                </ListItemIcon>*/}
                <ListItemText primary={<AssetName>{item.name}</AssetName>}/>
              </ListItem>
              { (index !== cart.items.length-1) && <Divider/>}
            </div>
          ))
        }
          </List>
        </Popover>
      </div>
    );
  }
}

// ControlledOpenSelect.propTypes = {
//   classes: PropTypes.object.isRequired,
//   anchorEl: PropTypes.object.isRequired,
// };

const mapStateToProps = state => {
  return {
    cart: state.cart,
    cartQuickViewOpen: state.cartQuickViewOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCartQuickViewOpen: (bool) => dispatch(uiSetCartQuickViewOpen(bool)),
  };
};

const CartQuickView = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withLocalize,
)(ControlledOpenSelect)
 

export { CartQuickView }