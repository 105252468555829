/* MODULES */
import React from 'react';
import {
  // BrowserRouter as Router,
  Router,
  Switch,
  Route,
  Redirect,
  // Link,
} from 'react-router-dom';
import { withRouter } from 'react-router';
import { renderToStaticMarkup } from "react-dom/server";
import { connect } from "react-redux";
import { compose } from 'redux';
import ReactPiwik from 'react-piwik';
import CookieConsent from "react-cookie-consent";
import queryString from 'query-string';

// LANGUAGES
import { withLocalize, Translate } from "react-localize-redux";
import deGlobal from "./_translations/de.global.json";
import enGlobal from "./_translations/en.global.json";
import frGlobal from "./_translations/fr.global.json";

/* LOCAL */
// import { mailFolderListItems, otherMailFolderListItems } from './tileData';
import { history } from './_helpers';

import { uiSetCountry } from './_actions';

/* components */
// import asyncLoader from './components/AsyncLoader';
// import { dataProvider } from './dataProvider';
import DashboardLayout from './layouts/DashboardLayout';

// pages
import { Home } from './pages/home';
import { Cart } from './pages/cart';
import { Node } from './pages/node';
import { Asset } from './pages/asset';
import { Messages } from './pages/messages';
import { Downloads } from './pages/downloads';
import { Profile } from './pages/profile';
import { Download } from './pages/download';
import { Search } from './pages/search';
import { Login } from './pages/login';
import { Signup } from './pages/signup';
import { Orders } from './pages/orders';
import { AddOrder, EditOrder } from './pages/order';
import { Confirm } from './pages/signup/confirm';
import { ResetPassword } from './pages/reset_password';

import { Contact, Imprint, Privacy, Cookies, TermsOfService } from './pages/static';

import { SetLang } from './pages/lang';
import { ErrorPage } from './pages/ErrorPage';

// const Login = asyncLoader(() => import('./pages/login'));

const SecureRoute = ({ component: Component, ...rest }) => {
  const {verySecure} = rest;
  const user = JSON.parse(localStorage.getItem('user'));
  if(verySecure)
    return (
      <Route
        {...rest}
        render={props =>
           (user.jde || user.isSuperUser) ? (
            <DashboardLayout>
              <Component {...props} />
            </DashboardLayout>
          ) : user ? (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );

  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('user') ? (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

const piwik = new ReactPiwik({
  url: 'tracking.jde-mediabox.com',
  siteId: 1,
  trackErrors: true,
});

class App extends React.Component {

  constructor(props){
    super(props);
    const languages = [
      { name: "German", code: "de" },
      { name: "English", code: "en" },
      { name: "French", code: "fr" },
    ];
    const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;
    const defaultLanguage = window.localStorage.getItem("lang") || languages[0];
    // const defaultLanguage = this.props.lang || languages[0];

    this.props.initialize({
      languages,
      translation: deGlobal,
      options: { renderToStaticMarkup, defaultLanguage, onMissingTranslation }
    });
  }

  componentDidMount(){
    this.props.addTranslationForLanguage(deGlobal, "de");
    this.props.addTranslationForLanguage(enGlobal, "en");
    this.props.addTranslationForLanguage(frGlobal, "fr");
    ReactPiwik.push(['addDownloadExtensions', "jpg|jpeg|eps|zip|png"]);

    const qs = queryString.parse(this.props.location.search);
    const c = {
      "de": "germany",
      "at": "austria",
      "ch": "switzerland"
    }
    if(qs.c){
      this.props.setCountry(c[qs.c])  
    }
    
    
  }

  render() {
    // const { translate } = this.props;
    
    const LinkWithText = ({translate, target}) => (
      <a href={`/${target}`} style={{ color: 'inherit'}}>
       {translate(`${target}.title`)}
      </a>
    );

    return (
      <div>
        <Router history={piwik.connectToHistory(history)}>
          <Switch>
            <Route exact path='/de' render={()=><SetLang lang="de" />} />
            <Route exact path='/en' render={()=><SetLang lang="en" />} />
            <Route exact path='/fr' render={()=><SetLang lang="fr" />} />
            <Route exact path='/download/:filename' component={Download} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/reset_password/:uuid' component={ResetPassword} />
            <Route exact path='/signup' component={Signup} />
            <Route exact path='/confirm/:uuid' component={Confirm} />

            <Route exact path='/contact' component={Contact} />
            <Route exact path='/imprint' component={Imprint} />
            <Route exact path='/privacy' component={Privacy} />
            <Route exact path='/cookies' component={Cookies} />
            <Route exact path='/termsofservice' component={TermsOfService} />

            <SecureRoute exact path='/' component={Home} />
            <SecureRoute exact path='/search/:query' component={Search} />
            <SecureRoute exact path='/asset/:id(\d+)' component={Asset} />
            <SecureRoute exact path='/cart' component={Cart} />
            <SecureRoute exact path='/messages' component={Messages} />
            <SecureRoute exact path='/downloads' component={Downloads} />
            <SecureRoute exact path='/profile' component={Profile} />
            <SecureRoute exact path='/orders' component={Orders} verySecure />
            <SecureRoute exact path='/orders/:step' component={Orders} verySecure />
            <SecureRoute exact path='/order/add' component={AddOrder} verySecure />
            <SecureRoute exact path='/order/:id' component={EditOrder} verySecure />
            <SecureRoute exact path='/order/:id/:step' component={EditOrder} verySecure />

            
            <SecureRoute path='/:id(\d+)' component={Node} />

            <SecureRoute component={ErrorPage} />
          </Switch>
        </Router>
        <CookieConsent
          location="top"
          buttonText={this.props.translate("cookieConsent.btnText")}
          cookieName="CookieConsentAccepted"
          style={{ background: "#2B373B", height: 64, zIndex: 10001 }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          <Translate
            id="cookieConsent.text"
            data={{
              cookies: <LinkWithText {...this.props} target="cookies"/>,
              privacy: <LinkWithText {...this.props} target="privacy"/>,
              termsofservice: <LinkWithText {...this.props} target="termsofservice"/>
            }}
          /> 
        </CookieConsent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCountry: (country) => dispatch(uiSetCountry(country)),
  };
};

const AppComponent = compose(
  withLocalize,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(App)

// export default withStyles(styles, { withTheme: true })(App);
export default AppComponent;