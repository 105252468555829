import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { compose } from 'redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
// import { cartGetOne } from '../../_actions/';

// MUI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CartIcon from '@material-ui/icons/ShoppingCart';
import DownloadIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import MailIcon from '@material-ui/icons/Mail';
import MoreIcon from '@material-ui/icons/MoreVert';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import PersonIcon from '@material-ui/icons/Person';
import LinearProgress from '@material-ui/core/LinearProgress';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';

// REDUX
import {
  messageGetAll,
  cartGetOne,
  downloadGetAll,
  setSearchString,
  searchFetchData,
  uiSetSearchActive,
  uiSetCartQuickViewOpen,
  uiSetLang,
  uiGetLang,
  orderActions,
} from '../../_actions';

// LOCAL
import { CartQuickView } from '../CartQuickView';
import { SnackCommander } from '../SnackCommander';
// import { CountrySwitcher } from '../CountrySwitcher';


const styles = theme => ({
  root: {
    width: '100%',
    position: 'absolute',
    // marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    zIndex: 6,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
    height: 35,
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    // width: theme.spacing.unit * 9,
    // height: '100%',
    position: 'relative',
    // pointerEvents: 'none',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    color: 'white',
    zIndex: 100
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    // paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 150,
      '&:focus': {
        width: 250,
      },
      
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  list: {
    border: '1px solid rgba(0,0,0,0.25)',
    borderRadius: theme.shape.borderRadius
  },
  logo: {
    height: 45
  },
  icon: {
    color: theme.palette.primary.main
  }
});

// function debounce(a,b,c){var d,e;return function(){function h(){d=null,c||(e=a.apply(f,g))}var f=this,g=arguments;return clearTimeout(d),d=setTimeout(h,b),c&&!d&&(e=a.apply(f,g)),e}}

export const drawerWidth = 240;

class JDEAppBar extends Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    // downloads: [],
    // cart: [],
    // messages: [],
    // isLoading: false,
    cartInvisible: "false",
    searchString: "",
  };

  // constructor(props){
  //   super(props);
  //   // this.props.addTranslationForLanguage(deGlobal, "de");
  // }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleBlur = () => {
    // console.log('handleBlur');
  };

  handleFocus = () => {
    // console.log('handleFocus');
  };

  handleCartOpen = event => {
    // console.log(event);
    this.props.cartQuickViewOpen(event.currentTarget);
  }

  // setSearchTerm = debounce(searchTerm => {
  //     this.setState({ searchTerm })
  // }, 1000);

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ searchString: e.target.value });
      // this.props.setSearchString(value);
    
    _.debounce(() => {
      // console.log('searching for',value);
      this.props.setSearchString(value);
      // this.props.searchFetchData(value);
    }, 750);
  };

  handleLangChange = (e) => {
    const { value } = e.target;
    this.props.setActiveLanguage(value);
    this.props.uiSetLang(value);
  };

  handleKeyPress = (e) => {
    const searchString = e.target.value;
    // this.setState({ searchString: e.target.value });
    if (e.key === 'Enter' && searchString.length > 0) {
      // console.log(this.props.history);
      // console.log('handleKeyPress',e.target.value);
      this.props.setSearchString(searchString);
      this.props.history.push(`/search/${searchString.replace(/ /g,'+').toLowerCase()}`);
      // console.log('ENTER',this.state.search);
    }
  };

  componentDidMount(){
    this.props.cartGetOne();
    this.props.downloadGetAll();
    if(this.props.isSuperUser || this.props.jde)
      this.props.ordersGetAll();
    this.props.messageGetAll();
    this.props.uiGetLang();

    this.setState({ searchString: this.props.searchString });
  }

  // checkLoading(){
  //   if(this.props.nodesIsLoading || this.props.assetIsLoading || this.props.assetsIsLoading || this.props.cartIsLoading || this.props.searchIsLoading){
  //     // console.log('something is loading');
  //     this.setState({ isLoading: true });
  //   }
  //   else{
  //     console.log('nothing is loading');
  //     this.setState({ isLoading: false });
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    // console.log('nextProps.searchActive',nextProps.searchActive);
    // console.log('this.props.searchActive',this.props.searchActive);
    // console.log('(window.location.pathname.indexOf("search") > 0)',(window.location.pathname.indexOf('search') > 0));
    // if(nextProps.searchActive !== this.props.searchActive || window.location.pathname.indexOf('search') > 0)
    this.props.setSearchActive(window.location.pathname.indexOf('search') > 0)
    // console.log(nextProps.cart.items.length,this.props.cart.items.length);
    // if(nextProps.cart !== this.props.cart)
    // console.log('componentWillReceiveProps',nextProps);
    // if(nextProps.)
    // this.checkLoading();
  }

  render() {
    const { anchorEl, mobileMoreAnchorEl, searchString } = this.state;
    // eslint-disable-next-line
    const { classes, isLoading, lang, translate, isSuperUser, jde, userId } = this.props;
    const cart = (this.props.cart) ? this.props.cart.items : [];
    const downloads = (this.props.downloads) ? this.props.downloads : [];
    const messages = this.props.message.messages;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    
    const allOrders = (this.props && this.props.orders && this.props.orders.isArray) ? this.props.orders : []
    const pendingOrders = allOrders.filter(o => o.status === "pending" && o.active);
    const newOrders = allOrders.filter(o => o.status === "new" && o.active);
    const rejectedOrders = allOrders.filter(o => o.status === "rejected" && o.active);
    const orders = isSuperUser ? [...newOrders, ...rejectedOrders] : pendingOrders;

    const renderMenu = (
      <Popover 
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={this.handleMenuClose}
      >
        <List className={classes.list} dense={true}>
          <ListItem button component={Link} to="/login">
            <PowerIcon fontSize="small"/><ListItemText primary={translate("AppBar.logout")}/>
          </ListItem>
          <Divider/>
          <ListItem button component={Link} to="/profile" onClick={this.handleMenuClose}>
            <PersonIcon fontSize="small"/><ListItemText primary={translate("AppBar.profile.title")}/>
          </ListItem>
          <Divider/>
          <ListItem button>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-simple">{translate("languages.title")}</InputLabel>
              <Select
                value={lang}
                onChange={this.handleLangChange.bind(this)}
                inputProps={{
                  name: 'lang',
                  id: 'lang-simple',
                }}
              >
                <MenuItem value="de">{translate("languages.de")}</MenuItem>
                <MenuItem value="en">{translate("languages.en")}</MenuItem>
                <MenuItem value="fr">{translate("languages.fr")}</MenuItem>
              </Select>
            </FormControl>
          </ListItem>
        </List>
      </Popover>
    );

    const renderMobileMenu = (
      <Popover 
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >

        <List className={classes.list} dense={true}>

          <ListItem button component={messages.length > 0 ? Link : "div"} to="/messages" onClick={this.handleMobileMenuClose} disabled={messages.length === 0}>
            <Badge className={classes.margin} badgeContent={messages.length} color="secondary" invisible={messages.length === 0}>
              <MailIcon className={classes.icon}/>
            </Badge>
            <ListItemText primary={translate("messages.title")}/>
          </ListItem>

          <Divider/>

          <ListItem button component={downloads.length > 0 ? Link : "div"} to="/downloads" onClick={this.handleMobileMenuClose} disabled={downloads.length === 0}>
            <Badge className={classes.margin} badgeContent={downloads.length} color="secondary" invisible={downloads.length === 0}>
              <DownloadIcon className={classes.icon}/>
            </Badge>
            <ListItemText primary={translate("downloads.title")}/>
          </ListItem>
          
          <Divider/>
          
          <ListItem button component={cart.length > 0 ? Link : "div"} to="/cart" onClick={this.handleMobileMenuClose} disabled={cart.length === 0}>
            <Badge className={classes.margin} badgeContent={cart.length} color="secondary" invisible={cart.length === 0}>
              <CartIcon className={classes.icon}/>
            </Badge>
            <ListItemText primary={translate("cart.title")}/>
          </ListItem>

          
          <Divider/>
          
          <ListItem button component={Link} to="/profile" onClick={this.handleMobileMenuClose}>
            <PersonIcon fontSize="small"/>
            <ListItemText primary={translate("AppBar.profile.title")}/>
          </ListItem>

          <Divider/>
          
          <ListItem button component={Link} to="/login" onClick={this.handleMobileMenuClose}>
            <PowerIcon fontSize="small"/>
            <ListItemText primary={translate("AppBar.logout")}/>
          </ListItem>


        </List>

      </Popover>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton onClick={this.props.handleDrawerToggle} className={classes.navIconHide} color="inherit" aria-label="Open drawer">
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              <Link to="/" style={{textDecoration: 'none', color: 'inherit'}}>
                <img className={classes.logo} src="https://www.jde-mediabox.com/images/logo_long.png" alt="Logo"/>
              </Link>
            </Typography>
            <div className={classes.search}>
              <IconButton onClick={this.handleKeyPress.bind(this)} className={classes.searchIcon} disabled={searchString.length === 0}>
                {/*<Link to={`/search/${searchString.replace(/ /g,'+').toLowerCase()}`}>*/}
                  <SearchIcon />
                {/*</Link>*/}
              </IconButton>
              <Input
                placeholder={translate("AppBar.search.title")}
                disableUnderline
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onKeyPress={this.handleKeyPress.bind(this)}
                onChange={this.handleChange.bind(this)}
                value={searchString}
              />

            </div>
            
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>

{
 /*(userId === "7" || userId === "21" || userId === "22" || userId === "41" || userId === "18") &&*/
 (jde || isSuperUser ) &&
              <IconButton color="inherit" component={Link} to="/orders">
                <Badge className={classes.margin} badgeContent={orders.length} invisible={(orders.length < 1)} color="secondary">
                  <DescriptionIcon/>
                </Badge>
              </IconButton>
}
              <IconButton disabled={(messages.length < 1)} color="inherit" component={Link} to="/messages">
                <Badge className={classes.margin} badgeContent={messages.length} invisible={(messages.length < 1)} color="secondary">
                  <MailIcon/>
                </Badge>
              </IconButton>
            
            
              <IconButton disabled={(downloads.length < 1)} color="inherit" component={Link} to="/downloads">
                <Badge className={classes.margin} badgeContent={downloads.length} invisible={(downloads.length < 1)} color="secondary">
                  <DownloadIcon />
                </Badge>
              </IconButton>
            
              <IconButton disabled={(cart.length < 1)} color="inherit"  onClick={this.handleCartOpen} > {/*component={cart.length > 0 ? Link : "div"} to="/cart"*/}
                <Badge className={classes.margin} badgeContent={cart.length} invisible={(cart.length < 1)} color="secondary">
                  <CartIcon />
                </Badge>
              </IconButton>
              <CartQuickView/>
            

              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
          { isLoading && 
            <LinearProgress color="primary" style={{ marginTop:'-5px', width: '100%'}} />
          }
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
        <SnackCommander/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: (state.nodesIsLoading || state.assetIsLoading || state.assetsIsLoading || state.cartIsLoading || state.searchIsLoading || state.cartDownloadIsLoading ),
    nodesIsLoading: state.nodesIsLoading,
    assetIsLoading: state.assetIsLoading,
    assetsIsLoading: state.assetsIsLoading,
    cartIsLoading: state.cartIsLoading,
    searchIsLoading: state.searchIsLoading,
    cart: state.cart,
    lang: state.lang,
    country: state.country,
    downloads: state.downloads,
    message: state.message,
    searchString: state.searchString,
    searchActive: state.searchActive,
    nodes: state.nodes,
    orders: state.order.orders,
    // rootId: state.rootId,
    isSuperUser: state.authentication.user.isSuperUser,
    userId: state.authentication.user.userId,
    jde: state.authentication.user.jde,
  }
  // loading: state.nodes.loading,
  // error: state.nodes.error
}

const mapDispatchToProps = (dispatch) => {
  return {
    cartGetOne: (id) => dispatch(cartGetOne(id)),
    downloadGetAll: () => dispatch(downloadGetAll()),
    messageGetAll: () => dispatch(messageGetAll()),
    ordersGetAll: () => dispatch(orderActions.getAll()),
    setSearchString: (searchString) => dispatch(setSearchString(searchString)),
    searchFetchData: (q) => dispatch(searchFetchData(q)),
    setSearchActive: (i) => dispatch(uiSetSearchActive(i)),
    cartQuickViewOpen: (bool) => dispatch(uiSetCartQuickViewOpen(bool)),
    uiSetLang: (lang) => dispatch(uiSetLang(lang)),
    uiGetLang: () => dispatch(uiGetLang()),
  };
};

JDEAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

JDEAppBar = compose(
  withRouter,
  withLocalize,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(JDEAppBar);

// JDEAppBar = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(JDEAppBar));

export {JDEAppBar}