import { uiConstants } from '../_constants'

// export function uisHasErrored(state = false, action) {
//   switch (action.type) {
//     case uiConstants.ui_HAS_ERRORED:
//       return action.hasErrored;
//     default:
//       return state;
//   }
// }

// export function uisIsLoading(state = false, action) {
//   switch (action.type) {
//     case uiConstants.ui_IS_LOADING:
//       return action.isLoading;
//     default:
//       return state;
//   }
// }

export function view(state = "", action) {
  switch (action.type) {
    case uiConstants.UI_SET_VIEW:
      return action.view;
    case uiConstants.UI_GET_VIEW:
      return action.view;
    case uiConstants.UI_VIEW_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function lang(state = "", action) {
  switch (action.type) {
    case uiConstants.UI_SET_LANG:
      return action.lang;
    case uiConstants.UI_GET_LANG:
      return action.lang;
    default:
      return state;
  }
}

export function country(state = "germany", action) {
  switch (action.type) {
    case uiConstants.UI_SET_COUNTRY:
      return action.country;
    case uiConstants.UI_GET_COUNTRY:
      return action.country;
    default:
      return state;
  }
}

export function container(state = 1, action) {
  switch (action.type) {
    case uiConstants.UI_SET_CONTAINER:
      return action.container;
    case uiConstants.UI_GET_CONTAINER:
      return action.container;
    default:
      return state;
  }
}

export function showSearch(state = false, action) {
  switch (action.type) {
    case uiConstants.UI_SHOW_SEARCH:
      return action.showSearch;
    default:
      return state;
  }
}

export function searchActive(state = false, action) {
  switch (action.type) {
    case uiConstants.UI_SEARCH_ACTIVE:
      return action.searchActive;
    default:
      return state;
  }
}

export function cartQuickViewOpen(state = null, action) {
  switch (action.type) {
    case uiConstants.UI_CART_QUICK_VIEW_OPEN:
      return action.cartQuickViewOpen;
    default:
      return state;
  }
}
