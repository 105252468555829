import React, { Component } from 'react'
import { withRouter } from 'react-router'

// MUI
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

// LOCAL
// import {dataProvider} from '../../dataProvider';
import { Statusbar } from '../../components/Statusbar';
import { GridView } from '../../components/GridView';
import { ListView } from '../../components/ListView';

// REDUX STUFF
import { connect } from 'react-redux';
import { compose } from 'redux'
import { assetsFetchData } from '../../_actions/assets.actions';
import { cartGetOne } from '../../_actions/cart.actions';
import { uiSetCurrentContainer } from '../../_actions/';

const styles = theme => ({
  root: {
    // margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing.unit,
    width: '100%',
  },
  table: {
    minWidth: 700,
  },
  downloadButton: {
    marginRight: theme.spacing.unit,
    fontSize: 16,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  noData: {
    padding: theme.spacing.unit,
  }
});


class NodeView extends Component {
 
  state = {
    isLoading: false,
    appFetchingError: null,
    data: []
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchData(id);
    this.props.cartGetOne();
    // this.props.dispatch(assetsFetchData(id));
  }
 
  componentWillMount() {
    // this.getAssets(this.props)
    // const { id } = this.props.match.params;
    // this.props.dispatch(assetsFetchData(id));
  }
 
  componentWillReceiveProps(nextProps) {
    this.setContainer(nextProps);
    // const { id } = nextProps.match.params;
    const current = `${this.props.location.pathname}${this.props.location.search}`
    const next = `${nextProps.location.pathname}${nextProps.location.search}`
    if (current === next) {
     return
    }
    this.getAssets(nextProps);
    
    
    // nextProps.dispatch(assetsFetchData(id));
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevProps.cart !== this.props.cart) {
  //     console.log('cart changed');
  //       // Do whatever you want
  //   }
  // }
 
  shouldComponentUpdate(nextProps, nextState) {
    return !nextState.isLoading
  }
 
  setContainer(props) {
    const { id } = props.match.params;
    this.props.uiSetCurrentContainer(parseInt(id));
    // console.log('Node.js sets container to',id)
  }

  getAssets(props) {
    const { id } = props.match.params;
    this.props.fetchData(id);
  }
 
  render () {
    const { view, classes, isLoading } = this.props;
    // for(let asset of this.props.assets) {
    //   console.log(asset.name)
    // }
    // console.log('assets', assets)

    //do something with isLoading for the first render if single page app. 
    // after the first render, the page contents will stay put until the next route's data is ready to go, so you'll have to do something outside of this. 
    if(isLoading)
      return (
        <Grid container style={{flexGrow: 1}} spacing={16} >
          <Statusbar blank />
          <CircularProgress style={{margin: '0 auto'}}/>
        </Grid>
      )

    return (
      <Grid container style={{flexGrow: 1}} spacing={16} >
        <Statusbar />
        { (this.props.assets.length === 0 && !isLoading) ?
          <div className={classes.noData}></div>
        :  
         (view === "grid") ? 
          <GridView assets={this.props.assets} />
        :
          <ListView assets={this.props.assets} />
        }
      </Grid>
    )
  }
}




const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (id) => dispatch(assetsFetchData(id)),
    cartGetOne: (id) => dispatch(cartGetOne(id)),
    uiSetCurrentContainer: (id) => dispatch(uiSetCurrentContainer(id)),
  };
};

const mapStateToProps = state => {
  return {
    assets: state.assets,
    cart: state.cart,
    isLoading: (state.nodesIsLoading || state.assetIsLoading || state.assetsIsLoading),
    nodesIsLoading: state.nodesIsLoading,
    assetIsLoading: state.assetIsLoading,
    assetsHasErrored: state.assetsHasErrored,
    delItemFromCart: state.delItemFromCart,
    addItemToCart: state.addItemToCart,
    view: state.view,
  }
}

// const Node = withRouter(connect(mapStateToProps, mapDispatchToProps)(NodeView))

const Node = compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps), 
)(NodeView);


export { Node }

