import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';

// MUI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
// import DoneIcon from '@material-ui/icons/Done';
// import Tooltip from '@material-ui/core/Tooltip';

// COMPONENTS
import { Flag } from '../../components/CountrySwitcher';
import { ImageCarousel } from '../../components/ImageCarousel';

// REDUX ACTIONS
import { orderActions } from '../../_actions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  listItem: {
    // padding: theme.spacing.unit * 1,
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
  chip: {
    margin: theme.spacing.unit,
  },
  new: {
    color: grey[500]
  },
  pending: {
    color: yellow[500]
  },
  accepted: {
    color: green[500]
  },
  rejected: {
    color: red[500]
  },
  preview: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: "100%"
  },
  secondary: {
    whiteSpace: "pre"
  },
  actionButton: {
    margin: theme.spacing.unit * 2
  },
  span:{
    display: 'block'
  }
});


class OrderReview extends Component {
  render(){
    const { classes, order, isSuperUser } = this.props;

    const formatStatus = (status) => {
      const lt = {
        "new": "In Bearbeitung",
        "pending": "Ausstehend",
        "accepted": "Freigegeben",
        "rejected": "Abgelehnt",
        "completed": "Erledigt/Abgeschloßen"
      }
      return lt[status]
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid container item xs={12} sm={6} spacing={0} style={{ height: 'fit-content'}}>

            <Grid item xs={12} sm={6}>
              <ListItem><ListItemText primary={"Status"} classes={{ secondary: classes[order.status] }} secondary={formatStatus(order.status)} /></ListItem>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ListItem><ListItemText primary={"Auftragsnummer"} secondary={order.orderNumber} /></ListItem>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ListItem><ListItemText primary={"Erstellt"} secondary={<Moment format="DD.MM.YYYY HH:mm" date={order.createdAt}/>} /></ListItem>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ListItem><ListItemText primary={"Zuletzt geändert"} secondary={<Moment format="DD.MM.YYYY HH:mm" date={order.lastModifiedAt}/>} /></ListItem>
            </Grid>

            {order.articleId &&
            <Grid item xs={12} sm={6}>
              <ListItem><ListItemText primary={"Artikelnummer"} secondary={order.articleId} /></ListItem>
            </Grid>
            }
            { order.gtin &&
            <Grid item xs={12} sm={6}>
              <ListItem><ListItemText primary={"GTIN"} secondary={order.gtin} /></ListItem>
            </Grid>
            }

            { order.packshot && 
            <Grid item xs={12} md={6}>
            { (order.packshot.display || order.packshot.ecommerce || order.packshot.other || order.packshot.single || order.packshot.tray) && <ListItem className={classes.listItem}><ListItemText primary="Typ" secondary={
              <React.Fragment>
                {order.packshot.ecommerce && <span className={classes.span}>E-Commerce {order.packshot.gtin.ecommerce && `(GTIN: ${order.packshot.gtin.ecommerce})`}</span>}
                {order.packshot.single && <span className={classes.span}>Einzelpackshot {order.packshot.gtin.single && `(GTIN: ${order.packshot.gtin.single})`}</span>}
                {order.packshot.tray && <span className={classes.span}>Regalkarton {order.packshot.gtin.tray && `(GTIN: ${order.packshot.gtin.tray})`}</span>}
                {order.packshot.display && <span className={classes.span}>Display {order.packshot.gtin.display && `(GTIN: ${order.packshot.gtin.display}${order.packshot.articleId.display && ` Artikel-Nr.: ${order.packshot.articleId.display}`})`}</span>}
                {order.packshot.other && <span className={classes.span}>Sonstige {order.packshot.description && `(Beschreibung: ${order.packshot.description})`}</span>}
              </React.Fragment>
            } /></ListItem> }

            </Grid>
            }






            { (order.de || order.at || order.ch) &&
            <Grid item xs={12} sm={6}>
              <ListItem>
                <ListItemText primary={"Land"} classes={{ secondary: classes[order.status] }} secondary={
                  <span>
                    { order.de && <Flag country="de"/>}
                    { order.at && <Flag country="at"/>}
                    { order.ch && <Flag country="ch"/>}
                  </span> }
                />
              </ListItem>
            </Grid>
            }
            <Grid item xs={12} sm={6}>
              <ListItem><ListItemText primary="Auftrag" secondary={order.new === "yes" ? "Artikel neu anlegen" : order.new === "delete" ? "Artikel löschen" : 
                <React.Fragment>
                  {(order.change && order.change.data) && <span className={classes.span}>Artikeldaten ändern</span> }
                  {(order.change && order.change.packshot) && <span className={classes.span}>Abbildung ändern</span> }
                </React.Fragment>
              } /></ListItem>
            </Grid>


            { order.internalDate &&
            <Grid item xs={12} sm={6}>
              <ListItem><ListItemText primary={"Verfügbarkeit intern"} secondary={<Moment format="DD.MM.YYYY" date={order.internalDate}/>}/></ListItem> 
            </Grid>
            }  
            
            { order.externalDate &&
            <Grid item xs={12} sm={6}>
              <ListItem><ListItemText primary={"Verfügbarkeit extern"} secondary={<Moment format="DD.MM.YYYY" date={order.externalDate}/>} /></ListItem>
            </Grid>
            }

            <Grid item xs={12} sm={12}>
              <ListItem><ListItemText primary="Benachrichtigung an" secondary={order.vertretung} /></ListItem>
            </Grid>

            { order.description &&
            <Grid item xs={12} sm={12}>
              <ListItem>
                <ListItemText primary={"Beschreibung"} classes={{secondary: classes.secondary}} secondary={order.description} />
              </ListItem>
            </Grid>
            }

            <Grid item xs={12} sm={12}>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                onClick={this.props.handleShowForm}
              >
                Auftrag bearbeiten
              </Button>
              { isSuperUser &&
              <Button
                className={classes.actionButton}
                variant="contained"
                color="secondary"
                onClick={() => this.props.deactivateOne(order._id)}
              >
                Auftrag l&ouml;schen
              </Button>
              }
            </Grid>



          </Grid>

          <Grid container item xs={12} sm={6} spacing={0}>
            <Grid item xs={12} sm={12}>
              <div style={{width: "100%", minHeight: "100%"}}>
              <ImageCarousel files={order.files ? order.files.filter(f => f.mimetype.indexOf("image/") >= 0) : null} />

              {/*(order.files && order.files.length > 0) &&
                <img
                  src={`https://api.jde-mediabox.com/uploads/preview/${order.files[order.files.length-1].filename}`}
                  className={classes.preview}
                  // style={img}
                  alt={"file.filename"}
                />
                */}
              </div>
            </Grid>
          </Grid>

        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    order: state.order.order,
    isSuperUser: state.authentication.user.isSuperUser,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    deactivateOne: (orderId) => dispatch(orderActions.deactivateOne(orderId))
  };
};

OrderReview = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(OrderReview));

export { OrderReview }