import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import filesize from 'filesize';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { Flag } from '../../components/CountrySwitcher';

const styles = theme => ({
  listItem: {
    padding: theme.spacing.unit * 1,
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
});

function Review(props) {
  const { classes, formValues } = props;
  const values = formValues;

  const getCountries = () => {
    let c = [];
    if(values.de) c.push(<Flag country="de" key="de" />);
    if(values.at) c.push(<Flag country="at" key="at" />);
    if(values.ch) c.push(<Flag country="ch" key="ch" />);
    return c;
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Zusammenfassung
      </Typography>
      <List disablePadding>
        <ListItem className={classes.listItem}><ListItemText primary={"Name"} secondary={values.name} /></ListItem>
        { (values.de || values.at || values.ch) && <ListItem className={classes.listItem}><ListItemText primary={"Land"} secondary={getCountries().map(c => c)} /></ListItem>}
        { (values.packshot && (values.packshot.display || values.packshot.ecommerce || values.packshot.other || values.packshot.single || values.packshot.tray)) && <ListItem className={classes.listItem}><ListItemText primary="Typ" secondary={
          <React.Fragment>
            {( values.packshot && values.packshot.ecommerce) && <div>E-Commerce {(values.packshot.gtin && values.packshot.gtin.ecommerce) && `(GTIN: ${values.packshot.gtin.ecommerce})`}</div>}
            {( values.packshot && values.packshot.single) && <div>Einzelpackshot {(values.packshot.gtin && values.packshot.gtin.single) && `(GTIN: ${values.packshot.gtin.single})`}</div>}
            {( values.packshot && values.packshot.tray) && <div>Regalkarton {(values.packshot.gtin && values.packshot.gtin.tray) && `(GTIN: ${values.packshot.gtin.tray})`}</div>}
            {( values.packshot && values.packshot.display) && <div>Display {(values.packshot.gtin && values.packshot.gtin.display) && `(GTIN: ${values.packshot.gtin.display}${values.packshot.articleId.display && ` Artikel-Nr.: ${values.packshot.articleId.display}`})`}</div>}
            {( values.packshot && values.packshot.other) && <div>Sonstige {values.packshot.description && `(Beschreibung: ${values.packshot.description})`}</div>}
          </React.Fragment>
        } /></ListItem> }
        {values.articleId && <ListItem className={classes.listItem}><ListItemText primary={"Artikelnummer"} secondary={values.articleId} /></ListItem>}
        {values.gtin && <ListItem className={classes.listItem}><ListItemText primary={"GTIN"} secondary={values.gtin} /></ListItem>}
        {values.internalDate && <ListItem className={classes.listItem}><ListItemText primary={"Verfügbarkeit intern"} secondary={values.internalDate} /></ListItem>}
        {values.externalDate && <ListItem className={classes.listItem}><ListItemText primary={"Verfügbarkeit extern"} secondary={values.externalDate} /></ListItem>}
        
        <ListItem className={classes.listItem}><ListItemText primary="Auftrag" secondary={values.new === "yes" ? "Artikel neu anlegen" : values.new === "delete" ? "Artikel entfernen" : 
          <React.Fragment>
            {(values.change && values.change.data) &&
            <div>
              {"Artikeldaten ändern"}
            </div>
            }
            {(values.change && values.change.packshot) &&
            <div>
              {"Abbildung ändern"}
            </div>
          }
          </React.Fragment>
        } /></ListItem>


        {values.description && <ListItem className={classes.listItem}><ListItemText primary={"Beschreibung"} secondary={values.description} /></ListItem>}
        {(values.newFiles && (values.newFiles.length > 0)) && <ListItem className={classes.listItem}><ListItemText primary={`${values.newFiles.length} Datei${values.newFiles.length === 1 ? "":"en"}`} secondary={
          <React.Fragment>
            {values.newFiles.map(f => <div key={f.name}>{f.name} ({filesize(f.size)})</div>)}
          </React.Fragment>
          
        } /></ListItem>}
      </List>
    </React.Fragment>
  );
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    formValues: state.form.orderForm.values
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Review));