import { userConstants } from '../_constants'

export function user(state = {}, action) {
    switch (action.type) {
        case userConstants.HAS_ERRORED:
            return action.hasErrored;
        case userConstants.IS_LOADING:
            return action.isLoading;
        case userConstants.FETCH_DATA_SUCCESS:
            return action.user;
        case userConstants.INFO_SUCCESS:
            return action.user;
        case userConstants.INFO_FAILURE:
            return action.error;
        // case "@@redux-form/CHANGE":
        //     return Object.assign({}, state, {[action.meta.field]: action.payload} )
        case userConstants.SET_INFO_SUCCESS:
            // return Object.assign({}, state, action.user )
            return action.user;
        default:
            return state;
    }
}