import React, { Component} from 'react';
// import Button from '@material-ui/core/Button';
// import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ReactPiwik from 'react-piwik';

const styles = theme => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  list: {
    border: '1px solid rgba(0,0,0,0.25)',
    borderRadius: 5
  },
});

class AssetDownloadDropdownComponent extends Component {
  // state = {
  //   anchorEl: this.props.anchorEl,
  // };

  // handleClick = event => {
  //   this.setState({ anchorEl: event.currentTarget });
  // };

  handleClose = () => {
    // this.setState({ anchorEl: null });
    this.props.handleDownloadClose(null)
  };

  handleTrackDownload = () => {
    ReactPiwik.push(['trackEvent', this.props.data.name ? this.props.data.name : "NO NAME", this.props.jde ? "Intern" : "Extern" ]);
    this.handleClose();
  }

  render() {
    const { anchorEl, data, classes, lang } = this.props;

    return (
      <Popover 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={this.handleClose}
      >
        <List className={classes.list} dense={true}>
        { data.relatedAsset ? 
          <div>
            <ListItem button onClick={this.handleTrackDownload}>
              <a className={classes.link + " piwik_download"} href={data.relatedAsset.originalTicket}>
                <ListItemText primary={data.relatedAsset[`mediaTypeName_${lang}`]}/>
              </a>
            </ListItem>
            <Divider/>
          </div> : null
        }
          <ListItem button onClick={this.handleTrackDownload}>
            <a className={classes.link + " piwik_download"} href={data.originalTicket}>
              <ListItemText primary={data[`mediaTypeName_${lang}`]}/>
            </a>
          </ListItem>
        </List>
      </Popover>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang,
    jde: state.authentication.user.jde,
  }
}

const AssetDownloadDropdown = connect(mapStateToProps)(withStyles(styles)(AssetDownloadDropdownComponent));
export { AssetDownloadDropdown };