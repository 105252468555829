import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom'
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';
// import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';
import 'moment-timezone';
// localize date time

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    // minWidth: 700,
    width: '100%'
  },
  cell: {
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  },
  link: {
    color:'inherit'
  }
});

const catLabelLt = {
  "1": "vordere Ansicht mit Perspektive/Aufsicht #C1C1",
  "2": "linke Ansicht (von schräg links) mit Perspektive #C1L1",
  "3": "rechte Ansicht (von schräg rechts) mit Perspektive #C1R1",
  "4": "frontale Ansicht ohne Perspektive #C1N1",
  "5": "frontale Rückansicht ohne Perspektive #C7N1",
  "6": "frontale Rückansicht mit Perspektive #C7C1",
  "7": "frontale Seitenansicht rechts ohne Perspektive #C8N1",
  "8": "frontale Seitenansicht rechts mit Perspektive #C8C1",
  "9": "frontale Seitenansicht links ohne Perspektive #C2N1",
  "10": "frontale Seitenansicht links mit Perspektive #C2C1",
  "11": "untere Ansicht #C9N1",
  "12": "obere Ansicht #C3N1",
}

function AssetTableComponent(props) {
  const { asset, classes, translate, lang, user } = props;
  const customFields = asset.customFields || [];
  return (
      <Table className={classes.table}>
        <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>{translate("asset.metadata")}</TableCell>
              <TableCell className={classes.cell}></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={asset.id}>
            <TableCell className={classes.cell}>{translate("asset.uploadDate")}</TableCell>
            <TableCell className={classes.cell}><Moment format="DD.MM.YYYY HH:ss" date={asset.creationDate} /></TableCell>
          </TableRow>
          { user.isSuperUser &&
            <TableRow key="0">
              <TableCell className={classes.cell}>{translate("asset.createdBy")}</TableCell>
              <TableCell className={classes.cell}><a href={`mailto:${asset.createdByUser.userName}?subject=${asset.name}%20(${asset.id})`} className={classes.link}>{`${asset.createdByUser.firstname} ${asset.createdByUser.lastname}`}</a></TableCell>
            </TableRow>
          } { user.isSuperUser &&
            <TableRow key="1">
              <TableCell className={classes.cell}>{translate("asset.lastModifiedBy")}</TableCell>
              <TableCell className={classes.cell}><a href={`mailto:${asset.lastModifiedByUser.userName}?subject=${asset.name}%20(${asset.id})`} className={classes.link}>{`${asset.lastModifiedByUser.firstname} ${asset.lastModifiedByUser.lastname}`}</a></TableCell>
            </TableRow>
          }
          { customFields.map(customField => (customField.fieldId === "104" || customField.fieldId === "103") ? null : (
            <TableRow key={customField.fieldId}>
              <TableCell className={classes.cell}>{customField.name[lang]}</TableCell>
              <TableCell className={classes.cell}>
                {
                    (customField.fieldId === "101" || customField.fieldId === "100")
                  ?
                    <Link to={`/search/${customField.value}`} className={classes.link}>{customField.value}</Link>
                  :
                    customField.fieldId === "102"
                  ?
                    catLabelLt[customField.value]
                  :
                    customField.value
                }
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    user: state.authentication.user,
    isLoading: (state.assetIsLoading || state.assetsIsLoading),
  };
};

const AssetTable = connect(mapStateToProps)(withLocalize(withStyles(styles)(AssetTableComponent)));

export { AssetTable }