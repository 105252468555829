// modules
import React, { Component } from 'react';

// REDUX
import { connect } from 'react-redux';
import {
  downloadGetAll
} from '../../_actions/download.actions';

// LOCALS
import { DownloadExpansionPanel } from '../../components/DownloadExpansionPanel';
import { Headline } from '../../components/Headline';

class DownloadsView extends Component {
  componentDidMount(){
    this.props.downloadGetAll();
    document.title = `JDE mediaBOX | Downloads`;
  }

  render() {
    const { downloads } = this.props;
    return(
      <div className='root'>
        <Headline>Downloads</Headline>
        <DownloadExpansionPanel>
          {downloads}
        </DownloadExpansionPanel>
      </div>
    )    
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    downloadGetAll: () => dispatch(downloadGetAll()),
  };
};

const mapStateToProps = state => {
  return {
    downloads: state.downloads,
  }
}

const Downloads = connect(mapStateToProps, mapDispatchToProps)(DownloadsView);

export { Downloads }