import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux'
import axios from 'axios';
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';

// MUI
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';


// Components
import Logo from '../../img/logo.png';
import { Footer } from '../../components/Footer';
import { SnackBar } from '../../components/SnackBar';
import { devendpoint } from '../../api';


const styles = theme => ({
  ...theme,
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'url(https://jde-mediabox.com/img)',
  },
  card: {
    ...theme.mixins.gutters(),
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing.unit * 2,
    float: 'none',
    // maxWidth: 720,
    
    [theme.breakpoints.between('xs','sm')]: {
      marginTop: '0em',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '6em',
      minWidth: 840,
    },
    zIndex: 2,
  },
  media: {
    height: 140,
    width: 140,
    margin: '0 auto',
    marginTop: 10
  },
  headline: {
    textAlign: 'center',
    paddingBottom: 10,
    paddingTop: 10,
  },
  text: {
    lineHeight: '1.4em',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  grid: {
    width: '100%',
  },
  formControl: {
    width: '100%'
  }
});

// const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

const isEmail = (email = null) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

class ContactView extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleOpenDialog = this.handleOpenDialog.bind(this);
  
    this.state = {
      form: {
        firstname: "",
        lastname: "",
        email: "",
        message: ""
      },
      error: false,
      isLoading: false,
      success: false,
    }
  }

  componentDidMount() {
    document.title = `JDE mediaBOX | ${this.props.translate("contact.title")}`;
  }

  handleSubmit(event) {
    const { authentication } = this.props;
    const { firstname, lastname, email, message } = this.state.form;

    event.preventDefault();
    this.setState({ isLoading: true });
    
    // console.log(this.state.form);

    // setTimeout(() => {
    //   this.setState({ isLoading: false, success: true });
    // },1500);
    
    const data = {
      name: `${firstname} ${lastname}`,
      email,
      message,
      userId: authentication.loggedIn ? authentication.user.userId : undefined
    }

    axios({
      method: 'post',
      url: `${devendpoint}/user/contact`,
      data: data,
    })
    .catch(e => {
      // console.log(e);
      this.setState({ isLoading: false, success: false });
    })
    .then(response => {
      // console.log(response);
      this.setState({ isLoading: false, success: true });  
    })
  }

  handleChange(e){
    var form = {...this.state.form};
    form[e.target.name] = e.target.value;
    this.setState({ form });
    // console.log(form)
  }

  render() {
    const { classes, translate, loggedIn } = this.props;
    const { isLoading, success } = this.state;
    return(
      <div className={classes.root}>
        <Card className={classes.card} elevation={1}>
          { loggedIn &&
          <CardHeader
            className={classes.header}
            action={
              <IconButton component={Link} to="/">
                <CloseIcon/>
              </IconButton>
            }
          />
          }
          <CardMedia
            className={classes.media}
            image={Logo}
            title="JDE mediaBOX"
          />
          <Typography className={classes.headline} variant="h5" component="h3">
            {translate("contact.title")}
          </Typography>
          <Grid className={classes.grid} container spacing={8}>
            <Grid className={classes.left} item xs={12} sm={6} md={6}>
              <Paper className={classes.paper}>
                <Typography variant="h6">{translate("contact.subtitle1")}:</Typography>
                
                <Typography variant="body2" className={classes.text}>R&R GmbH Bremen</Typography>
                <Typography variant="body2" className={classes.text}>Senator-Bömers-Str. 20</Typography>
                <Typography variant="body2" className={classes.text}>28197 Bremen</Typography>
                                
                <Typography variant="h6">{translate("contact.subtitle2")}:</Typography>
                <Typography variant="body2" className={classes.text}>Philip Meyerdierks</Typography>
                <Typography variant="body2" className={classes.text}>Marc Seidel</Typography>
                <Typography variant="body2" className={classes.text}>{translate("contact.email2")}: <a href="mailto:support@jde-mediabox.com" style={{ color: 'inherit'}}>support@jde-mediabox.com</a></Typography>
                <Typography variant="body2" className={classes.text}>{translate("contact.phone")}: +49 (0) 421 52150-0</Typography>
                
                <Typography variant="h6">{translate("contact.subtitle3")}:</Typography>
                <Typography variant="body2" className={classes.text}>JDE - Jacobs Douwe Egberts</Typography>
                <Typography variant="body2" className={classes.text}>Langemarckstraße 4 - 20</Typography>
                <Typography variant="body2" className={classes.text}>28199 Bremen</Typography>
                <Typography variant="body2" className={classes.text}>{translate("contact.email2")}: <a href="mailto:anfragen@jdecoffee.com" style={{ color: 'inherit'}}>anfragen@jdecoffee.com</a></Typography>
                <Typography variant="h6">{translate("contact.subtitle4")}:</Typography>
                <Typography variant="body2" className={classes.text}>Frauke Backhaus</Typography>
                <Typography variant="body2" className={classes.text}>{translate("contact.email2")}: <a href="mailto:frauke.backhaus@jdecoffee.com" style={{ color: 'inherit'}}>frauke.backhaus@jdecoffee.com</a></Typography>
                <Typography variant="body2" className={classes.text}>{translate("contact.phone")}: +49 (0) 421 42789-3327</Typography>
                <Typography variant="h6">{translate("contact.subtitle5")}:</Typography>
                <Typography variant="body2" className={classes.text}>Katharina Weid</Typography>
                <Typography variant="body2" className={classes.text}>{translate("contact.email2")}: <a href="mailto:katharina.weid@jdecoffee.com" style={{ color: 'inherit'}}>katharina.weid@jdecoffee.com</a></Typography>
                <Typography variant="body2" className={classes.text}>{translate("contact.phone")}: +49 (0) 421 42789-3077</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paper className={classes.paper}>
                <form onSubmit={this.handleSubmit}>

                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="firstname">{translate("contact.firstname")}</InputLabel>
                    <Input
                      name="firstname"
                      onChange={(e) => this.handleChange(e)}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="lastname">{translate("contact.lastname")}</InputLabel>
                    <Input
                      name="lastname"
                      onChange={(e) => this.handleChange(e)}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="email">{translate("contact.email")}</InputLabel>
                    <Input
                      name="email"
                      onChange={(e) => this.handleChange(e)}
                      error={!isEmail(this.state.form.email) && this.state.form.email.length > 0}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="message">{translate("contact.text")}</InputLabel>
                    <Input
                      name="message"
                      onChange={(e) => this.handleChange(e)}
                      multiline
                      rows="5"
                    />
                  </FormControl>
                  { isLoading && <LinearProgress color="primary" style={{ marginTop:'-5px', width: '100%'}} /> }
                  <CardActions>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      fullWidth
                      disabled={isLoading || this.state.form.firstname.length === 0 || this.state.form.lastname.length === 0 || this.state.form.email.length === 0 || !isEmail(this.state.form.email)}
                    >
                      {translate("contact.sendBtn")}
                    </Button>
                  </CardActions>
                  
                </form>

              </Paper>
            </Grid>
          </Grid>

         

        </Card>
        <Footer layout="static" active="contact" />

        <SnackBar
          open={success}
          message={translate("contact.success")}
          variant="success"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authentication.loggedIn,
    lang: state.lang,
    localize: state.localize,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // uiSetLang: (lang) => dispatch(uiSetLang(lang)),
  };
};

const connectedContactPage =
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withLocalize
  )(ContactView)

export { connectedContactPage as Contact }; 

