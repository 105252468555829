import { orderConstants } from '../_constants';
import { orderApi } from '../api';
import { history } from '../_helpers';

export const orderActions = {
  getAll,
  getOne,
  addOne,
  updateOne,
  updateReview,
  updateLoading,
  removeFile,
  deactivateOne,
};

function getAll() {
  return dispatch => {
    dispatch(request(true));
    orderApi.getAll()
    .then(
      orders => {
        if(orders.status === 403)
          history.push('/login');
        dispatch(success(orders.data));
        dispatch(request(false));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(isLoading) { return { type: orderConstants.ORDER_REQUEST, isLoading } }
  function success(orders) { return { type: orderConstants.ORDERS_SUCCESS, orders } }
  function failure(error) { return { type: orderConstants.ORDERS_FAILURE, error } }
}

function getOne(orderId) {
  return dispatch => {
    dispatch(request(true));
    orderApi.getOne(orderId)
    .then(
      order => {
        if(order.status === 403)
          history.push('/login');
        dispatch(success(order.data));
        dispatch(request(false));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(isLoading) { return { type: orderConstants.ORDER_REQUEST, isLoading } }
  function success(order) { return { type: orderConstants.ORDER_SUCCESS, order } }
  function failure(error) { return { type: orderConstants.ORDER_FAILURE, error } }
}

function addOne(order) {
  return dispatch => {
    dispatch(request(true));
    orderApi.addOne(order)
    .then(
      order => {
        if(order.status === 403)
          history.push('/login');
        dispatch(success(order.data));
        dispatch(request(false));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(isLoading) { return { type: orderConstants.ORDER_REQUEST, isLoading }}
  function success(order) { return { type: orderConstants.ORDER_SUCCESS, order }}
  function failure(error) { return { type: orderConstants.ORDER_FAILURE, error }}
}

function updateOne(order) {
  return dispatch => {
    dispatch(request(true));
    orderApi.updateOne(order)
    .then(
      response => {
        if(response.status === 403)
          history.push('/login');
        dispatch(success(response.data));
        dispatch({type: "SNACK_SHOW", snack: { msg: "snack.saved", variant: "success", open: true }});
        dispatch(request(false));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(isLoading) { return { type: orderConstants.ORDER_UPT_REQUEST, isLoading } }
  function success(order) { return { type: orderConstants.ORDER_UPT_SUCCESS, order } }
  function failure(error) { return { type: orderConstants.ORDER_UPT_FAILURE, error } }
}

function deactivateOne(orderId) {
  return dispatch => {
    dispatch(request(true));
    orderApi.deactivateOne(orderId)
    .then(
      response => {
        if(response.status === 403)
          history.push('/login');
        dispatch(success(response.data));
        dispatch({type: "SNACK_SHOW", snack: { msg: "snack.saved", variant: "success", open: true }});
        history.push('/orders');
        dispatch(request(false));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(isLoading) { return { type: orderConstants.ORDER_UPT_REQUEST, isLoading } }
  function success(order) { return { type: orderConstants.ORDER_UPT_SUCCESS, order } }
  function failure(error) { return { type: orderConstants.ORDER_UPT_FAILURE, error } }
}

function removeFile(orderId, fileId) {
  return dispatch => {
    dispatch(request(true));
    orderApi.removeFile(orderId, fileId)
    .then(
      response => {
        if(response.status === 403)
          history.push('/login');
        dispatch(success(response.data));
        dispatch({type: "SNACK_SHOW", snack: { msg: "snack.removed", variant: "success", open: true }});
        dispatch(request(false));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(isLoading) { return { type: orderConstants.ORDER_REMOVE_FILE_REQUEST, isLoading } }
  function success(order) { return { type: orderConstants.ORDER_REMOVE_FILE_SUCCESS, order } }
  function failure(error) { return { type: orderConstants.ORDER_REMOVE_FILE_FAILURE, error } }
}

function updateReview(orderId, data) {
  return dispatch => {
    dispatch(request(true));
    orderApi.updateReview(orderId, data)
    .then(
      response => {
        dispatch(success(response.data));
        dispatch({type: "SNACK_SHOW", snack: { msg: "snack.saved", variant: "success", open: true }});
        dispatch(request(false));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(isLoading) { return { type: orderConstants.ORDER_SET_STATUS_REQUEST, isLoading } }
  function success(order) { return { type: orderConstants.ORDER_SET_STATUS_SUCCESS, order: { order } } }
  // function showSnack(snack) { return { type: "SNACK_SHOW", snack } }
  function failure(error) { return { type: orderConstants.ORDER_SET_STATUS_FAILURE, error } }
}

function updateLoading(percentage) {
  return dispatch => {
    dispatch(loading(percentage));
    if(percentage === 100)
      dispatch(success(true));
  };

  function loading(percentage) { return { type: orderConstants.ORDER_UPLOAD_LOADING, percentage } }
  function success(success) { return { type: orderConstants.ORDER_UPLOAD_SUCCESS, success } }
}