import React from 'react';
import PropTypes from 'prop-types';
import Image from "react-graceful-image";

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// COMPONENTS
import { AssetTable } from '../AssetTable';
import { DetailViewDownloadOptions } from './DetailViewDownloadOptions';
import { AssetName } from '../AssetName';

const styles = theme => ({
  root: {
    flexGrow: 1,
    // margin: 10,
    // padding: 20,
    // width: '95%'
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      margin: -10,
    },
  },
  image: {
    maxWidth: 320,
    maxHeight: 320,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 'auto'
    // minWidth: 320,
    // minHeight: 320,
  },
  paper: {
    padding: 20,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  }
});

var DetailView = (props) => {
// function DetailView(props) {
  const { classes, asset } = props;
  if(Object.keys(asset).length === 0)
    return null;

  return (
    <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Typography variant="h5" gutterBottom><AssetName>{asset.name}</AssetName></Typography>
              <Divider className={classes.divider}/>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6} sm={12}>
                  <Image
                    src={asset.largePreview}
                    height="320"
                    className={classes.image}
                    alt={asset.name}
                    retry={{ count: 15, delay: 1, accumulate: "add" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <AssetTable asset={asset} />
                  <DetailViewDownloadOptions />
                </Grid>
              </Grid>

            </Paper>
          </Grid>
        </Grid>
    </div>
  );
}

DetailView.propTypes = {
  classes: PropTypes.object.isRequired,
};
DetailView = withStyles(styles)(DetailView)
export { DetailView }