import React from 'react';
import Typography from '@material-ui/core/Typography';

const PrivacyGerman = props => {
  const { classes } = props;
  return(
    <div>
          <Typography variant="body2" className={classes.text}>Wir freuen uns über Ihren Besuch auf unseren Webseiten. Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gem. Art. 13 Datenschutzgrundverordnung (DSGVO) informieren.</Typography>
          
          <Typography variant="h6">Verantwortlicher</Typography>
          <Typography variant="body2" className={classes.text}>Verantwortlich für die nachfolgend dargestellte Datenerhebung und -verarbeitung ist die im <a href="/imprint">Impressum</a> genannte Stelle.</Typography>

          <Typography variant="h6">Speicherung der IP-Adresse</Typography>
          <Typography variant="body2" className={classes.text}>Wir speichern die von Ihrem Webbrowser übermittelte IP-Adresse streng zweckgebunden für die Dauer von sieben Tagen, in dem Interesse, Angriffe auf unsere Webseiten erkennen, eingrenzen und beseitigen zu können. Nach Ablauf dieser Zeitspanne löschen bzw. anonymisieren wir die IP-Adresse. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</Typography>
          
          <Typography variant="h6">Nutzungsdaten</Typography>
          <Typography variant="body2" className={classes.text}>Wenn Sie unsere Webseiten besuchen, werden auf unserem Webserver temporär sogenannte Nutzungsdaten zu statistischen Zwecken als Protokoll gespeichert, um die Qualität unserer Webseiten zu verbessern. Dieser Datensatz besteht aus</Typography>
          <Typography variant="body2" className={classes.text}>- der Seite, von der aus die Datei angefordert wurde,</Typography>
          <Typography variant="body2" className={classes.text}>- dem Namen der Datei,</Typography>
          <Typography variant="body2" className={classes.text}>- dem Datum und der Uhrzeit der Abfrage,</Typography>
          <Typography variant="body2" className={classes.text}>- der übertragenen Datenmenge,</Typography>
          <Typography variant="body2" className={classes.text}>- dem Zugriffsstatus (Datei übertragen, Datei nicht gefunden),</Typography>
          <Typography variant="body2" className={classes.text}>- der Beschreibung des Typs des verwendeten Webbrowsers,</Typography>
          <Typography variant="body2" className={classes.text}>- der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar ist. </Typography>
          <Typography variant="body2" className={classes.text}>Die genannten Protokolldaten werden nur anonymisiert gespeichert.</Typography>

          <Typography variant="h6">Dienstleister</Typography>
          <Typography variant="body2" className={classes.text}>Teilweise setzen wir Dienstleister (z.B. Hosting-Dienstleister, Agenturen) ein, die uns beim Betrieb unserer Webseiten und der damit zusammenhängenden Prozesse unterstützen. Unsere Dienstleister sind uns gegenüber streng weisungsgebunden und entsprechend vertraglich verpflichtet.</Typography>
          
          <Typography variant="h6">Cookies</Typography>
          <Typography variant="body2" className={classes.text}>Auf unseren Webseiten nutzen wir Cookies. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert und ausgelesen werden können. Man unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert werden. Cookies können Daten enthalten, die eine Wiedererkennung des genutzten Geräts möglich machen. Teilweise enthalten Cookies aber auch lediglich Informationen zu bestimmten Einstellungen, die nicht personenbeziehbar sind. </Typography>
          <Typography variant="body2" className={classes.text}>Wir setzen Cookies nur im Zusammenhang mit dem Analyse-Tool „Matomo“ ein (siehe dazu unten).</Typography>
          <Typography variant="body2" className={classes.text}>Sie können Ihren Browser so einstellen, dass er Sie über die Platzierung von Cookies informiert. So wird der Gebrauch von Cookies für Sie transparent. Sie können Cookies zudem jederzeit über die entsprechende Browsereinstellung löschen und das Setzen neuer Cookies verhindern. Bitte beachten Sie, dass unsere Webseiten dann ggf. nicht optimal angezeigt werden und einige Funktionen technisch nicht mehr zur Verfügung stehen.</Typography>

          <Typography variant="h6">Matomo</Typography>
          <Typography variant="body2" className={classes.text}>Zur bedarfsgerechten Gestaltung unserer Webseiten erstellen wir pseudonyme Nutzungsprofile mit Hilfe des Webanalysetools Matomo. Matomo verwendet Cookies, die auf Ihrem Endgerät gespeichert und von uns ausgelesen werden können. Auf diese Weise sind wir in der Lage, wiederkehrende Besucher zu erkennen und als solche zu zählen. Eine Speicherung der ungekürzten IP-Adresse erfolgt nicht. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO.</Typography>
          <Typography variant="body2" className={classes.text}>Sie können der Verarbeitung jederzeit widersprechen. Klicken Sie dazu bitte das folgende Kästchen an. In diesem Fall wird in Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten erhebt. Wenn Sie Ihre Cookies löschen, hat dies allerdings zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und ggf. von Ihnen erneut aktiviert werden muss.</Typography>


          <iframe
            title="Opt-Out"
            style={{border: 0, height: '12em', width: '100%'}}
            src="https://tracking.jde-mediabox.com/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontSize=0.875rem&fontFamily=%22Roboto%22%2C%20%22Helvetica%22%2C%20%22Arial%22%2C%20sans-serif">
          </iframe>


          <Typography variant="h6">Zugangsbeschränkter Bereich</Typography>
          <Typography variant="body2" className={classes.text}>Wenn Sie Zugriff auf unsere aktuellen Produktabbildungen erhalten möchten, müssen Sie sich zuvor registrieren. Ihre Daten erheben wir dabei auf Grundlage von Art. 6 Abs. 1 S. 1 lit. b DSGVO. Daten, die Sie im Rahmen der Registrierung bzw. Ihrer Anfragen an uns übermitteln, verwenden wir, um Sie im System zu identifizieren und Ihnen die passenden Inhalte auf Grundlage der Berechtigungsstruktur übermitteln zu können. Ihr Profil können Sie innerhalb des Accounts jederzeit ändern oder auch löschen. Die Daten werden automatisch aus unserem System entfernt, sofern keine Aufbewahrungsfristen bestehen oder die Daten nicht im Einzelfall doch noch erforderlich sind (etwa im Falle von offenen Forderungen zum Einzug der Forderungen).</Typography>

          <Typography variant="h6">Datensicherheit</Typography>
          <Typography variant="body2" className={classes.text}>Um Ihre Daten vor unerwünschten Zugriffen möglichst umfassend zu schützen, treffen wir technische und organisatorische Maßnahmen. Wir setzen auf unseren Seiten ein Verschlüsselungsverfahren ein. Ihre Angaben werden von Ihrem Rechner zu unserem Server und umgekehrt über das Internet mittels einer TLS-Verschlüsselung übertragen. Sie erkennen dies daran, dass in der Statusleiste Ihres Browsers das Schloss-Symbol geschlossen ist und die Adresszeile mit https:// beginnt. </Typography>

          <Typography variant="h6">Ihre Rechte als Nutzer</Typography>
          <Typography variant="body2" className={classes.text}>Bei Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO Ihnen als Webseitennutzer bestimmte Rechte:</Typography>
          <Typography variant="body2" className={classes.textP}>1. Auskunftsrecht (Art. 15 DSGVO):</Typography>
          <Typography variant="body2" className={classes.text}>Sie haben das Recht eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im einzelnen aufgeführten Informationen.</Typography>
          <Typography variant="body2" className={classes.textP}>2. Recht auf Berichtigung und Löschung (Art. 16 und 17 DSGVO):</Typography>
          <Typography variant="body2" className={classes.text}>Sie haben das Recht, unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</Typography>
          <Typography variant="body2" className={classes.text}>Sie haben zudem das Recht, zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im einzelnen aufgeführten Gründe zutrifft, z. B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden.</Typography>
          <Typography variant="body2" className={classes.textP}>3. Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO):</Typography>
          <Typography variant="body2" className={classes.text}>Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z.B. wenn Sie Widerspruch gemäß Art. 21 DSGVO gegen die Verarbeitung eingelegt haben oder für die Dauer einer etwaigen Prüfung, ob unsere berechtigten Interessen gegenüber Ihren Interessen als betroffene Person überwiegen.</Typography>
          <Typography variant="body2" className={classes.textP}>4. Recht auf Datenübertragbarkeit (Art. 20 DSGVO):</Typography>
          <Typography variant="body2" className={classes.text}>In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt werden, haben Sie das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.</Typography>
          <Typography variant="body2" className={classes.textP}>5. Widerspruchsrecht (Art. 21 DSGVO):</Typography>
          <Typography variant="body2" className={classes.text}>Werden Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO (Datenverarbeitung zur Wahrung berechtigter Interessen) erhoben, steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die gegenüber Ihren Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</Typography>
          <Typography variant="body2" className={classes.textP}>6. Beschwerderecht bei einer Aufsichtsbehörde:</Typography>
          <Typography variant="body2" className={classes.text}>Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.</Typography>
          <Typography variant="body2" className={classes.text}>Kontaktdaten des Datenschutzbeauftragten</Typography>
          <Typography variant="body2" className={classes.text}>Unser betrieblicher Datenschutzbeauftragter steht Ihnen gerne für Auskünfte oder Anregungen zum Thema Datenschutz zur Verfügung:</Typography>
          <Typography variant="body2" className={classes.text}>Dr. Uwe Schläger</Typography>
          <Typography variant="body2" className={classes.text}>datenschutz nord GmbH</Typography>
          <Typography variant="body2" className={classes.text}>Konsul-Smidt-Straße 88</Typography>
          <Typography variant="body2" className={classes.text}>28217 Bremen</Typography>
          <Typography variant="body2" className={classes.text}>Web: <a href="https://www.datenschutz-nord-gruppe.de">www.datenschutz-nord-gruppe.de</a></Typography>
          <Typography variant="body2" className={classes.text}>E-Mail: <a href="mailto:office@datenschutz-nord.de">office@datenschutz-nord.de</a></Typography>
    </div>
  )
}

export { PrivacyGerman }