// modules
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form'
import { withLocalize } from "react-localize-redux";
import axios from 'axios';

import { withRouter } from 'react-router-dom';

// MUI
import { withStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import { TextField } from 'redux-form-material-ui';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

// REDUX
import { connect } from 'react-redux';
import { userActions } from '../../_actions';

// LOCALS
import { Headline } from '../../components/Headline';
import { SnackBar } from '../../components/SnackBar';

const styles = theme => ({
  container: {
    // display: 'flex',
    // flexWrap: 'wrap',
    padding: theme.spacing.unit,
  },
  textField: {
    margin: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    width: 320,
    display: 'flex',
  },
  dense: {
    marginTop: 19,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  deleteButton: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  error: {
    color: red[500],
    margin: theme.spacing.unit,
  }
});

const renderedTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}

    {...input}
    {...custom}
  />
)

class UserForm extends Component {
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  state = {
    isLoading: false,
  };

  componentDidMount(){
    this.props.getUserData();
  }

  handleClick() {
    // console.log(this.props.userForm.values);
    this.setState({ isLoading: true });
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',  
      'Authorization': `Bearer ${user.token}`
    }
    axios({
      method: 'post',
      url: `https://api.jde-mediabox.com/user/`,
      data: { user:this.props.userForm.values },
      headers
    })
    .then(response => {
      this.setState({ isLoading: false });
      this.props.success(true);
      // console.log(response);
    });
  }



  render(){
    const { classes, translate, userForm } = this.props;
    const { isLoading } = this.state;
    // console.log({userForm});
    const passwordError = () => {
      if(!userForm || !userForm.values || !userForm.values.newPassword || !userForm.values.newPasswordRepeat) 
        return false;
      if(userForm.values.newPassword.length < 8)
        return "AppBar.profile.errors.tooShort";
      if(userForm.values.newPasswordRepeat.length > 1 && userForm.values.newPasswordRepeat !== userForm.values.newPassword)
        return "AppBar.profile.errors.doNotMatch";
    }

    return (
      <form>
        <Field name="firstname" type="text" component={renderedTextField} label={translate("AppBar.profile.firstname")} className={classes.textField} />
        <Field name="lastname" type="text" component={renderedTextField} label={translate("AppBar.profile.lastname")} className={classes.textField} />
        <Field name="userName" type="email" component={renderedTextField} label={translate("AppBar.profile.username")} className={classes.textField} disabled={true} />
        <Field name="company" type="text" component={renderedTextField} label={translate("AppBar.profile.company")} className={classes.textField} />
        <Field name="phone" type="text" component={renderedTextField} label={translate("AppBar.profile.phone")} className={classes.textField} />
        
        <Field name="newPassword" error={!!(passwordError())} type="password" component={renderedTextField} label={translate("AppBar.profile.newPassword")} className={classes.textField} />
        <Field name="newPasswordRepeat" error={!!(passwordError())} type="password" component={renderedTextField} label={translate("AppBar.profile.newPasswordRepeat")} className={classes.textField} />
        { !!(passwordError()) && <FormHelperText id="password-text" className={classes.error} error={!!(passwordError())}>{translate(passwordError())}</FormHelperText>}
        
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={this.handleClick}
          disabled={isLoading || !!(passwordError())}
        >
          <SaveIcon className={classes.leftIcon} />
          { isLoading ? translate("AppBar.profile.saving") : translate("AppBar.profile.save") }
        </Button>
      </form>

    )
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
UserForm = reduxForm({
  form: 'userForm', // a unique identifier for this form
  enableReinitialize: true
})(withStyles(styles)(withLocalize(UserForm)))

// You have to connect() to any reducers that you wish to connect to yourself
UserForm = connect(
  state => ({
    initialValues: state.user, // pull initial values from account reducer
    userForm: state.form.userForm,
  }),
  { getUserData: userActions.getInfo }, // bind account loading action creator
)(UserForm)




class ProfileView extends Component {
  constructor(props){
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }
  
  componentDidMount(){
    document.title = `JDE mediaBOX | ${this.props.translate("AppBar.profile.title")}`;
  }

  state = {
    isLoading: false,
    open: false,
    password: "",
    passwordError: false,
    success: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, passwordError: false, password: "" });
  };

  handlePasswordChange = e => {
    this.setState({ [e.target.name]: e.target.value, passwordError: false });
    // console.log(e.target.name, e.target.value);
  }

  handleDeleteClick() {
    // console.log(this.props.userForm.values);
    this.setState({ isLoading: true });

    const user = JSON.parse(localStorage.getItem('user'));
    const { password } = this.state;
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',  
      'Authorization': `Bearer ${user.token}`
    }
    axios({
      method: 'DELETE',
      url: `https://api.jde-mediabox.com/user/`,
      data: { user, password },
      headers
    })
    .then(response => {
      this.setState({ isLoading: false });
      if(response.status === 200){
        // console.log(response);
        localStorage.removeItem('user');
        this.props.history.push('/login');
      }
      else
        this.setState({ passwordError: true });
    })
    .catch(error => {
      this.setState({ passwordError: true, isLoading: false });
    });
  }

  handleSuccess(success){
    this.setState({success});
  }

  render() {
    const { classes, translate } = this.props;
    const { passwordError, isLoading, success } = this.state;

    return(
      <div className='root'>
        <Headline>{translate("AppBar.profile.title")}</Headline>
        <Paper className={classes.container}>
          <UserForm success={this.handleSuccess} />
          <Button color="primary" className={classes.button} onClick={this.handleClickOpen}>
            {translate("AppBar.profile.deleteBtn")}
          </Button>
        </Paper>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{translate("AppBar.profile.deleteDialog.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate("AppBar.profile.deleteDialog.text")}
            </DialogContentText>

            <FormControl error={passwordError}>
              <InputLabel htmlFor="password">{translate("AppBar.profile.deleteDialog.passwordLabel")}</InputLabel>
              <Input
                name="password"
                value={this.state.name}
                onChange={this.handlePasswordChange}
                margin="dense"
                type="password"
                aria-describedby="password-text"
                fullWidth
                autoFocus
              />

              { passwordError && <FormHelperText id="password-text">{translate("AppBar.profile.deleteDialog.passwordError")}</FormHelperText>}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {translate("AppBar.profile.deleteDialog.cancelBtn")}
            </Button>
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={this.handleDeleteClick}
              disabled={isLoading || this.state.password.length < 8}
            >
              <DeleteIcon className={classes.leftIcon} />
              {translate("AppBar.profile.deleteDialog.deleteBtn")}
            </Button>
          </DialogActions>
        </Dialog>
        <SnackBar
          open={success}
          message={translate("AppBar.profile.success")}
          variant="success"
        />
      </div>
    )    
  }
}

const Profile = withRouter(withLocalize(withStyles(styles)(ProfileView)));

export { Profile }