import "isomorphic-fetch";
// import { devheaders } from '../api';
import { nodeConstants } from '../_constants';
import { history } from '../_helpers';
// const APIKEY = '8d6lepmgrcjku9l7i1b8m7po9f'
// const API = 'https://dam.celum.net/cora'
const API = 'https://api.jde-mediabox.com'
// const lang = 'de';
// const headers = {
//   'Accept': 'application/json',
//   'Content-Type': 'application/json',
//   'Authorization': `celumApiKey ${APIKEY}`
// }


// export const NODES_HAS_ERRORED = 'NODES_HAS_ERRORED';
// export const NODES_IS_LOADING = 'NODES_IS_LOADING';
// export const NODES_FETCH_DATA_SUCCESS = 'NODES_FETCH_DATA_SUCCESS';


function nodesHasErrored(bool) {
  return {
    type: nodeConstants.NODE_HAS_ERRORED,
    hasErrored: bool
  };
}

function nodesIsLoading(bool) {
  return {
    type: nodeConstants.NODE_IS_LOADING,
    isLoading: bool
  };
}

function nodesSetRoot(rootId) {
  return {
    type: nodeConstants.NODE_SET_ROOT,
    rootId
  };
}

function nodesFetchDataSuccess(nodes, id) {
  return {
    type: nodeConstants.NODE_FETCH_DATA_SUCCESS,
    nodes,
    id
  };
}

export function setRootId(id) {
  return (dispatch) => {
    dispatch(nodesSetRoot(id));
    return id;
  }
}

export function nodesFetchData(id) {
  return (dispatch) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',  
      'Authorization': `Bearer ${user.token}`
    }
    dispatch(nodesIsLoading(true));
    fetch(API+`/node/${id}`,{
      method: 'GET',
      headers
    })
      .then((response) => {
        if (!response.ok) {
          if(response.status === 403)
            history.push('/login');
          throw Error(response.statusText);
        }
        dispatch(nodesIsLoading(false));
        dispatch(nodesSetRoot(id));
        return response;
      })
      .then((response) => response.json())
      .then((nodes) => {
        dispatch(nodesFetchDataSuccess(nodes, id));
      })
      .catch(() => dispatch(nodesHasErrored(true)));
  };
}