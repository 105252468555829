// import { assetConstants } from '../_constants'

// export function asset(state = {}, action) {
//     switch(action.type) {
//         case assetConstants.ASSET_HAS_ERRORED:
//             return action.hasErrored;
//         case assetConstants.ASSET_IS_LOADING:
//             return action.isLoading;
//         case assetConstants.ASSET_FETCH_DATA_SUCCESS:
//             return action.asset;
//         default:
//             return state;
//     }
// }


import { assetConstants } from '../_constants'

export function assetHasErrored(state = false, action) {
  switch (action.type) {
    case assetConstants.ASSET_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function assetIsLoading(state = false, action) {
  switch (action.type) {
    case assetConstants.ASSET_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function asset(state = {}, action) {
  switch (action.type) {
    case assetConstants.ASSET_FETCH_DATA_SUCCESS:
      return action.asset;
    default:
      return state;
  }
}
