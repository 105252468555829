export const cartConstants = {
  CART_HAS_ERRORED: 'CART_HAS_ERRORED',
  CART_IS_LOADING: 'CART_IS_LOADING',
  CART_DOWNLOAD_IS_LOADING: 'CART_DOWNLOAD_IS_LOADING',
  CART_FETCH_DATA_SUCCESS: 'CART_FETCH_DATA_SUCCESS',
  CART_GET_ONE_SUCCESS: 'CART_GET_ONE_SUCCESS',
  CART_GET_ALL_SUCCESS: 'CART_GET_ALL_SUCCESS',
  CART_ADD_ONE_SUCCESS: 'CART_ADD_ONE_SUCCESS',
  CART_ADD_MANY_SUCCESS: 'CART_ADD_MANY_SUCCESS',
  CART_DEL_ONE_SUCCESS: 'CART_DEL_ONE_SUCCESS',
  CART_DEL_MANY_SUCCESS: 'CART_DEL_MANY_SUCCESS',
  CART_CLEAR_SUCCESS: 'CART_CLEAR_SUCCESS',
  CART_SEND_SUCCESS: 'CART_SEND_SUCCESS',
  CART_DOWNLOAD_SUCCESS: 'CART_DOWNLOAD_SUCCESS',
  CART_ADD_ALL_FORMAT: 'CART_ADD_ALL_FORMAT',
  CART_REMOVE_ALL_FORMAT: 'CART_REMOVE_ALL_FORMAT',
  CART_UPDATE_CHECKBOXES: 'CART_UPDATE_CHECKBOXES',
  CART_DOWNLOAD_LIST_SUCCESS: 'CART_DOWNLOAD_LIST_SUCCESS',
  CART_DOWNLOAD_LIST_IS_LOADING: 'CART_DOWNLOAD_LIST_IS_LOADING',
};
