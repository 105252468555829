import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BasisForm from './BasisForm';
import FilesForm from './FilesForm';
import Review from './Review';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      // marginLeft: 'auto',
      // marginRight: 'auto',
      marginTop: '-4em',
    },
  },
  paper: {
    // marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: theme.spacing.unit * 3,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 1,
  },
});

const steps = ['Basisdaten', 'Dateien', 'Zusammenfassung'];

function getStepContent(step) {
  let lt = {
    0: <BasisForm new={true} />,
    1: <FilesForm />,
    2: <Review />
  }
  return lt[step];
}

class OrderAddForm extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));

    if(this.state.activeStep === steps.length-1){
      let data = this.props.order;
      var formData = new FormData();
      

      // Object.keys(data).forEach((key) => {
      //   if(key === 'files')
      //     data[key].forEach(file => {
      //       console.log(file);
      //       formData.append("files[]", file.blob, file.name)
      //     })
      //   else
      //     formData.append(key, data[key]);
      // });
      
      Object.keys(data).forEach(key => {
        if(key === 'packshot' || key === 'change')
          formData.append(key, JSON.stringify(data[key]));
        else if(key !== 'newFiles')
          formData.append(key, data[key]);
      });


      if(data.newFiles){
        data.newFiles.forEach(file => {
          formData.append("files", file);
        })
      }
      
      this.props.addOne(formData);
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  // handleReset = () => {
  //   this.setState({
  //     activeStep: 0,
  //   });
  // };

  verifyValues = (activeStep) => {
    if(activeStep === 0){
      return !!(this.props.order && this.props.order.name)
    }
    else
      return true;
  }

  render() {
    const { classes, orderResponse } = this.props;
    const { activeStep } = this.state;
    // console.log(orderResponse);

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Vielen Dank für Ihren Auftrag.
                  </Typography>
                  <Typography variant="subtitle1">
                    Ihre Auftragsnummer lautet <strong>#{orderResponse.order.orderNumber}</strong>. Sie erhalten in Kürze eine Auftragsbestätigung per Mail.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button}>
                        Zurück
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                      disabled={!this.verifyValues(activeStep)}
                    >
                      {activeStep === steps.length - 1 ? 'Auftrag erstellen' : 'Weiter'}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

OrderAddForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

OrderAddForm = withStyles(styles)(OrderAddForm);
export { OrderAddForm }
