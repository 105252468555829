import React, {Component} from 'react';
// import PropTypes from 'prop-types';

// import { withStyles } from '@material-ui/core/styles';
// import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';

// REDUX
import { cartRemoveOne } from '../../_actions/'
import { connect } from 'react-redux';

class RemoveButtonComponent extends Component {
  render(){
    const { assetId, cartRemoveOne, view, isLoading } = this.props;
    return(
      <IconButton size="small" aria-label="Remove" onClick={() => cartRemoveOne(assetId)} disabled={isLoading || this.props.disabled}>
        <RemoveIcon fontSize={view === "list" ? "small" : "default"}/>
      </IconButton>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    cartRemoveOne: (id) => dispatch(cartRemoveOne(id))
  };
};

const mapStateToProps = state => {
  return {
    view: state.view,
    isLoading: state.cartIsLoading,
  }
}

const RemoveButton = connect(mapStateToProps, mapDispatchToProps)(RemoveButtonComponent)
export { RemoveButton }