import 'babel-polyfill';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { LocalizeProvider } from "react-localize-redux";
import { Provider } from "react-redux";
import { CookiesProvider } from 'react-cookie';

import './index.css';
import App from './App';
import theme from './theme';
import configureStore from './_store/configureStore';

const store = configureStore();

ReactDOM.render((
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizeProvider store={store}>
          <CookiesProvider>
            <App/>
          </CookiesProvider>
        </LocalizeProvider>
      </Provider>
    </MuiThemeProvider>
  </BrowserRouter>
  
), document.getElementById('root'))