import { downloadConstants } from '../_constants'

export function downloadHasErrored(state = false, action) {
  switch (action.type) {
    case downloadConstants.DOWNLOAD_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function downloadIsLoading(state = false, action) {
  switch (action.type) {
    case downloadConstants.DOWNLOAD_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function downloads(state = [], action) {
  switch (action.type) {
    case downloadConstants.DOWNLOAD_FETCH_DATA_SUCCESS:
      return action.downloads;
    default:
      return state;
  }
}
