// import { userService } from '../_services';
const user = JSON.parse(localStorage.getItem('user'));
export const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  // 'Authorization': `celumApiKey 8d6lepmgrcjku9l7i1b8m7po9f`
}
export const endpoint = `https://dam.celum.net/cora`;
export const devendpoint = `https://api.jde-mediabox.com`;

let devheaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',  
}
if(user)
  devheaders.Authorization = `Bearer ${user.token}`;
export {devheaders};

export * from './cart.api';
export * from './order.api';
export * from './asset.api';