import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { withLocalize } from "react-localize-redux";
import { withCookies } from 'react-cookie';
// import { renderToStaticMarkup } from "react-dom/server";

// MUI
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Components
import Logo from '../../img/logo.png';
import { PasswordLostDialog } from '../../components/PasswordLostDialog';
import { Footer } from '../../components/Footer';

// Redux
import { userActions } from '../../_actions';
import { uiSetLang } from '../../_actions/ui.actions';

const styles = theme => ({
  ...theme,
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    // minHeight: 'calc(100vh + 75px)',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'url(https://jde-mediabox.com/img)',
    position: 'relative'
  },
  card: {
    ...theme.mixins.gutters(),
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing.unit * 2,
    float: 'none',
    minWidth: 300,
    maxWidth: 360,
    
    [theme.breakpoints.between('xs','sm')]: {
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '6em',
    },
    zIndex: 2,
  },
  formControl: {
    // margin: theme.spacing.unit,
    width: '100%'
  },
  button: {
    // marginTop: 20
  },
  regButton: {
    marginBottom: theme.spacing.unit*2
  },
  media: {
    height: 140,
    width: 140,
    margin: '0 auto',
    marginTop: 10
  },
  text: {
    textAlign: 'center',
    paddingBottom: 10,
    paddingTop: 10,
  },
  pwLostbutton: {
    padding: 0,
    textTransform: 'none'
  }, 
  pwLostaction: {
    float: 'right',
    padding: 0,
    paddingBottom: theme.spacing.unit * 2
  },
  actions: {
    display: 'block',
    padding: 0,
    paddingBottom: theme.spacing.unit * 2
  },
  langSwitcher: {
    // float: 'right',
    // marginRight: '-1.5em',
    // marginTop: 4,
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  }
});

// const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

const isEmail = (email = null) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

const LanguageSwitcher = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: 0,//theme.spacing.unit * 2,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    // marginRight: '-1.7em',
  },
  switcher: {
    position: 'absolute',
    right: -24,
    top: theme.spacing.unit,    
  }
}))(props => {
  const { classes, handleLangChange, lang, translate, login } = props;
  return (
    <div className={classes.root}>
      <FormControl className={login ? classes.switcher : classes.closeButton}>
        <InputLabel htmlFor="age-simple">{translate("languages.title")}</InputLabel>
        <Select
          value={lang}
          onChange={handleLangChange}
          inputProps={{
            name: 'lang',
            id: 'lang-simple',
          }}
        >
          <MenuItem value="de">{translate("languages.de")}</MenuItem>
          <MenuItem value="en">{translate("languages.en")}</MenuItem>
          <MenuItem value="fr">{translate("languages.fr")}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
});

class LoginView extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    const { cookies } = props;
 
    this.state = {
      title: 'Login',
      username: '',
      password: '',
      error: false,
      isLoading: false,
      openDialog: false,
      showPopup: cookies.get("showPopup") || true
    }
    // this.props.initialize({
    //   languages: ["de", "en", "fr"],
    //   options: { onMissingTranslation, renderToStaticMarkup }
    // });

  }

  componentDidMount(){
    // reset login status
    this.props.logout();

    document.title = `JDE mediaBOX | Login`;
    // if(window.location.href.indexOf(".com") > 0 && !localStorage.getItem("lang")){
    //   this.props.setActiveLanguage("en");
    //   this.props.uiSetLang("en");
    // }
    if(!localStorage.getItem("lang")){
      this.props.setActiveLanguage("de");
      this.props.uiSetLang("de");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.state.error) {
      this.setState({ error: !!(nextProps.error), isLoading: !!(nextProps.loggingIn) });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      error: false
    });
  };

  handleClose(){
    this.setState({ openDialog: false });
  }

  handlePopupClose = () => {
    const { cookies } = this.props;
    cookies.set("showPopup", false, {path: "/"});
    this.setState({ showPopup: false });
  };

  handleSubmit(event) {
    const { username, password } = this.state;
    // const { dispatch } = this.props;

    event.preventDefault();
    
    this.setState({
      isLoading: true,
      error: false
    })
    if(username && password) {
      this.props.login(username, password);
    }

    // fetch('https://api.jde-mediabox.com/user/login/', {
    //   method: 'POST',
    //   headers: {
    //     "Content-Type": "application/json; charset=utf-8"
    //   },
    //   body: JSON.stringify(credentials)
    // })
    // .then(res => {
    //   return res.json()
    // })
    // .then(result => {
    //   if(result.success)
    //     window.location="/"
    //   else
    //     this.setState({ isLoading: false, error: true })
    // })


  }

  handleOpenDialog() {
    this.setState({ openDialog: true });
  }

  handleLangChange = (e) => {
    const { value } = e.target;
    this.props.setActiveLanguage(value);
    this.props.uiSetLang(value);
  };

  render() {   
    const { classes, translate, lang } = this.props;
    const { error, username, password, isLoading } = this.state;
    const loginError = this.props.error;

    return(
      <div className={classes.root}>
        <Card className={classes.card} elevation={1}>
          <LanguageSwitcher handleLangChange={this.handleLangChange.bind(this)} lang={lang} translate={translate} login={true}/>
          <CardMedia
            className={classes.media}
            image={Logo}
            title="JDE mediaBOX"
          />
          <Typography className={classes.text} variant="h5" component="h3">
            {translate("login.subtitle")}
          </Typography>
          <form onSubmit={this.handleSubmit}>
            <FormControl className={classes.formControl} error={error}>
              <InputLabel htmlFor="username">{translate("login.email")}</InputLabel>
              <Input
                name="username"
                value={username}
                onChange={this.handleChange.bind(this)}
                disabled={isLoading}
                autoComplete="email"
                type="email"
              />
            </FormControl>

            <FormControl className={classes.formControl} error={error}>
              <InputLabel htmlFor="password">{translate("login.password")}</InputLabel>
              <Input
                name="password"
                value={password}
                type="password"
                onChange={this.handleChange.bind(this)}
                disabled={isLoading}
                autoComplete="current-password"
              />
              { error && 
              <FormHelperText id="password-error-text">{translate(loginError)}</FormHelperText>
              }
            </FormControl>

            <CardActions className={classes.pwLostaction}>
              <Button
                className={classes.pwLostbutton}
                size="small"
                onClick={this.handleOpenDialog}
              >
                {translate("login.passwordlost")}
              </Button>
            </CardActions>

            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={(isLoading || (this.state.username.length < 1 || this.state.password < 1) || !isEmail(this.state.username)) }
                className={classes.button}
                fullWidth
              >
                {isLoading && (
                  <CircularProgress size={25} thickness={2} />
                )}
                {translate("login.login")}
              </Button>
            </CardActions>
            <Typography className={classes.text} style={{ paddingTop: 0}} variant="body1" component="h3">
              {translate("login.call2signup")}
            </Typography>
              <Button
                variant="contained"
                color="secondary"
                className={classes.regButton}
                component={Link}
                to="/signup"
                fullWidth
              >
                {translate("login.signupBtn")}
              </Button>
          </form>
        </Card>
        <PasswordLostDialog open={this.state.openDialog} handleClose={this.handleClose.bind(this)} />
        <Footer layout="static" active="login" />
        <Dialog
          open={JSON.parse(this.state.showPopup)}
          onClose={this.handlePopupClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          maxWidth="md"
        > 
          <LanguageSwitcher handleLangChange={this.handleLangChange.bind(this)} lang={lang} translate={translate} login={false}/>
          <DialogTitle style={{}} id="alert-dialog-title">{translate("login.popup.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText gutterBottom>{translate("login.popup.p1")}</DialogContentText>
            <DialogContentText gutterBottom>{translate("login.popup.p2")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handlePopupClose} color="primary" autoFocus>
              {translate("login.popup.button")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, error } = state.authentication;
  // console.log(state);
  return {
    loggingIn,
    error,
    lang: state.lang,
    localize: state.localize,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetLang: (lang) => dispatch(uiSetLang(lang)),
    logout: () => dispatch(userActions.logout()),
    login: (username,password) => dispatch(userActions.login(username,password)),
  };
};

// const Login = withStyles(styles)(LoginView);
// export { Login }

const connectedLoginPage =
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withLocalize,
    withCookies
  )(LoginView)

// const connectedLoginPage = connect(mapStateToProps)(LoginView);
export { connectedLoginPage as Login }; 