import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import {TextBlock, RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';


const styles = theme => ({
  root: {
    flexGrow: 1,
    // margin: 10,
    // padding: 20,
    // width: '95%'
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      margin: -10,
    },
  },
  image: {
    maxWidth: 320,
    maxHeight: 320,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    // minWidth: 320,
    // minHeight: 320,
  },
  paper: {
    padding: 20,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  }
});

var Placeholder = (props) => {
  const { classes } = props;
  
  return (
    <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Typography variant="h5" gutterBottom><TextBlock rows={1}/></Typography>
              <Divider className={classes.divider}/>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6} sm={12}>
                  <RectShape style={{width: 150, height: 300}}/>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextBlock rows={5}/>
                  <TextBlock rows={2}/>
                </Grid>
              </Grid>

            </Paper>
          </Grid>
        </Grid>
    </div>
  );
}

Placeholder.propTypes = {
  classes: PropTypes.object.isRequired,
};
Placeholder = withStyles(styles)(Placeholder)
export { Placeholder }