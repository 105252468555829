import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { compose } from 'redux';

// MATERIAL UI 
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
// import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

// COMPONENTS 
import { JDEAppBar, drawerWidth } from '../components/JDEAppBar'
import Menu from '../components/Menu/Menu';
import { Footer } from '../components/Footer';

// REDUX
import { connect } from "react-redux";
import { nodesFetchData, uiGetCountry, uiSetCountry } from '../_actions';

const styles = theme => ({
  root: {
    height: `calc(100% + 50px)`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 110,
    },
    // flexGrow: 1,
    // height: 440,
    // height: '100% !important',

    // position: 'absolute',
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // zIndex: 1,
    // overflow: 'hidden',
    display: 'flex',
    // width: '100%',
    webkitBoxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.2)',
    mozBoxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.2)',
    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.2)',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    overflow: 'hidden',
    borderRight: 'none !important',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minHeight: '100vh',
    zIndex: 5,
  },
  userBar: {
    // backgroundColor: theme.palette.secondary.main,
    height: 63,
  },
  avatar: {
    margin: 10,
    backgroundColor: theme.palette.primary.main,
  },
  userName: {
    paddingTop: 22
  },
  border: {
    borderRight: '1px solid rgba(0,0,0,0.12)',
    backgroundColor: 'white',
    zIndex: 5,
  },
  pofileLink: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
});

const trunc = (str,n) => {
  if(!str || str === undefined) return null;
  return (str.length > n) ? str.substr(0, n-1) + '…' : str;
}

const UserBar = ({classes, user, children}) => {
  let username = (user && user.userName) ? user.userName.split(' ') : '';
  let first = username ? username[0].charAt(0).toUpperCase() : '';
  let last = username ? username[username.length-1].charAt(0).toUpperCase() : '';
  return (
    <Grid container justify="flex-start" alignItems="flex-start" className={classes.userBar}>
      <Avatar className={classes.avatar}>{`${first}${last}`}</Avatar>
      <Link to="/profile"  className={classes.pofileLink}>
        <div className={classes.userName}>{trunc(user.userName, 23)}</div>
      </Link>
    </Grid>
  )
}

class Dashboard extends Component {
  // constructor(props){
  //   super(props);
  // }

  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  componentDidMount(){
    this.props.uiGetCountry();
    const values = queryString.parse(this.props.location.search);
    let country = "";

    // console.log(values);

    if(values.c){
      const lt = {
        "de": "germany",
        "at": "austria",
        "ch": "switzerland"
      }
      country = lt[values.c];
      // console.log('country',lt[values.c]);
    }
    else
      country = localStorage.getItem("country") ? localStorage.getItem("country") : this.props.country;
    
    let id = {
      "germany": 1,
      "austria": 123,
      "switzerland": 658
    };

    this.props.nodesFetchData(id[country]);
    this.props.uiSetCountry(country);

  }

  render(){
    const { classes, theme, children } = this.props;
    return (
      <div className={classes.root}>
        <JDEAppBar handleDrawerToggle={this.handleDrawerToggle}/>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Menu/>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css" className={classes.border}>
          <UserBar className={classes.userBar} {...this.props} />
            
          <Divider/>
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Menu/>
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
        <Footer layout="dashboard"/>
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { authentication } = state;
  const { user } = authentication;
  
  return {
    user,
    country: state.country,
    authentication: state.authentication,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nodesFetchData: (id) => dispatch(nodesFetchData(id)),
    uiGetCountry: () => dispatch(uiGetCountry()),
    uiSetCountry: (country) => dispatch(uiSetCountry(country))
  };
};

const DashboardLayout = compose(
  connect(mapStateToProps,mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
  withRouter
)(Dashboard)

export default DashboardLayout;