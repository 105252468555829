import "isomorphic-fetch";
// import { devheaders } from '../api';
import { assetsConstants } from '../_constants';
import { history } from '../_helpers';
const API = process.env.REACT_APP_API_ENDPOINT; //'https://api.jde-mediabox.com'
// const lang = 'de';
// const headers = {
//   'Accept': 'application/json',
//   'Content-Type': 'application/json',
//   'Authorization': `celumApiKey ${APIKEY}`
// }


// export const NODES_HAS_ERRORED = 'NODES_HAS_ERRORED';
// export const NODES_IS_LOADING = 'NODES_IS_LOADING';
// export const NODES_FETCH_DATA_SUCCESS = 'NODES_FETCH_DATA_SUCCESS';


function assetsHasErrored(bool) {
  return {
    type: assetsConstants.ASSETS_HAS_ERRORED,
    hasErrored: bool
  };
}

function assetsIsLoading(bool) {
  return {
    type: assetsConstants.ASSETS_IS_LOADING,
    isLoading: bool
  };
}

function assetsFetchDataSuccess(assets) {
  return {
    type: assetsConstants.ASSETS_FETCH_DATA_SUCCESS,
    assets
  };
}

export function assetsFetchData(id) {
  // console.log('nodesFetchData')
  return (dispatch) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',  
      'Authorization': `Bearer ${user.token}`
    }
    dispatch(assetsIsLoading(true));
    fetch(API+`/assets/${id}`,{
      method: 'GET',
      headers
    })
      .then((response) => {
        if (!response.ok) {
          if(response.status === 403)
            history.push('/login');
          throw Error(response.statusText);
        }
        dispatch(assetsIsLoading(false));
        return response;
      })
      .then((response) => response.json())
      .then((assets) => dispatch(assetsFetchDataSuccess(assets)))
      .catch(() => dispatch(assetsHasErrored(true)));
  };
}