export const uiConstants = {
  UI_GET_VIEW: 'UI_GET_VIEW',
  UI_SET_VIEW: 'UI_SET_VIEW',
  UI_VIEW_IS_LOADING: 'UI_VIEW_IS_LOADING',
  UI_GET_CONTAINER: 'UI_GET_CONTAINER',
  UI_SET_CONTAINER: 'UI_SET_CONTAINER',
  UI_SHOW_SEARCH: 'UI_SHOW_SEARCH',
  UI_SEARCH_ACTIVE: 'UI_SEARCH_ACTIVE',
  UI_FILTER_SEARCH: 'UI_FILTER_SEARCH',
  UI_CART_QUICK_VIEW_OPEN: 'UI_CART_QUICK_VIEW_OPEN',
  UI_SET_LANG: 'UI_SET_LANG',
  UI_GET_LANG: 'UI_GET_LANG',
  UI_SET_COUNTRY: 'UI_SET_COUNTRY',
  UI_GET_COUNTRY: 'UI_GET_COUNTRY',
};