import "isomorphic-fetch";
// import axios from 'axios';
import {
  // endpoint,
  // devheaders,
  devendpoint
} from '../api';
import { downloadConstants } from '../_constants';
import { history } from '../_helpers';

export function downloadHasErrored(bool) {
  return {
    type: downloadConstants.DOWNLOAD_HAS_ERRORED,
    hasErrored: bool
  };
}

export function downloadIsLoading(bool) {
  return {
    type: downloadConstants.DOWNLOAD_IS_LOADING,
    isLoading: bool
  };
}

export function downloadFetchDataSuccess(downloads) {
  return {
    type: downloadConstants.DOWNLOAD_FETCH_DATA_SUCCESS,
    downloads
  };
}

export function downloadGetAll() {
  return (dispatch) => {
    dispatch(downloadIsLoading(true));
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',  
      'Authorization': `Bearer ${user.token}`
    }
    fetch(`${devendpoint}/downloads/`,{
      method: 'GET',
      headers
    })
    .then((response) => {
      if (!response.ok) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(downloadIsLoading(false));
      return response;
    })
    .then((response) => response.json())
    .then((downloads) => dispatch(downloadFetchDataSuccess(downloads)))
    .catch(() => dispatch(downloadHasErrored(true)));
  };
}