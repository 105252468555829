import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DownloadIcon from '@material-ui/icons/GetApp';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Link } from 'react-router-dom'
import { withLocalize } from "react-localize-redux";

import { connect } from 'react-redux';

import Moment from 'react-moment';
import 'moment-timezone';

import { AddButton } from '../AddButton';
import { RemoveButton } from '../RemoveButton';
import { AssetName } from '../AssetName';

// const styles = theme => ({
//   table: {
//     minWidth: 700,
//   },
//   downloadButton: {
//     marginRight: theme.spacing.unit,
//     fontSize: 16,
//   },
//   link: {
//     textDecoration: 'none',
//     color: 'inherit'
//   },

// });


const List = withStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  downloadButton: {
    marginRight: theme.spacing.unit,
    fontSize: 16,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  isInCart: {
    backgroundColor: 'rgba(0,0,0,0.05)',
  }
}))((props) => {

// let List = props => {
  const { assets, cart, classes, translate, lang, searchActive } = props;


  function compare(a,b) {
    if(searchActive){
      if (a.score < b.score)
         return 1;
      if (a.score > b.score)
        return -1;
      return 0;
    }
    else {
      if (a.name.toUpperCase() < b.name.toUpperCase())
         return -1;
      if (a.name.toUpperCase() > b.name.toUpperCase())
        return 1;
      return 0;
    }
  }


  const isInCart = (cart, asset) => {
    for(let item of cart.items){
      if(item.id === asset.id)
        return true
    }
    return false;
  }

  return (
    <Grid item xs={12}>
      <Paper>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{translate("node.name")}</TableCell>
              { searchActive && <TableCell>{translate("search.relevance")}</TableCell>}
              <TableCell>{translate("node.uploadDate")}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.sort(compare).map(asset => {
              return (
                <TableRow key={asset.id} className={isInCart(cart,asset) ? classes.isInCart : null } >
                  <TableCell>{isInCart(cart, asset) ? <RemoveButton assetId={asset.id} />:<AddButton assetId={asset.id}/>}</TableCell>
                  <TableCell component="th" scope="row">
                    <Link className={classes.link} to={`/asset/${asset.id}`}><AssetName>{asset.name}</AssetName></Link>
                  </TableCell>
                  { searchActive &&
                  <TableCell>
                    <LinearProgress color="primary" variant="determinate" value={parseFloat(asset.score)*100} />
                  </TableCell>
                  }
                  <TableCell><Moment format="DD.MM.YYYY HH:ss" date={asset.creationDate} /></TableCell>
                  <TableCell>
                    <Button size="small" className={classes.downloadButton} aria-label={asset[`mediaTypeName_${lang}`]} href={asset.originalTicket}>
                      <DownloadIcon/>
                    </Button>
                    {asset[`mediaTypeName_${lang}`]}
                  </TableCell>
                  <TableCell>
                  { asset.relatedAsset && 
                    <div>
                      <Button size="small" className={classes.downloadButton} aria-label={asset.relatedAsset[`mediaTypeName_${lang}`]} href={asset.relatedAsset.originalTicket}>
                        <DownloadIcon/>
                      </Button>
                      {asset.relatedAsset[`mediaTypeName_${lang}`]}
                    </div>
                  }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </Grid>
  )
})

// List = withStyles(List)(styles)


const mapStateToProps = state => {
  return {
    cart: state.cart,
    lang: state.lang,
    searchActive: state.searchActive,
  }
}

const ListView = withLocalize(connect(mapStateToProps)(List));

export { ListView }