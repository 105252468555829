import { searchConstants } from '../_constants';

export function searchHasErrored(state = false, action) {
  switch (action.type) {
    case searchConstants.SEARCH_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function searchIsLoading(state = false, action) {
  switch (action.type) {
    case searchConstants.SEARCH_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function search(state = { results: [] }, action) {
  switch (action.type) {
    case searchConstants.SEARCH_FETCH_DATA_SUCCESS:
      return action.search;
    default:
      return state;
  }
}

export function searchFilter(state = [] , action) {
  switch (action.type) {
    case searchConstants.SEARCH_SET_FILTER:
      return action.searchFilter;
    default:
      return state;
  }
}

const query = (window.location.pathname.indexOf('search') > 0) ? window.location.pathname.replace(/\/search\//,'').replace(/\+/,' ') : "";
export function searchString(state = query, action){
  if(action.type === searchConstants.SEARCH_SET_SEARCHSTRING)
    return action.searchString
  else
    return state;
}