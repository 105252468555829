import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
// import { routerMiddleware } from 'react-router-redux';

import rootReducer from '../_reducers';

const loggerMiddleware = process.env.NODE_ENV === "development" ? createLogger() : null;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  if(process.env.NODE_ENV === 'production')
    return createStore(
      rootReducer,
      initialState,
      applyMiddleware(
        thunk
      )
    );
  else {
    // return createStore(
    return createStore(
      rootReducer,
      initialState,
      composeEnhancers(
        applyMiddleware(
          thunk,
          loggerMiddleware,
        )
      )
    );
  }
}