import React from 'react';
import prettysize from 'prettysize';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from "react-localize-redux";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/GetApp';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {isMobile} from "react-device-detect";
import Moment from 'react-moment';
import 'moment-timezone';

import { AssetName } from '../AssetName';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  secondaryHeading2: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing.unit * 8,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  nestedText: {
    fontSize: `${theme.typography.pxToRem(15)} !important`,
    color: `${theme.palette.text.secondary} !important`,
  },
  options: {
    padding: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: null,
    open: false,
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleDownloadClick = (filename) => {
    window.open(`https://api.jde-mediabox.com/download/${filename}`);
  }

  handleClick = (id) => {
    const prevState = this.state.open;
    this.setState({ open: id === prevState ? false : id });
  }

  render() {
    const { classes, children, translate } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        {children.map(child => (
        <ExpansionPanel expanded={expanded === child._id} onChange={this.handleChange(child._id)} key={child._id} >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
                {translate("downloads.created")}: <Moment format="DD.MM.YYYY HH:mm" date={child.created} />
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {child.items.length} {translate("downloads.article")} ({prettysize(child.filesize)})
            </Typography>
            <Typography className={classes.secondaryHeading2}>
              {translate("downloads.valid")}: <Moment format="DD.MM.YYYY HH:mm" date={child.created} add={{ days: 14 }} />
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            
            <List
              component="nav"
              subheader={<ListSubheader component="div">{translate("downloads.content")}:</ListSubheader>}
              className={classes.root}
            >
            {child.items.map(item => (
              <div key={item.id}>
                <ListItem button onClick={ () => this.handleClick(item.id) }>
                  <ListItemText inset primary={<AssetName>{item.name}</AssetName>} />
                  {(this.state.open === item.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
                <Collapse in={this.state.open === item.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                      { item.files.map(file => (
                        <ListItemText className={classes.nestedText} inset primary={file.filename} key={file._id} />
                      ))}
                    </ListItem>
                  </List>
                  <Divider light />
                </Collapse>
              </div>
            ))}
            </List>
          </ExpansionPanelDetails>
          <Divider/>
          <div className={classes.options}>
            <Button variant="contained" color="primary" className={classes.button} onClick={ () => this.handleDownloadClick(child.filename) } disabled={isMobile}>
              Download
              <DownloadIcon className={classes.rightIcon} />
            </Button>
            {`${child.filename} (${prettysize(child.filesize)})`}
          </div>
        </ExpansionPanel>
        ))}
      </div>
    );
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

const DownloadExpansionPanel = withLocalize(withStyles(styles)(ControlledExpansionPanels));
export { DownloadExpansionPanel }