import "isomorphic-fetch";
import {
  // endpoint,
  // devheaders,
  assetApi,
  devendpoint
} from '../api';
import { assetConstants } from '../_constants';
import { history } from '../_helpers';

// export const assetActions = {
//     login,
//     logout,
//     getAll
// };

// export const ASSET_HAS_ERRORED = 'ASSET_HAS_ERRORED';
export function assetHasErrored(bool) {
  return {
    type: assetConstants.ASSET_HAS_ERRORED,
    hasErrored: bool
  };
}

// export const ASSET_IS_LOADING = 'ASSET_IS_LOADING';
export function assetIsLoading(bool) {
  return {
    type: assetConstants.ASSET_IS_LOADING,
    isLoading: bool
  };
}

// export const ASSET_FETCH_DATA_SUCCESS = 'ASSET_FETCH_DATA_SUCCESS';
export function assetFetchDataSuccess(asset) {
  return {
    type: assetConstants.ASSET_FETCH_DATA_SUCCESS,
    asset
  };
}

export function assetFetchData(id) {
  return (dispatch) => {
    dispatch(assetIsLoading(true));
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',  
      'Authorization': `Bearer ${user.token}`
    }
    fetch(`${devendpoint}/asset/${id}`,{
      method: 'GET',
      headers
    })
    .then((response) => {
      if (!response.ok) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(assetIsLoading(false));
      return response;
    })
    .then((response) => response.json())
    .then((asset) => dispatch(assetFetchDataSuccess(asset.data)))
    .catch(() => dispatch(assetHasErrored(true)));
  };
}

export function assetRequestRemove(assetId) {
  return dispatch => {
    dispatch(request(true));
    assetApi.requestRemove(assetId)
    .then(
      response => {
        dispatch(success(response.data));
        dispatch(request(false));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(isLoading) { return { type: assetConstants.ASSET_IS_LOADING, isLoading } }
  function success(asset) {
    return {
      type: assetConstants.ASSET_FETCH_DATA_SUCCESS,
      asset: {...asset.data, removalRequested: true},
    }
  }
  function failure(error) { return { type: assetConstants.ASSET_HAS_ERRORED, error } }
}