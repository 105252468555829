import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import FileDownloadIcon from '@material-ui/icons/GetApp';

import Draggable from 'react-draggable';
import { withLocalize } from "react-localize-redux";

import fileDownload from 'js-file-download';
import Moment from 'react-moment';
import 'moment-timezone';

import './FileDetailDialog.css';

import { devendpoint } from '../../../api';


function PaperComponent(props) {
  return (
    <Draggable>
      <Paper {...props} />
    </Draggable>
  );
}


class ResponsiveDialog extends React.Component {
  constructor(props){
    super(props);
    this.keyHandling = this.keyHandling.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.state = {
      showClearMessage: false
    }
  }

  componentDidMount(){
    window.addEventListener("keyup", this.keyHandling);
  }

  componentWillUnmount(){
    window.removeEventListener("keyup", this.keyHandling);
  }

  keyHandling(e){
    if(e.code === "ArrowRight")
      this.props.next();
    else if(e.code === "ArrowLeft")
      this.props.prev();
  }

  handleDeleteClick(bool) {
    const file = this.props.files[this.props.index];
    if(bool) {
      // console.log(file._id);
      this.props.remove(file._id);
    }
    this.setState({ showClearMessage: !this.state.showClearMessage });
  }

  handleDownloadClick() {
    const file = this.props.files[this.props.index];
    axios({
      url: `${devendpoint}/uploads/${file.filename}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      fileDownload(response.data, file.originalname);
    });
  }

  render() {
    const { fullScreen, files, open, handleClose, next, prev, index, translate, isSuperUser } = this.props;
    const { showClearMessage } = this.state;

    if(index >= 0){
      const file = files[index];
      return (
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            scroll="body"
            PaperComponent={PaperComponent}
          >
            <DialogTitle id="responsive-dialog-title">{ file ? file.originalname : " " }</DialogTitle>
            <DialogContent>
             { file &&
                <div>
                  <img
                    src={`https://api.jde-mediabox.com/uploads/preview/${file.filename}`}
                    alt={file.filename}
                    style={{ display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "100%"}}
                  />
                  <p><Moment format="DD.MM.YYYY HH:mm" date={file.createdAt}/></p>
                </div>
             }
            </DialogContent>
            <DialogActions>
              <span>{index+1} / {files.length}</span>
              
              <div className="deleteBtn">
                <Fab size="small" disabled={!isSuperUser} onClick={() => this.handleDeleteClick(false)} color="primary" aria-label="Entfernen">
                  <DeleteIcon />
                </Fab>

                {showClearMessage && 
                <span>
                  <span className="clearMessageItem">{translate("cart.clear.sure")}</span>
                  <Button className="clearMessageItem" size="small" variant="contained" color="primary" onClick={ () => this.handleDeleteClick(true) }>
                    {translate("cart.clear.yes")}
                  </Button>
                  <Button className="clearMessageItem" size="small" variant="contained" color="primary" onClick={ () => this.handleDeleteClick(false) }>
                    {translate("cart.clear.no")}
                  </Button>
                </span>
                }
                <Fab size="small" color="primary" onClick={this.handleDownloadClick} className="downloadBtn">
                  <FileDownloadIcon/>
                </Fab>
              </div>

              <Fab size="small" onClick={prev} color="primary">
                <NavigateBeforeIcon/>
              </Fab>

              <Fab size="small" onClick={next} color="primary" autoFocus>
                <NavigateNextIcon/>
              </Fab>

              <Fab size="small" onClick={handleClose} color="primary" className="closeBtn">
                <CloseIcon/>
              </Fab>

            </DialogActions>
          </Dialog>
        </div>
      );
    }
    return null;
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withLocalize(withMobileDialog()(ResponsiveDialog));