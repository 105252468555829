import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#422110',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: '#FCF4DE',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#513D38',
    },
    error: red,
    // error: will use the default color
  },
});

export default theme;