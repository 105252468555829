import "isomorphic-fetch";
import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
// import { Link } from 'react-router-dom'
import { withLocalize } from "react-localize-redux";

const isEmail = (email = null) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

class FormDialog extends React.Component {
  componentDidMount(){
    // console.log(this.props.localize.languages.filter(l => l.active));
    // this.props.setActiveLanguage();
  }

  state = {
    open: this.props.open,
    error: false,
    emailError: false,
    emailErrorText: '',
    email: '',
    isLoading: false,
    ok: false,
  };

  handleChange = e => {
    this.setState({
      error: !isEmail(e.target.value),
      [e.target.name]: e.target.value,
      emailError: false
    })
    // console.log(this.props.lang)
  }

  handleSubmit = () => {
    if(!this.state.error){
      const { email } = this.state;

      this.setState({ isLoading: true });

      fetch('https://api.jde-mediabox.com/user/forgot_password/', {
        method: 'POST',
        body: JSON.stringify({ username: email, lang: this.props.lang }),
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      })
      .then(response => response.json())
      .then(response => {
        this.setState({ ok: response.success, error: !response.success, emailErrorText: response.message ? this.props.translate(response.message) : '' || '', emailError: !response.success });
        this.setState({ isLoading: false });
        // console.log(response)
      });
    }
    else
      console.log('error')
  }

  handleClose = () => {
    this.props.handleClose();
    this.setState({ email: '', error: false, ok: false, emailErrorText: '', emailError: false });
  };

  // componentWillReceiveProps() {
  //   this.setState({ open: this.props.open })
  // }

  render() {
    const { ok, isLoading, error, email, emailError, emailErrorText } = this.state;
    const { translate } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{translate("passwordlost.title")}</DialogTitle>
          
          <DialogContent>
          { isLoading ? <CircularProgress style={{ margin: '0 auto', display: 'block' }} size={25} thickness={2} /> : !ok ?
            <div>
              <DialogContentText>
                {translate("passwordlost.text")}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                name="email"
                label={translate("passwordlost.email")}
                type="email"
                onChange={this.handleChange.bind(this)}
                fullWidth
                error={error}
                value={email}
              />
              { emailError && <FormHelperText error={emailError}>{emailErrorText}</FormHelperText> }
            </div>
            : <DialogContentText>
                {translate("passwordlost.linkSentMessage")}
              </DialogContentText>
          }
          </DialogContent>
          
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              { ok ? translate("passwordlost.closeBtn") : translate("passwordlost.cancelBtn")}
            </Button>
            { !ok &&
            <Button
              onClick={this.handleSubmit}
              color="primary"
              disabled={(isLoading || !email || error)}
            >
              {translate("passwordlost.sendBtn")}
            </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang,
    localize: state.localize
  }
}

const PasswordLostDialog = connect(mapStateToProps)(withLocalize(FormDialog));
export { PasswordLostDialog }
