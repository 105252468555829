import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import Image from "react-graceful-image";

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'block',
  minWidth: 0,
  width: '100%',
  overflow: 'hidden',
  cursor: 'pointer'
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  margin: '0 auto',
  // objectFit: 'contain'
};

class OrderFiles extends Component {
  render(){
    const { order } = this.props;

    // const images = order.files ? order.files.filter(f => f.mimetype.indexOf("image/") >= 0) : [];
    // const otherFiles = order.files ? order.files.filter(f => f.mimetype.indexOf("image/") === -1) : []

    return(
      <div>
        {order.files ?
          <div>
            <p>
              <Translate
                id="order.edit.tabs.files.title"
                data={{
                  filelength: order.files.length
                }}
              />

            </p>
            {order.files.map((file, index) => (
              <div style={thumb} key={file.filename}>
                <div style={thumbInner} onClick={() => this.props.openDialog(index)}>
                  <Image
                    src={`https://api.jde-mediabox.com/uploads/thumb/${file.filename}`}
                    width="150"
                    height="150"
                    style={img}
                    alt={file.filename}
                    retry={{ count: 15, delay: 1, accumulate: "add" }}
                  />
                </div>
              </div>
            ))}

            {/*otherFiles.map((file, index) => (
              <li key={index}>{file.originalname}</li>
            ))*/}
           </div> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.order.order,
  }
}

OrderFiles = connect(mapStateToProps)(OrderFiles);

export { OrderFiles }