import "isomorphic-fetch";
import { downloadGetAll } from './download.actions';
// import axios from 'axios';
import {
  // endpoint,
  // devheaders,
  devendpoint
} from '../api';
import { cartConstants } from '../_constants';
import { cartApi } from '../api';
import { history } from '../_helpers';
/*
CART_FETCH_DATA_SUCCESS
CART_GET_ONE_SUCCESS
CART_GET_ALL_SUCCESS
CART_ADD_ONE_SUCCESS
CART_DEL_ONE_SUCCESS
CART_CLEAR_SUCCESS
CART_SEND_SUCCESS
CART_DOWNLOAD_SUCCESS
*/

function cartHasErrored(bool) {
  return {
    type: cartConstants.CART_HAS_ERRORED,
    hasErrored: bool
  };
}

function cartIsLoading(bool) {
  return {
    type: cartConstants.CART_IS_LOADING,
    isLoading: bool
  };
}

function cartDownloadIsLoading(bool) {
  return {
    type: cartConstants.CART_DOWNLOAD_IS_LOADING,
    isLoading: bool
  };
}

function cartDownloadListIsLoading(bool) {
  return {
    type: cartConstants.CART_DOWNLOAD_LIST_IS_LOADING,
    isLoading: bool
  };
}

function cartFetchDataSuccess(cart) {
  return {
    type: cartConstants.CART_FETCH_DATA_SUCCESS,
    cart
  };
}

// function cartGetOneSuccess(cart) {
//   return {
//     type: cartConstants.CART_GET_ONE_SUCCESS,
//     cart
//   };
// }

function cartSendSuccess(bool) {
  return {
    type: cartConstants.CART_SEND_SUCCESS,
    cartSent: bool
  };
}

function cartDownloadListSuccess(bool) {
  return {
    type: cartConstants.CART_DOWNLOAD_LIST_SUCCESS,
    cartSent: bool
  };
}

// function cartGetAllSuccess(carts) {
//   return {
//     type: cartConstants.CART_GET_ALL_SUCCESS,
//     carts
//   };
// }

function cartAddOneSuccess(cart) {
  return {
    type: cartConstants.CART_ADD_ONE_SUCCESS,
    cart
  };
}

function cartAddManySuccess(cart) {
  return {
    type: cartConstants.CART_ADD_MANY_SUCCESS,
    cart
  };
}

function cartDelOneSuccess(cart) {
  return {
    type: cartConstants.CART_DEL_ONE_SUCCESS,
    cart
  };
}

function cartDelManySuccess(cart) {
  return {
    type: cartConstants.CART_DEL_MANY_SUCCESS,
    cart
  };
}


function cartClearSuccess(cart) {
  return {
    type: cartConstants.CART_CLEAR_SUCCESS,
    cart: { items: [] }
  };

}

function cartDownloadSuccess(bool) {
  return {
    type: cartConstants.CART_DOWNLOAD_SUCCESS,
    downloadSuccess: bool
  };
}

function cartAddAllFormatSuccess(cart) {
  return {
    type: cartConstants.CART_ADD_ALL_FORMAT,
    cart
  }
}

function cartRemoveAllFormatSuccess(cart) {
  return {
    type: cartConstants.CART_REMOVE_ALL_FORMAT,
    cart
  }
}


function cartUpdateCartCheckboxes(cartCheckboxState) {
  return {
    type: cartConstants.CART_UPDATE_CHECKBOXES,
    cartCheckboxState
  }
}


function updateCheckboxes(cartItems) {
  let checkboxes = [];
  for(let item of cartItems){
    const formats = item.files.map(i => i.mediaType);
    for(let format of formats){
      checkboxes[`${item.id}.${format}`] = true;
    }
  }
  return checkboxes;
}

export function cartGetOne(cartId) {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.getOne()
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartUpdateCartCheckboxes(updateCheckboxes(response.data.cartItems)));
      dispatch(cartIsLoading(false));
      return response;
    })
    .then(cart => dispatch(cartFetchDataSuccess(cart.data)))
    .catch(() => dispatch(cartHasErrored(true)));
  };  
}

export function cartGetAll() {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.getAll()
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      return response;
    })
    .then(cart => dispatch(cartFetchDataSuccess(cart.data)))
    .catch(() => dispatch(cartHasErrored(true)));
  };
}

export function cartAddOne(assetId) {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.addOne(assetId)
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      return response;
    })
    .then(cart => dispatch(cartAddOneSuccess(cart.data)))
    .catch(() => dispatch(cartHasErrored(true)));
  };
}

export function cartAddOneFormat(assetId, format) {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.addOneFormat(assetId,format)
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      dispatch(cartUpdateCartCheckboxes(updateCheckboxes(response.data.cartItems)));
      return response;
    })
    .then(cart => dispatch(cartAddOneSuccess(cart.data)))
    .catch((err) => dispatch(cartHasErrored(err)));
  };
}

export function cartAddManyFormat(assetIds, format) {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.addManyFormat(assetIds,format)
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      dispatch(cartUpdateCartCheckboxes(updateCheckboxes(response.data.cartItems)));
      return response;
    })
    .then(cart => dispatch(cartAddManySuccess(cart.data)))
    .catch((err) => dispatch(cartHasErrored(err)));
  };
}

export function cartRemoveOne(assetId) {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.delOne(assetId)
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      return response;
    })
    .then(cart => dispatch(cartDelOneSuccess(cart.data)))
    .catch(() => dispatch(cartHasErrored(true)));
  };
}

export function cartRemoveOneFormat(assetId, format) {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.delOneFormat(assetId, format)
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      // updateCheckboxes(response.data.cartItems);
      dispatch(cartUpdateCartCheckboxes(updateCheckboxes(response.data.cartItems)));
      return response;
    })
    .then(cart => dispatch(cartDelOneSuccess(cart.data)))
    .catch((err) => dispatch(cartHasErrored(err)));
  };
}

export function cartRemoveManyFormat(assetIds, format) {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.delManyFormat(assetIds, format)
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      dispatch(cartUpdateCartCheckboxes(updateCheckboxes(response.data.cartItems)));
      return response;
    })
    .then(cart => dispatch(cartDelManySuccess(cart.data)))
    .catch((err) => dispatch(cartHasErrored(err)));
  };
}


export function cartClear() {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.clear()
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      return response;
    })
    .then(cart => dispatch(cartClearSuccess()))
    .catch((err) => dispatch(cartHasErrored(err)));
  };
}

export function cartSend(form, lang) {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    cartApi.send(form, lang)
    .then(response => {
      if (response.status > 200) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      return response;
    })
    .then(response => dispatch(cartSendSuccess(true)))
    .catch((err) => dispatch(cartHasErrored(err)));
  };
}

export function cartSendReset() {
  return (dispatch) => {
    dispatch(cartSendSuccess(false));
  };
}

export function cartDownloadList() {
  return (dispatch) => {
    dispatch(cartDownloadListIsLoading(true));
    cartApi.list()
    .then(response => {
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'artikeluebersicht.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      dispatch(cartGetOne());
      dispatch(cartDownloadListIsLoading(false));
    });
    dispatch(cartDownloadListSuccess(false));
  }
}

export function cartDownload() {
  return (dispatch) => {
    dispatch(cartDownloadIsLoading(true));
    cartApi.download()
    .then(downloadRes => {
      const { filename } = downloadRes.data;
      window.open(`https://api.jde-mediabox.com/download/${filename}`);

      // axios({
      //   url: `${devendpoint}/download/${filename}`,
      //   method: 'GET',
      //   responseType: 'blob', // important
      // })
      // .then((response) => {
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', filename);
      //   document.body.appendChild(link);
      //   link.click();
      dispatch(cartDownloadIsLoading(false));
      dispatch(downloadGetAll());
      // })
      // .then(() => dispatch(cartDownloadSuccess(true)))
      // .catch(() => dispatch(cartHasErrored(true)));
    })
    .then(() => dispatch(cartDownloadSuccess(true)))
    .catch(() => dispatch(cartHasErrored(true)));
  };  
}

export function cartFetchData(id) {
  return (dispatch) => {
    dispatch(cartIsLoading(true));
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',  
      'Authorization': `Bearer ${user.token}`
    }
    fetch(`${devendpoint}/cart/${id}`,{
      method: 'GET',
      headers
    })
    .then((response) => {
      if (!response.ok) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(cartIsLoading(false));
      return response;
    })
    .then((response) => response.json())
    .then((cart) => dispatch(cartFetchDataSuccess(cart.data)))
    .catch(() => dispatch(cartHasErrored(true)));
  };
}

export function cartAddAllFormat(assetIds, format){
 return (dispatch) => {
  dispatch(cartIsLoading(true));
  cartApi.addAllFormat(assetIds, format)
  .then(response => {
    if (response.status > 200) {
      if(response.status === 403)
        history.push('/login');
      throw Error(response.statusText);
    }
    dispatch(cartIsLoading(false));
    return response;
  })
    .then(cart => dispatch(cartAddAllFormatSuccess(cart.data)))
    .catch(() => dispatch(cartHasErrored(true)));
  };
}

export function cartRemoveAllFormat(assetIds, format){
 return (dispatch) => {
  dispatch(cartIsLoading(true));
  cartApi.delAllFormat(assetIds, format)
  .then(response => {
    if (response.status > 200) {
      if(response.status === 403)
        history.push('/login');
      throw Error(response.statusText);
    }
    dispatch(cartIsLoading(false));
    return response;
  })
    .then(cart => dispatch(cartRemoveAllFormatSuccess(cart.data)))
    .catch(() => dispatch(cartHasErrored(true)));
  };
}