import "isomorphic-fetch";
import React, { Component } from 'react'
import { renderToStaticMarkup } from "react-dom/server";
// import { Link } from 'react-router-dom'
// import { connect } from 'react-redux';
// import { compose } from 'redux'
import { withLocalize } from "react-localize-redux";

// MUI
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// Components
import Logo from '../../../img/logo.png';

const styles = theme => ({
  ...theme,
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'url(https://jde-mediabox.com/img)',
  },
  card: {
    ...theme.mixins.gutters(),
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing.unit * 2,
    float: 'none',
    minWidth: 300,
    maxWidth: 360,
    
    [theme.breakpoints.between('xs','sm')]: {
      marginTop: '0em',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '1em',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '3em',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '6em',
    },
  },
  formControl: {
    // margin: theme.spacing.unit,
    width: '100%'
  },
  button: {
    // marginTop: 20
  },
  regButton: {
    marginBottom: theme.spacing.unit*2
  },
  media: {
    height: 140,
    width: 140,
    margin: '0 auto',
    marginTop: 10
  },
  text: {
    textAlign: 'center',
    paddingBottom: 10,
    paddingTop: 10,
  },
  pwLostbutton: {
    padding: 0,
    textTransform: 'none'
  }, 
  pwLostaction: {
    float: 'right',
    padding: 0,
    paddingBottom: theme.spacing.unit * 2
  },
  actions: {
    display: 'block',
    padding: 0,
    paddingBottom: theme.spacing.unit * 2
  }
});

const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

class ConfirmView extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  
    this.state = {
      error: true,
      isLoading: true,
      success: false
    }

    this.props.initialize({
      languages: ["de", "en", "fr"],
      options: { onMissingTranslation, renderToStaticMarkup }
    });

  }

  componentDidMount() {
    const { uuid } = this.props.match.params;
    fetch(`https://api.jde-mediabox.com/user/confirm/${uuid}`, {
      method: 'GET'
    })
    .then(response => response.json())
    .then(response => {
      this.setState({ isLoading: false })
      if(response.success) {
        this.props.setActiveLanguage(response.lang)
        this.setState({ error: false })
      }
    })    
  }

  handleClick(event) {
    // event.preventDefault();
    const { uuid } = this.props.match.params;
    fetch(`https://api.jde-mediabox.com/user/confirm/${uuid}`, {
      method: 'POST'
    })
    .then(response => response.json())
    .then(response => {
      if(response.success)
        this.setState({ success: true, error: false, isLoading: false });
      else
        this.setState({ success: false, error: true, isLoading: false });
    })
    
    this.setState({
      isLoading: true,
      error: false
    })
  }

  render() {
    const { classes, translate } = this.props;
    const { error, isLoading, success } = this.state;
    return(
      <div className={classes.root}>
        <Card className={classes.card} elevation={1}>
          <CardMedia
            className={classes.media}
            image={Logo}
            title="JDE mediaBOX"
          />
          { !error ? success ?
          <div>
            <Typography className={classes.text} variant="subtitle1" component="h3">
              {translate("signup.confirm.emailConfirmed")}
            </Typography>

            <Typography className={classes.text} variant="body2" component="p">
              {translate("signup.confirm.confirmedText")}
            </Typography>

          </div>
              :
          <div>
            <Typography className={classes.text} variant="subtitle1" component="h3">
              {translate("signup.confirm.confirmText")}
            </Typography>
            <CardActions className={classes.actions}>
              <Button
                onClick={this.handleClick}
                variant="contained"
                color="primary"
                disabled={(isLoading || success)}
                className={classes.button}
                fullWidth
              >
                {isLoading && (
                  <CircularProgress size={25} thickness={2} />
                )}
                {translate("signup.confirm.confirmBtn")}
              </Button>
            </CardActions>
          </div>
          : <Typography className={classes.text} variant="subtitle1" component="h3">
              {translate("signup.confirm.linkInvalid")}
            </Typography>
          }
        </Card>
      </div>
    );
  }
}


const Confirm = withLocalize(withStyles(styles)(ConfirmView));
export { Confirm }