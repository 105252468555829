import React from 'react';
import Typography from '@material-ui/core/Typography';

const PrivacyEnglish = props => {
  const { classes } = props;
  return(
    <div>
          <Typography variant="body2" className={classes.text}>We welcome you to our website. We would like to inform you about the management of your personal data in accordance with Art. 13 General Data Protection Regulation (GDPR).</Typography>
          
          <Typography variant="h6">Controller</Typography>
          <Typography variant="body2" className={classes.text}>The controller responsible for the described data collection and processing is named in the <a href="/imprint">imprint</a></Typography>

          <Typography variant="h6">Storage of Your IP address</Typography>
          <Typography variant="body2" className={classes.text}>We store the IP address transmitted by your web browser for a period of seven (7) days, strictly for the purpose of identifying, restricting and eliminating attacks on our website. After seven (7) days, we delete or anonymize your IP address. The legal basis for the processing of this personal data is provided for in Art. 6 para. 1 s. 1 lit. f GDPR.</Typography>
          
          <Typography variant="h6">Usage Data</Typography>
          <Typography variant="body2" className={classes.text}>When you visit our website, the data collected from the use of the website is temporarily stored on our web server for statistical purposes in order to improve the quality of our website. This data set contains:</Typography>
          <Typography variant="body2" className={classes.text}>- the page, from which the data is requested,</Typography>
          <Typography variant="body2" className={classes.text}>- the name of the data file,</Typography>
          <Typography variant="body2" className={classes.text}>- the date and time of the query,</Typography>
          <Typography variant="body2" className={classes.text}>- the amount of data transferred,</Typography>
          <Typography variant="body2" className={classes.text}>- the access status (file transmitted, file not found),</Typography>
          <Typography variant="body2" className={classes.text}>- a description of the type of browser used,</Typography>
          <Typography variant="body2" className={classes.text}>- the IP address of the requesting computer shortened to such an extent that no reidentification of any persona data is possible. </Typography>
          <Typography variant="body2" className={classes.text}>The listed usage data is stored anonymously.</Typography>

          <Typography variant="h6">Service Providers</Typography>
          <Typography variant="body2" className={classes.text}>We may transfer your data to service providers who support us in the operation of our website and the associated processes. This transference is made under the scope of a data processing agreement in accordance to Art. 28 GDPR. Our service providers are bound to us by strict instructions and contractual obligations.</Typography>
          
          <Typography variant="h6">Cookies</Typography>
          <Typography variant="body2" className={classes.text}>We use cookies on our website. There are session cookies, which are deleted when you close your browser, and permanent cookies, which are stored even after your visit has expired. Cookies may contain data that enables the recognition of the device being used. However, in some cases cookies only contain information on certain settings which are not personal data.</Typography>
          <Typography variant="body2" className={classes.text}>Please be aware that you can set your browser to inform you when cookies are being stored or used on the website you are visiting. Thus, any use of cookies is transparent to you. You have the possibility to delete your browser configuration at any time and prevent any use of new cookies.  In the event you refuse the use of cookies, please note that our web sites may not be displayed optimally and some functions are then no longer technically available.</Typography>

          <Typography variant="h6">Matomo</Typography>
          <Typography variant="body2" className={classes.text}>We use the web analysis tool Matomo to create pseudonymous user profiles for the tailoring of our website. Matomo uses cookies, which are stored on your terminal and can be read by us. By doing this, we are able to recognize and count returning visitors. Your complete IP address will not be stored. This data processing is carried out on the basis of your consent (Art. 6 para. 1 s. 1 lit. a GDPR).</Typography>
          <Typography variant="body2" className={classes.text}>You may revoke your consent to the processing at any time. In order to do so please tick the following box. An opt-out cookie will then be stored on your browser, which means that Matomo will not collect any session data. If you delete your cookies, however, the opt-out cookie will also be deleted and you may need to reactivate it.</Typography>


          <iframe
            title="Opt-Out"
            style={{border: 0, height: '12em', width: '100%', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}}
            src="https://tracking.jde-mediabox.com/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=&fontColor=&fontSize=0.875rem&fontFamily=%22Roboto%22%2C%20%22Helvetica%22%2C%20%22Arial%22%2C%20sans-serif">
          </iframe>


          <Typography variant="h6">Restricted Access Area</Typography>
          <Typography variant="body2" className={classes.text}>In case you want to gain access to our products, you must register beforehand. We process your data according to Art. 6 par. 1 s. 1 lit. b GDPR. Data transferred to us in the context of the registration will be used in order to identify you and to enable us to transfer data to you. You can change or delete your profile within your account at any time. Data will be deleted from the system automatically if there are no retention requirements or the data is not required on an individual case basis (e.g. in the case of open claims).</Typography>

          <Typography variant="h6">Data Security</Typography>
          <Typography variant="body2" className={classes.text}>To avoid unauthorized access to your data, we have implemented technical and organizational measures. We use encryption technologies on our website. Your data will be transferred to our servers and back again via a connection that is protected by a TLS encryption technology. You can recognize that you are browsing on an encryption secured website by the lock-symbol shown in the address bar of your browser and by the address bar starting with https://</Typography>

          <Typography variant="h6">Your Rights as a User</Typography>
          <Typography variant="body2" className={classes.text}>As a website user, the GDPR grants you certain rights when processing your personal data.</Typography>
          <Typography variant="body2" className={classes.textP}>1. Right of access (Art. 15 GDPR): </Typography>
          <Typography variant="body2" className={classes.text}>You have the right to obtain confirmation as to whether or not personal data concerning you is being processed, and, where that is the case to obtain access to the personal data and the information specified in Art. 15 GDPR.</Typography>
          <Typography variant="body2" className={classes.textP}>2. Right to rectification and erasure (Art. 16 and 17 GDPR):</Typography>
          <Typography variant="body2" className={classes.text}>You have the right to obtain without undue delay the rectification of inaccurate personal data concerning you and, if necessary, the right to have incomplete personal data completed.</Typography>
          <Typography variant="body2" className={classes.text}>You also have the right to obtain an erasure of the personal data concerning you without undue delay, if one of the reasons listed in Art. 17 GDPR applies, e.g. if the data is no longer necessary for the intended purpose.</Typography>
          <Typography variant="body2" className={classes.textP}>3. Right to restriction of processing (Art. 18 GDPR):</Typography>
          <Typography variant="body2" className={classes.text}>If one of the conditions set forth in Art. 18 GDPR applies, you shall have the right to restrict the processing of your data to mere storage, e.g. if you revoke consent, to the processing, for the duration of a possible examination.</Typography>
          <Typography variant="body2" className={classes.textP}>4. Right to data portability (Art. 20 GDPR):</Typography>
          <Typography variant="body2" className={classes.text}>In certain situations, listed in Art. 20 GDPR, you have the right to receive the personal data concerning you in a structured, common and machine-readable format or demand a transmission of the data to another third party.</Typography>
          <Typography variant="body2" className={classes.textP}>5. Right to object (Art. 21 GDPR):</Typography>
          <Typography variant="body2" className={classes.text}>If the data is processed pursuant to Art. 6 para. 1 s. 1 lit. f GDPR (data processing for the purposes of the legitimate interests), you have the right to object to the processing at any time for reasons arising out of your particular situation. We will then no longer process personal data, unless there are demonstrably compelling legitimate grounds for processing, which override the interests, rights and freedoms of the person concerned, or the processing serves the purpose of asserting, exercising or defending legal claims.</Typography>
          <Typography variant="body2" className={classes.textP}>6.Right to lodge a complaint with a supervisory authority</Typography>
          <Typography variant="body2" className={classes.text}>Pursuant to Art. 77 GDPR, you have the right to lodge a complaint with a supervisory authority if you consider the processing of the data concerning you infringes data protection regulations. The right to lodge a complaint may be invoked in particular in the Member State of your habitual residence, place of work or the place of the alleged infringement.</Typography>
          <Typography variant="body2" className={classes.text}>Contact Details of the Data Protection Officer</Typography>
          <Typography variant="body2" className={classes.text}>Please contact our data protection officer if you have any further questions, suggestions or wishes regarding data protection:</Typography>
          <Typography variant="body2" className={classes.text}>Dr. Uwe Schläger</Typography>
          <Typography variant="body2" className={classes.text}>datenschutz nord GmbH</Typography>
          <Typography variant="body2" className={classes.text}>Konsul-Smidt-Straße 88</Typography>
          <Typography variant="body2" className={classes.text}>28217 Bremen</Typography>
          <Typography variant="body2" className={classes.text}>Web: <a href="https://www.datenschutz-nord-gruppe.de">www.datenschutz-nord-gruppe.de</a></Typography>
          <Typography variant="body2" className={classes.text}>E-Mail: <a href="mailto:office@datenschutz-nord.de">office@datenschutz-nord.de</a></Typography>
    </div>
  )
}

export { PrivacyEnglish }