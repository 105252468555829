import React, { Component } from 'react';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';

import { reduxForm, Field } from 'redux-form';

import FileInput from './components/FileInput';
// import Dropzone from 'react-dropzone';

// const renderedTextField = ({
//   input,
//   label,
//   meta: { touched, error },
//   ...custom
// }) => (
//   <TextField
//     label={label}
    
//     {...input}
//     {...custom}
//   />
// )
class FilesForm extends Component {
  render(){
    return (
      <form>
        <Field
          name="newFiles"
          component={FileInput}
        />           
      </form>
    );
  }
}


export default reduxForm({
  form: 'orderForm',
  destroyOnUnmount: false,
})(FilesForm);