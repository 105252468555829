import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from "react-localize-redux";
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { CartTableRow } from './CartTableRow'

// REDUX
import {
  cartAddAllFormat,
  cartAddOneFormat,
  cartRemoveOneFormat,
  cartRemoveOne,
  cartAddManyFormat,
  cartRemoveManyFormat,
} from '../../_actions';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

// function SimpleTable(props) {
class SimpleTable extends Component {
  constructor(props) {
    super(props);
    this.handleChangeAll = this.handleChangeAll.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    // this.handleCheckboxes = this.handleCheckboxes.bind(this);
    // this.state = props.cartItems.map(item => {
    //   const id = item.id;
    //   for(let file of item.files){
    //     const mediaType = file.mediaType;
    //     return { [`${id}.${mediaType}`]: true }
    //   }
    // })
  }



  handleDelete(id) {
    this.props.cartRemoveOne(id);
  }

  handleChangeAll(mediatype){
    // true = all checked, ergo remove all
    let add = [], remove = [];
    if(this.handleCheckboxes(mediatype)){
      for(let item of this.props.cart){
        if(item.mediaType === mediatype || item.relatedAsset.mediaType === mediatype)
          remove.push(item.id);
      }
    } // else: add all
    else {
      for(let item of this.props.cart){
        if(!this.props.cartCheckboxState[`${item.id}.${mediatype}`])
          if(item.mediaType === mediatype || item.relatedAsset.mediaType === mediatype)
            add.push(item.id);
      }
    }
    if(remove.length > 0)
      this.props.cartRemoveManyFormat(remove, mediatype);
    if(add.length > 0)
      this.props.cartAddManyFormat(add, mediatype);
  }

  handleCheckboxes(mediatype) {
    let checked = 0;
    let fail = 0;
    for(let item of this.props.cart){
      if(this.props.cartCheckboxState[`${item.id}.${mediatype}`])
        checked++;
      if(item.mediaType !== mediatype && item.relatedAsset.mediaType !== mediatype)
        fail++;
    }
    // console.log('checked', `${checked}/${this.props.cart.length}`, mediatype);
    // console.log('fail', `${fail}/${this.props.cart.length}`, mediatype);
    return checked === this.props.cart.length-fail;
  }

  handleIndeterminateCheckboxes(mediatype) {
    let checked = false;
    for(let item of this.props.cart){
      if(this.props.cartCheckboxState[`${item.id}.${mediatype}`])
        checked = true;
    }
    return this.handleCheckboxes(mediatype) ? false : checked;
  }

  render(){
    const { classes, cart, translate } = this.props;
    // let mediaTypes = [];
    // mediaTypes[0] = cart[0] ?
    if(cart.length === 0)
      return null;
    const mediaTypes = cart[0] ? [cart[0].mediaType, cart[0].relatedAsset ? cart[0].relatedAsset.mediaType : 0] : [0,0];

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translate("cart.delete")}</TableCell>
              <TableCell>{translate("cart.name")}</TableCell>
              <TableCell>
              
                <FormControlLabel
                  control={
                    <Tooltip title={this.handleCheckboxes(mediaTypes[0]) ? "Alle abwählen" : "Alle auswählen"} placement="right">
                      <Checkbox
                        name="all"
                        color="primary"
                        onChange={ (e) => this.handleChangeAll(mediaTypes[0])}
                        // checked={this.handleCheckboxes(mediaTypes[0])}
                        checked={this.handleIndeterminateCheckboxes(mediaTypes[0]) || this.handleCheckboxes(mediaTypes[0])}
                        indeterminate={this.handleIndeterminateCheckboxes(mediaTypes[0])}
                        disabled={this.props.isLoading}
                      />
                    </Tooltip>
                  }
                />
                
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Tooltip title={this.handleCheckboxes(mediaTypes[1]) ? "Alle abwählen" : "Alle auswählen"} placement="right">
                      <Checkbox
                        name="all"
                        color="primary"
                        onChange={ (e) => this.handleChangeAll(mediaTypes[1])}
                        // checked={this.handleCheckboxes(mediaTypes[1])}
                        checked={this.handleIndeterminateCheckboxes(mediaTypes[1]) || this.handleCheckboxes(mediaTypes[1])}
                        indeterminate={this.handleIndeterminateCheckboxes(mediaTypes[1])}
                        disabled={this.props.isLoading}
                      />
                    </Tooltip>
                  }
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cart.map(item => (
              <CartTableRow
                key={item.id}
                item={item}
                // handleCheckboxes={this.handleCheckboxes}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  let cartItems;
  if(state.cart.cartItems)
    cartItems = state.cart.cartItems;

  return {
    cartItems: cartItems,
    cart: state.cart.items ? state.cart.items : [],
    isLoading: state.isLoading || state.cartDownloadIsLoading || state.cartDownloadListIsLoading,
    cartCheckboxState: state.cartCheckboxState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    cartAddAllFormat: (assetIds) => dispatch(cartAddAllFormat(assetIds)),
    cartAddOneFormat: (id, mediaType) => dispatch(cartAddOneFormat(id, mediaType)),
    cartRemoveOneFormat: (id, mediaType) => dispatch(cartRemoveOneFormat(id, mediaType)),
    cartAddManyFormat: (assetIds, mediaType) => dispatch(cartAddManyFormat(assetIds, mediaType)),
    cartRemoveManyFormat: (assetIds, mediaType) => dispatch(cartRemoveManyFormat(assetIds, mediaType)),
    cartRemoveOne: (id) => dispatch(cartRemoveOne(id)),
  };
};

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const CartTable = compose(
  withLocalize,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SimpleTable);

export { CartTable }