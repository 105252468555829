import React, { Component } from 'react'
import axios from 'axios';
import ReactPiwik from 'react-piwik';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { uiSetLang } from '../../_actions';

import { devendpoint } from '../../api';

class DownloadView extends Component {
  state = {
    isLoading: true,
    error: false
  }

  componentDidMount() {
    // super(props);
    // this.handleClick = this.handleClick.bind(this);
    this.props.uiSetLang(this.props.lang || "de");
    const { filename } = this.props.match.params;
    
    (async () => {
      let apiRes = null;
      try {
        apiRes = await axios({
          url: `${devendpoint}/download/${filename}`,
          method: 'GET',
          responseType: 'blob', // important
        });
      } catch (err) {
        this.setState({ isLoading: false, error: true });
        ReactPiwik.push(['trackEvent', "Warenkorb", "invalid link" ]);
      } finally {
        this.setState({ isLoading: false, error: apiRes ? false : true });
        if(!apiRes) return;
        const url = window.URL.createObjectURL(new Blob([apiRes.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        ReactPiwik.push(['trackEvent', "Warenkorb", this.props.isLoggedin ? "registered user" : "guest" ]);
      }
    })();
  }


  render() {
    if(this.state.isLoading) return <div><p>loading...</p></div>

    return(
      <div style={{position: "relative", width: "100vw", height: "100vh"}}>
        <div style={{position: "absolute", top: "25%", left: "50%", transform: "translate(-50%, -50%"}}>
          { !this.state.error ? 
            <p>Falls der Download nicht automatisch startet, klicken Sie bitte hier</p>
            :
            <p>Ungültiger Link</p>
          }

          {!this.state.error && <Button style={{left: "50%", transform: "translateX(-50%)"}} href={`${devendpoint}/download/${this.props.match.params.filename}`} variant="contained" color="primary">Download</Button>}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetLang: (lang) => dispatch(uiSetLang(lang)),
  };
};

const mapStateToProps = state => {
  return {
    lang: state.lang,
    isLoggedin: !!Boolean(state.authentication.user),
  }
}

const Download = connect(mapStateToProps,mapDispatchToProps)(DownloadView);

export { Download }