import "isomorphic-fetch";
import axios from 'axios';
import ReactPiwik from 'react-piwik';

import { devendpoint } from '../api';
import { searchConstants } from '../_constants'
import { history } from '../_helpers';

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

function searchHasErrored(bool) {
  return {
    type: searchConstants.SEARCH_HAS_ERRORED,
    hasErrored: bool
  };
}

function searchIsLoading(bool) {
  return {
    type: searchConstants.SEARCH_IS_LOADING,
    isLoading: bool
  };
}

function searchFetchDataSuccess(search) {
  return {
    type: searchConstants.SEARCH_FETCH_DATA_SUCCESS,
    search
  };
}

function _setSearchString(searchString) {
  // console.log(searchString);
  return {
    type: searchConstants.SEARCH_SET_SEARCHSTRING,
    searchString
  }
}

function setSearchFilterDispatch(searchFilter) {
  return {
    type: searchConstants.SEARCH_SET_FILTER,
    searchFilter
  }
}

export function setSearchString(searchString) {
  return (dispatch) => {
    dispatch(_setSearchString(searchString));
  }
}

export function setSearchFilter(searchFilter) {
  return (dispatch) => {
    dispatch(setSearchFilterDispatch(searchFilter));
  }
}

export function searchFetchData(query) { // add rootId
  return (dispatch) => {
    dispatch(searchIsLoading(true));
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',  
      'Authorization': `Bearer ${user.token}`
    }


    axios({
      method: 'post',
      url: `${devendpoint}/search/`,
      data: { query: query.query },
      headers
    })

    // axios.post(`${devendpoint}/search/`, {query}, {headers})
    .then((response) => {
      if(response.status === 403)
        history.push('/login');
      dispatch(searchIsLoading(false));
      dispatch(searchHasErrored(false));
      // console.log(['trackSiteSearch', query, "search", response.data.results.length]);
      ReactPiwik.push(['trackSiteSearch', query, "search", response.data.results.length]);
      ReactPiwik.push(['trackPageView']);
      return response.data;
    })
    .then((search) => {
      dispatch(searchFetchDataSuccess(search));

      let results = search.results.filter(r => parseInt(r.countryId) === query.rootId)
      const grouped = groupBy(results, item => item.rootId);
      let groups = [];
      for (var [key] of grouped) {
        groups.push(String(key));
      }
      dispatch(setSearchFilterDispatch(groups));

    })
    .catch(() => {
      dispatch(searchHasErrored(true));
      dispatch(searchIsLoading(false));
    });
  };
}