export const userConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  INFO_REQUEST: 'INFO_REQUEST',
  INFO_SUCCESS: 'INFO_SUCCESS',
  INFO_FAILURE: 'INFO_FAILURE',
  SET_INFO_REQUEST: 'SET_INFO_REQUEST',
  SET_INFO_SUCCESS: 'SET_INFO_SUCCESS',
  SET_INFO_FAILURE: 'SET_INFO_FAILURE',
  LOGOUT: 'LOGOUT',
};


