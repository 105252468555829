import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from "react-localize-redux";

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import FormHelperText from '@material-ui/core/FormHelperText';

// REDUX
import { setSearchFilter } from '../../_actions/';
import { connect } from 'react-redux';
import { compose } from 'redux';


const styles = theme => ({
  root: {
    margin: theme.spacing.unit,
    // padding: theme.spacing.unit,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing.unit,
    width: '100%',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
});

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

function formatName(name) {
  return name.substring(0,1) === "#" ? name.substring(5) : name;
}

class SearchFilterComponent extends Component {
  
  constructor(props){
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    let results = this.props.search.results.filter(r => parseInt(r.countryId) === this.props.rootId)
    const grouped = groupBy(results, item => item.rootId);
    let groups = [];
    for (var [key] of grouped) {
      groups.push(String(key));
    }
    this.props.setSearchFilter(groups);
  }

  handleChange = id => event => {
    // let arr = [];
    // this.setState({ [id]: event.target.checked }, () => {
    //   Object.keys(this.state).forEach(i => {
    //     if(this.state[i]){
    //       arr.push(i);
    //     }
    //   });
    if(!event.target.checked)
      this.props.setSearchFilter(this.props.searchFilter.filter(s => s !== String(id)));
    else
      this.props.setSearchFilter([...this.props.searchFilter, String(id)]);
    // });
  }
  
  render(){
    const { classes, search, nodes, translate, lang, rootId } = this.props;
    let options = [];
    let countryOptions = [];
    try {
      const results = search.results.filter(res => parseInt(res.countryId) === rootId)
      const grouped = groupBy(results, item => item.rootId);
      const groupedByCountry = groupBy(search.results, item => item.countryId);
      // console.log(groupedByCountry);
      for (var [key, value] of grouped) {
        // eslint-disable-next-line
        let node = nodes[rootId].filter(i => (i.id === parseInt(key)));
        let nodeItem = node[0];
        let name = nodeItem.name[lang] ? nodeItem.name[lang] : nodeItem.name["de"]
        options.push({ id: key, name, length: value.length })
      }
      for (var [k, v] of groupedByCountry) {
        let name = translate(`AppBar.country.${k}`)
        countryOptions.push({ id: k, name, length: v.length })
      }
      // console.log(countryOptions);
    }
    catch(e) {
      // console.log(e);
    }

    return (
      <div className={classes.root}>

        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">{translate("search.filter")}</FormLabel>
          <FormGroup>
          {
            options.map(opt => (
              <FormControlLabel
                key={opt.id}
                control={
                  <Checkbox
                    checked={this.props.searchFilter.includes(String(opt.id))}
                    onChange={this.handleChange(opt.id)}
                    value={opt.id}
                  />
                }
                label={`${formatName(opt.name)} (${opt.length})`}
              />
            ))
          }

          {
/*            countryOptions.map(opt => (
              <FormControlLabel
                key={opt.id}
                control={
                  <Checkbox
                    // checked={this.state[opt.id]}
                    checked={true}
                    onChange={this.handleChange(opt.id)}
                    value={opt.id}
                  />
                }
                label={`${opt.name} (${opt.length})`}
              />
            ))
          */}

          </FormGroup>
          {/*<FormHelperText>Be careful</FormHelperText>*/}
        </FormControl>
      </div>
    );
  }
}

SearchFilterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapDispatchToProps = (dispatch) => {
  return {
    setSearchFilter: (filter) => dispatch(setSearchFilter(filter)),
  };
};

const mapStateToProps = state => {
  return {
    search: state.search,
    nodes: state.nodes,
    rootId: state.rootId,
    lang: state.lang,
    searchFilter: state.searchFilter,
  }
}

let SearchFilter = compose(
  connect(mapStateToProps,mapDispatchToProps),
  withStyles(styles),
  withLocalize,
)(SearchFilterComponent)

export { SearchFilter }