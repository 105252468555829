import React, { Component } from 'react';
import { reduxForm, Field, reset, change } from 'redux-form'
import { withLocalize } from "react-localize-redux";

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux';

import { Flag } from '../../components/CountrySwitcher';
import { orderApi } from '../../api';
import { userActions } from '../../_actions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    margin: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    width: '25%',
    display: 'flex',
  },
  textField50: {
    width: "50%",
    display: "inline-block",
  }
});

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

const renderedTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    
    {...input}
    {...custom}
  />
)

const renderedDatePicker = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    type="date"
    InputLabelProps={{
      shrink: true,
    }}
    {...input}
    {...custom}
  />
)

const renderCheckbox = ({ input, label, disabled }) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
          disabled={disabled}
          color="primary"
        />
      }
      label={label}
    />
  </div>
);

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error}>
    <InputLabel htmlFor="status">Status</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: 'status',
        id: 'status'
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
)

// const renderFormHelper = ({ touched, error }) => {
//   if (!(touched && error)) {
//     return
//   } else {
//     return <FormHelperText>{touched && error}</FormHelperText>
//   }
// }

// const renderSelectField = ({
//   input,
//   label,
//   meta: { touched, error },
//   children,
//   ...custom
// }) => (
//   <FormControl error={touched && error}>
//     <InputLabel htmlFor="age-native-simple">Abbildungstyp</InputLabel>
//     <Select
//       native
//       {...input}
//       {...custom}
//       inputProps={{
//         name: 'age',
//         id: 'age-native-simple'
//       }}
//     >
//       {children}
//     </Select>
//     {renderFormHelper({ touched, error })}
//   </FormControl>
// )

const radioButton = ({ input, ...rest }) => (
  <FormControl>
    <RadioGroup {...input} {...rest}>
      <FormControlLabel value="yes" control={<Radio color="primary"/>} label="Artikel neu anlegen"/>
      <FormControlLabel value="no" control={<Radio color="primary"/>} label="Bestehenden Artikel ändern"/>
      <FormControlLabel value="delete" control={<Radio color="primary"/>} label="Artikel entfernen"/>
    </RadioGroup>
  </FormControl>
)



class OrderForm extends Component {
  constructor(props){
    super(props);
    this.handleDataChange = this.handleDataChange.bind(this);
  }

  componentDidMount(){
    this.props.getUserData();
    if(this.props.new)
      this.props.reset('orderForm');
  }

  handleDataChange(e){
    if(e.target.name === "vertretung"){
      this.props.change("vertretung_checked", true);
    }
    else if((e.target.value.length > 4 && this.props.orderForm.values.new === "no") || (e.target.value.length > 4 && this.props.orderForm.values.new === "delete"))
      orderApi.search(e.target.value, e.target.name)
      .catch(err => {

      })
      .then(response => {
        if(response){
          if(e.target.name === "gtin"){
            this.props.change("articleId", response.data.articleId);
            this.props.change("name", response.data.name);
            if(response.data.name.toLowerCase().indexOf('regalkarton') > -1)
              this.props.change("packshot.tray", true);
            if(response.data.name.toLowerCase().indexOf('display') > -1)
              this.props.change("packshot.display", true);
          }
          else if(e.target.name === "articleId"){
            this.props.change("gtin", response.data.gtin);
            this.props.change("name", response.data.name);
          }
          this.props.change(`${response.data.country}`, true);
          // console.log(response.data);
        }
      })
  }

  render(){
    const { classes, orderForm, isSuperUser } = this.props;
    const editExisting = this.props.orderForm && this.props.orderForm.values && this.props.orderForm.values.new ? this.props.orderForm.values.new === "no" : false;
    const disabledChange = Boolean(this.props.orderForm && this.props.orderForm.values && this.props.orderForm.values.change ? (this.props.orderForm.values.change.data || this.props.orderForm.values.change.packshot) : false);
    var disabled = !!!(orderForm && orderForm.values && orderForm.values.new);
    if(this.props.orderForm && this.props.orderForm.values && this.props.orderForm.values.new === "no")
      disabled = !disabledChange;

    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <form>
            
            <Grid item xs={12} sm={12}>
              <Field name="new" component={radioButton} />
            </Grid>

            { editExisting &&
            <Grid item xs={12} sm={12}>
              <Field name="change.data" component={renderCheckbox} label="Artikeldaten ändern"/>
              <Field name="change.packshot" component={renderCheckbox} label="Abbildung ändern"/>
            </Grid>
            }

            {(isSuperUser && !this.props.new) &&
              <Grid container direction="row">
                <Grid item xs={12} sm={12}>
                  <Field
                    name="status"
                    component={renderSelectField}
                    label="Status"
                    disabled={disabled}
                  >
                    <option />
                    <option value="new">In Bearbeitung</option>
                    <option value="pending">Bereit zur Abnahme</option>
                    <option value="rejected">Abgelehnt</option>
                    <option value="accepted">Akzeptiert</option>
                    <option value="completed">Erledigt</option>
                  </Field>
                </Grid>
                { (this.props.orderForm && this.props.orderForm.values && this.props.orderForm.values.status && this.props.orderForm.values.status === "completed") && 
                <Grid item xs={12} sm={12}>
                   <Field name="notify" component={renderCheckbox} label={`${(this.props.orderForm && this.props.orderForm.values && this.props.orderForm.values.vertretung) ? this.props.orderForm.values.vertretung : ""} Benachrichtigen?`}/>
                </Grid>
                }
              </Grid>
            }

            <Grid item xs={12} sm={6} className={classes.textField50}>
              <Field disabled={disabled} name="articleId" type="text" component={renderedTextField} fullWidth label="Artikelnummer" onChange={this.handleDataChange} />
            </Grid>

            <Grid item xs={12} sm={6} className={classes.textField50}>
              <Field disabled={disabled} name="gtin" type="text" component={renderedTextField} fullWidth label="GTIN" onChange={this.handleDataChange} />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Field disabled={disabled} name="name" type="text" component={renderedTextField} fullWidth label="Produktbezeichnung"/>
            </Grid>

            <Grid item xs={12} sm={12}>
              <div style={{ display: 'flex' }}>
                <Field disabled={disabled} name="de" component={renderCheckbox} label={<Flag country="de" checked={false} />} />
                <Field disabled={disabled} name="at" component={renderCheckbox} label={<Flag country="at" checked={false} />} />
                <Field disabled={disabled} name="ch" component={renderCheckbox} label={<Flag country="ch" checked={false} />} />
              </div>

              {/*
                            <Field
                              disabled={disabled}
                              name="kind"
                              component={renderSelectField}
                            >
              
                              <option value={1}>Produktabbildung</option>
              
                            </Field> */}
              {/* (orderForm && orderForm.values && orderForm.values.basic && orderForm.values.kind && orderForm.values.kind === "1") && <span></span> */}
              <div style={{ display: 'flex' }}>
                <Grid item xs={6} sm={6}>
                  <Field disabled={disabled} name="packshot.ecommerce" component={renderCheckbox} label="E-Commerce"/>
                </Grid>
                <Grid item xs={6} sm={6}>
                  { (orderForm && orderForm.values && orderForm.values.packshot && orderForm.values.packshot.ecommerce) &&
                  <Field disabled={disabled} name="packshot.gtin.ecommerce" type="text" component={renderedTextField} fullWidth label="GTIN (optional)"/>
                  }
                </Grid>
              </div>

              <div style={{ display: 'flex' }}>
                <Grid item xs={6} sm={6}>
                  <Field disabled={disabled} name="packshot.single" component={renderCheckbox} label="Einzelpackshot"/>
                </Grid>
                <Grid item xs={6} sm={6}>
                  { (orderForm && orderForm.values && orderForm.values.packshot && orderForm.values.packshot.single) &&
                  <Field disabled={disabled} name="packshot.gtin.single" type="text" component={renderedTextField} fullWidth label="GTIN (optional)"/>
                  }
                </Grid>
              </div>

              <div style={{ display: 'flex' }}>
                <Grid item xs={6} sm={6}>
                  <Field disabled={disabled} name="packshot.tray" component={renderCheckbox} label="Regalkarton"/>
                </Grid>
                <Grid item xs={6} sm={6}>
                { (orderForm && orderForm.values && orderForm.values.packshot && orderForm.values.packshot.tray) &&
                  <Field disabled={disabled} name="packshot.gtin.tray" type="text" component={renderedTextField} fullWidth label="GTIN (optional)"/>
                }
                </Grid>
              </div>

              <div style={{ display: 'flex' }}>
                <Grid item xs={6} sm={6}>
                  <Field disabled={disabled} name="packshot.display" component={renderCheckbox} label="Display"/>
                </Grid>
                <Grid item xs={6} sm={6}>
                { (orderForm && orderForm.values && orderForm.values.packshot && orderForm.values.packshot.display) &&
                  <Field disabled={disabled} name="packshot.articleId.display" type="text" component={renderedTextField} fullWidth label="Artikelnr (optional)"/>
                }
                </Grid>
              </div>

              <div style={{ display: 'flex' }}>
                <Grid item xs={6} sm={6}>
                  <div></div>
                </Grid>
                <Grid item xs={6} sm={6}>
                { (orderForm && orderForm.values && orderForm.values.packshot && orderForm.values.packshot.display) &&
                  <Field disabled={disabled} name="packshot.gtin.display" type="text" component={renderedTextField} fullWidth label="GTIN (optional)"/>
                }
                </Grid>
              </div>

              <div style={{ display: 'flex' }}>
                <Grid item xs={6} sm={6}>
                  <Field disabled={disabled} name="packshot.other" component={renderCheckbox} label="Sonstige"/>
                </Grid>
                <Grid item xs={6} sm={6}>
                  { (orderForm && orderForm.values && orderForm.values.packshot && orderForm.values.packshot.other) &&
                  <Field disabled={disabled} name="packshot.description" type="text" component={renderedTextField} fullWidth label="Beschreibung"/>
                  }
                </Grid>
              </div>

              <div className="placeholder">
              &nbsp;
              </div>


            </Grid>
            
            <Grid item xs={12} sm={6} className={classes.textField50}>
              <Field disabled={disabled} name="internalDate" type="date" component={renderedDatePicker} fullWidth label="Verfügbarkeit intern"/>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.textField50}>
              <Field disabled={disabled} name="externalDate" type="date" component={renderedDatePicker} fullWidth label="Verfügbarkeit extern"/>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Field disabled={disabled} name="vertretung" type="text" component={renderedTextField} fullWidth label="Benachrichtigung an" onChange={this.handleDataChange}/>
              <FormHelperText>(mehrere durch Komma getrennt)</FormHelperText>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Field disabled={disabled} name="description" type="text" component={renderedTextField} fullWidth multiline rows="4" label="Beschreibung"/>
            </Grid>

          </form>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (form) => dispatch(reset(form)),
    change: (field, value) => dispatch(change(field, value)),
    getUserData: () => dispatch(userActions.getInfo())
  };
};

OrderForm = reduxForm({
  form: 'orderForm', // a unique identifier for this form
  destroyOnUnmount: false,
  enableReinitialize: true,
  multipartForm : true,
})(withStyles(styles)(withLocalize(OrderForm)))

OrderForm = connect(
  state => ({
    initialValues: {...state.order.order, vertretung: state.order.order.vertretung || state.user.userName, vertretung_checked: false }, // pull initial values from account reducer
    orderForm: state.form.orderForm,
    user: state.user,
    isSuperUser: state.authentication.user.isSuperUser
  }),
  mapDispatchToProps
  //{ getUserData: userActions.getInfo }, // bind account loading action creator
)(OrderForm)

export default OrderForm;
