import { uiConstants } from '../_constants';


function uiGetViewFromLocalStorage(view) {
  return {
    type: uiConstants.UI_GET_VIEW,
    view
  };
}

function uiGetLangFromLocalStorage(lang) {
  return {
    type: uiConstants.UI_GET_LANG,
    lang
  };
}

function uiGetCountryFromLocalStorage(country) {
  return {
    type: uiConstants.UI_GET_COUNTRY,
    country
  };
}

function uiSetViewToLocalStorage(view) {
  return {
    type: uiConstants.UI_SET_VIEW,
    view
  };
}

function uiSetLangDispatch(lang) {
  return {
    type: uiConstants.UI_SET_LANG,
    lang
  };
}

function uiSetCountryDispatch(country) {
  return {
    type: uiConstants.UI_SET_COUNTRY,
    country
  };
}

function uiSetSearchActiveDispatch(searchActive) {
  return {
    type: uiConstants.UI_SEARCH_ACTIVE,
    searchActive
  };
}

// function uiFilterSearchDispatch(search) {
//   return {
//     type: uiConstants.UI_FILTER_SEARCH,
//     search
//   };
// }

// function viewIsLoading(bool) {
//   return {
//     type: uiConstants.UI_VIEW_IS_LOADING,
//     isLoading: bool
//   };
// }

function uiShowSearch(bool) {
  return {
    type: uiConstants.UI_SHOW_SEARCH,
    showSearch: bool
  };
}

function uiGetContainer(container) {
  return {
    type: uiConstants.UI_GET_CONTAINER,
    container
  };
}

function uiSetContainer(container) {
  return {
    type: uiConstants.UI_SET_CONTAINER,
    container
  };
}
function uiSetCartQuickViewOpenDispatch(cartQuickViewOpen) {
  return {
    type: uiConstants.UI_CART_QUICK_VIEW_OPEN,
    cartQuickViewOpen
  };
}

export function uiGetView() {
  return (dispatch) => {
    let view = localStorage.getItem('view');
    if(!view)
      view = "grid";
    dispatch(uiGetViewFromLocalStorage(view));
  }
}


export function uiSetView(view) {
  return (dispatch) => {
    localStorage.setItem('view', view);
    dispatch(uiSetViewToLocalStorage(view));
  }
}

export function uiSetLang(lang) {
  return (dispatch) => {
    localStorage.setItem('lang', lang);
    dispatch(uiSetLangDispatch(lang));
  }
}

export function uiSetCountry(country) {
  return (dispatch) => {
    localStorage.setItem('country', country);
    dispatch(uiSetCountryDispatch(country));
  }
}

export function uiGetCountry() {
  return (dispatch) => {
    let country = localStorage.getItem('country');
    if(!country)
      country = "germany";
    dispatch(uiGetCountryFromLocalStorage(country));
  }
}

export function uiGetLang() {
  return (dispatch) => {
    let lang = localStorage.getItem('lang');
    if(!lang)
      lang = "de";
    dispatch(uiGetLangFromLocalStorage(lang));
  }
}


export function uiSetSearchActive(searchActive) {
  return (dispatch) => {
    dispatch(uiSetSearchActiveDispatch(searchActive));
  }
}


export function uiGetCurrentContainer() {
  return (dispatch) => {
    dispatch(uiGetContainer());
  }
}

export function uiSetCurrentContainer(id) {
  return (dispatch) => {
    dispatch(uiSetContainer(id));
  }
}

export function uiShowSearchField(bool) {
  return (dispatch) => {
    dispatch(uiShowSearch(bool));
  }
}

export function uiSetCartQuickViewOpen(bool) {
  return (dispatch) => {
    dispatch(uiSetCartQuickViewOpenDispatch(bool));
  }
}

