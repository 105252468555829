import React from 'react';
// import classNames from 'classnames';
import Moment from 'react-moment';
import 'moment-timezone';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import { lighten } from '@material-ui/core/styles/colorManipulator';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

import { Flag } from '../CountrySwitcher';

function createData(id, name, articleId, gtin, createdAt, createdBy, country, status) {
  return { id, name, articleId, gtin, createdAt, createdBy, country, status };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Artikel' },
  { id: 'articleId', numeric: true, disablePadding: false, label: 'Artikelnummer' },
  { id: 'gtin', numeric: true, disablePadding: false, label: 'GTIN' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Erstellt am' },
  { id: 'createdBy', numeric: false, disablePadding: false, label: 'Erstellt von' },
  { id: 'country', numeric: false, disablePadding: false, label: 'Land' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, showAll } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            &nbsp;
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align="left"
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sortieren"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
          { showAll &&
              <TableCell
                align="left"
                padding='default'
                sortDirection={orderBy === "status" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "status"}
                  direction={order}
                  onClick={this.createSortHandler("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
          }
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const toolbarStyles = theme => ({
//   root: {
//     paddingRight: theme.spacing.unit,
//   },
//   highlight:
//     theme.palette.type === 'light'
//       ? {
//           color: theme.palette.primary.main,
//           backgroundColor: lighten(theme.palette.primary.light, 0.85),
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.primary.dark,
//         },
//   spacer: {
//     flex: '1 1 100%',
//   },
//   actions: {
//     color: theme.palette.text.primary,
//   },
//   title: {
//     flex: '0 0 auto',
//   },
// });

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  pending: { backgroundColor: yellow[50], cursor: "pointer" },
  accepted: { backgroundColor: green[50], cursor: "pointer" },
  rejected: { backgroundColor: red[50], cursor: "pointer" },
  new: { backgroundColor: grey[50], cursor: "pointer" },
});

  // { id: 'articleId', numeric: true, disablePadding: false, label: 'Artikelnummer' },
  // { id: 'createdAt', numeric: true, disablePadding: false, label: 'Erstellt am' },
  // { id: 'createdBy', numeric: true, disablePadding: false, label: 'Erstellt von' },
  // { id: 'createdAt', numeric: true, disablePadding: false, label: 'Termin' },
  // { id: 'country', numeric: true, disablePadding: false, label: 'Land' },

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    // selected: [],
    data: [],
    // data: this.props.orders.map(order => createData(order.name, order.articleId, order.createdAt, order.createdBy, order.country)),
    // [
    //   createData('Cupcake', 305, 3.7, 67, 4.3),
    //   createData('Donut', 452, 25.0, 51, 4.9),
    //   createData('Eclair', 262, 16.0, 24, 6.0),
    // ],
    page: 0,
    rowsPerPage: 10,
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.orders){
      const data = nextProps.orders.map(order => createData(order._id, order.name, order.articleId, order.gtin, order.createdAt, order.createdBy, order.country, order.status));
      this.setState({data});
    }
  }

  componentDidMount(){
    let rowsPerPage = parseInt(localStorage.getItem("rowsPerPage"),10);
    if(rowsPerPage)
      this.setState({rowsPerPage});
  }

  handleRequestSort = (event, property) => {
    // console.log(event, property);
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  // handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     this.setState(state => ({ selected: state.data.map(n => n.id) }));
  //     return;
  //   }
  //   this.setState({ selected: [] });
  // };

  // handleClick = (event, id) => {
  //   const { selected } = this.state;
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   this.setState({ selected: newSelected });
  // };

  handleRowClick = (id) => {
    this.props.history.push(`/order/${id}/0`);
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
    localStorage.setItem("rowsPerPage", event.target.value)
  };

  // isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, showAll } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const data = this.props.orders.map(order => createData(order._id, order.name, order.articleId, order.gtin, order.createdAt, order.createdBy, order.country, order.status));
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    if(data.length === 0)
      return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <p>Keine Aufträge vorhanden</p>
        </div>
      </Paper>
      )

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              showAll={showAll}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={n.id}
                      className={classes[n.status]}
                      onClick={(id) => this.handleRowClick(n.id)}
                    >
                      <TableCell padding="checkbox">
                        &nbsp;
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {n.name}
                      </TableCell>
                      <TableCell align="left">{n.articleId}</TableCell>
                      <TableCell align="left">{n.gtin}</TableCell>
                      <TableCell align="left"><Moment format="DD.MM.YYYY HH:mm" date={n.createdAt} /></TableCell>
                      <TableCell align="left">{n.createdBy}</TableCell>
                      <TableCell align="left">{n.country.split(",").map(c => (<Flag country={c} key={c}/>))}</TableCell>
                      { showAll && <TableCell align="left">
                        {n.status === "accepted" && <CheckIcon/>}
                        {n.status === "rejected" && <NotInterestedIcon/>}
                        {n.status === "pending" && <MoreHorizIcon/>}
                        {n.status === "new" && "Neu"}

                      </TableCell>}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Aufträge pro Seite"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} von ${count}`}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const OrderTable = withRouter(withStyles(styles)(EnhancedTable));

export {OrderTable}