import "isomorphic-fetch";
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
// import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';

// LOCAL
import { Footer } from '../../components/Footer';

import Logo from '../../img/logo.png';

const styles = theme => ({
  ...theme,
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'url(https://jde-mediabox.com/img)',
  },
  card: {
    ...theme.mixins.gutters(),
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing.unit * 2,
    float: 'none',
    minWidth: 300,
    maxWidth: 360,
    
    [theme.breakpoints.down('sm')]: {
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '3em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '6em',
      marginBottom: 60,
    },
    zIndex: 2,
  },
  stepper: {
    width: '100%',
  },
  label: {
    fontSize: '11px'
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  media: {
    height: 140,
    width: 140,
    margin: '0 auto',
    marginTop: 10
  },
  formControl: {
    // margin: theme.spacing.unit,
    width: '100%'
  },
  buttons: {
    // padding: 20,
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1em',
    },
  },
  text: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    textAlign: 'center',
  },
  actions: {
    display: 'inline-block'
  },
  spinner: {
    position: 'absolute',
    marginLeft: '20%'
  },
  successText: {
    textAlign: 'center',
    paddingBottom: '2em'
  }
});

// function getSteps() {
//   return ['E-Mail & Passwort', 'Persönliche Details', 'Nutzungs-bedingungen', 'Abschluss'];
// }

const isEmail = (email = null) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

const isEmailAvailable = (email) => {
  if(isEmail(email))
    return fetch('https://api.jde-mediabox.com/user/check/', {
        method: 'POST',
        body: JSON.stringify({email}),
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
    })
    .then(response => response.json())
    .then(response => response);
}

// const strong = (password = null) => {
//   const regex = /^.{8,}$/;
//   return regex.test(password);
// }

function createUser(user) {
  let userData = user;
  userData.contactPerson = user.jde ? "JDE" : user.contactPerson;
  return fetch('https://api.jde-mediabox.com/user/', {
    method: 'PUT',
    body: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  })
  .then(response => response.json())
}

class HorizontalLabelPositionBelowStepper extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  
    this.state = {
      title: 'Signup',
      activeStep: 0,
      form: {
        email: '',
        password: '',
        passwordRepeat: '',
        company: '',
        firstname: '',
        lastname: '',
        phone: '',
        contactPerson: '',
        tou: false,
      },
      error: {
        email: '',
        password: '',
        passwordRepeat: '',
        company: '',
        firstname: '',
        lastname: '',
        contactPerson: '',
        tou: '',
      },
      emailValid: true,
      emailAvailable: true,
      passwordValid: true,
      passwordRepeatValid: true,
      companyValid: true,
      firstnameValid: true,
      lastnameValid: true,
      touValid: false,
      contactPersonValid: true,

      formValid: [false, false, false],

      isLoading: false,
      jde: false,
    }
  }

  componentDidMount() {
    document.title = `JDE mediaBOX | ${this.props.translate("signup.subtitle")}`;
  }

  handleNext = () => {
    const { activeStep } = this.state;
    // const steps = getSteps();
    this.setState({
      isLoading: true,
      error: {
        email: '',
        password: '',
        passwordRepeat: '',
      }
    })
    this.setState({ isLoading: false, activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      form: {},
      error: {}
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { activeStep } = this.state;
    this.setState({ isLoading: true })
    createUser({ ...this.state.form, lang: this.props.lang, jde: this.state.jde})
    .then(json => {
      if(json.success)
        this.setState({ isLoading: false, activeStep: activeStep + 1 });
    })
    .catch(error => {
      // console.log(error);
    });
  }

  async validateField(fieldName, value) {
    let fieldValidationErrors = this.state.error;
    let { activeStep, emailValid, emailAvailable, passwordValid, passwordRepeatValid, companyValid, firstnameValid, lastnameValid, touValid, formValid, contactPersonValid, form, jde } = this.state;
    const { translate } = this.props;

    switch(fieldName) {
      case 'email':
        emailAvailable = await isEmailAvailable(value);
        emailValid = isEmail(value);
        contactPersonValid = value.toLowerCase().indexOf("@jdecoffee.com") > 0;
        jde = value.toLowerCase().indexOf("@jdecoffee.com") > 0;

        fieldValidationErrors.email = !emailValid ? translate("signup.step1.errors.wrongMail") : emailAvailable ? "" : translate("signup.step1.errors.mailExists");

        break;
      case 'password':
        passwordValid = value.length >= 8;
        fieldValidationErrors.password = passwordValid ? '': translate("signup.step1.errors.passMinChar");
        break;
      case 'passwordRepeat':
        passwordRepeatValid = (value === this.state.form.password);
        fieldValidationErrors.passwordRepeat = passwordRepeatValid ? '': translate("signup.step1.errors.passNotMatching");
        break;
      case 'firstname':
        firstnameValid = value.length>=2;
        fieldValidationErrors.firstname = firstnameValid ? '': translate("signup.step2.errors.required");
        break;
      case 'lastname':
        lastnameValid = value.length>=2;
        fieldValidationErrors.lastname = lastnameValid ? '': translate("signup.step2.errors.required");
        break;
      case 'company':
        companyValid = value.length>=2;
        fieldValidationErrors.company = companyValid ? '': translate("signup.step2.errors.required");
        break;
      case 'contactPerson':
        contactPersonValid = value.length>=2;
        fieldValidationErrors.contactPerson = contactPersonValid ? '': translate("signup.step3.errors.required");
        break;
      case 'tou':
        touValid = value; // checked
        fieldValidationErrors.tou = touValid ? '': translate("signup.step3.errors.accept");
        break;
      default:
        break;
    }
    var thisStep = formValid;
    if(activeStep === 0)
      thisStep[activeStep] = (emailValid && emailAvailable && passwordValid && passwordRepeatValid && form.email.length > 0 && form.password.length > 0 && form.passwordRepeat.length > 0);
    if(activeStep === 1)
      thisStep[activeStep] = (companyValid && firstnameValid && lastnameValid && form.company.length >=2 && form.firstname.length >=2 && form.lastname.length >=2)
    if(activeStep === 2)
      thisStep[activeStep] = (contactPersonValid && touValid); //  && form.contactPerson.length >= 2

    this.setState({ error: fieldValidationErrors,
                    emailValid,
                    emailAvailable,
                    passwordValid,
                    passwordRepeatValid,
                    firstnameValid,
                    lastnameValid,
                    companyValid,
                    contactPersonValid,
                    touValid,
                    formValid: thisStep,
                    jde
                  });
                  // }, this.validateForm(activeStep));
  }

  handleChange = event => {
    const { form } = this.state;
    let { name, value } = event.target;

    if(name === 'tou')
      value = event.target.checked;
    this.setState({
      form:{ ...form, [name]: value }},
      () => { this.validateField(name, value) }); 
  };

  render() {
    const { classes, translate } = this.props;
    // const steps = getSteps();
    const steps = [
      translate("signup.step1.title"),
      translate("signup.step2.title"),
      translate("signup.step3.title"),
      translate("signup.step4.title")
    ]
    const {
      activeStep,
      error,
      isLoading,
      emailValid, emailAvailable, passwordValid, passwordRepeatValid, formValid, firstnameValid, lastnameValid, companyValid, contactPersonValid
    } = this.state;
    const { email, password, passwordRepeat, company, firstname, lastname, phone, contactPerson } = this.state.form;

    return (
      <div className={classes.root}>
        <Card className={classes.card} elevation={1}>
          <div className={classes.stepper}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map(label => {
                return (
                  <Step key={label}>
                    <StepLabel classes={{label: classes.label}}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            <CardMedia
              className={classes.media}
              image={Logo}
              title="JDE mediaBOX"
            />
            <Typography className={classes.text} variant="h5" component="h3">
              { activeStep !== 3 ? translate("signup.subtitle") : translate("signup.step4.subtitle") }
            </Typography>

            <div>
              {activeStep === steps.length-1 ? (
                <div>
                  <Typography className={classes.successText}>
                    {translate("signup.step4.text")}
                  </Typography>
                  {/*<Button onClick={this.handleReset}>Zurücksetzen</Button>*/}
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/login"
                    fullWidth
                  >
                  {translate("signup.loginLink")}
                  </Button>
                </div>
              ) : (
                <div>
                  <form onSubmit={this.handleSubmit}>
                    { (activeStep === 0) && 
                      <div>
                        <TextField
                          error={!emailValid || !emailAvailable}
                          name="email"
                          label={translate("signup.step1.email")}
                          disabled={isLoading}
                          className={classes.formControl}
                          value={email}
                          onChange={this.handleChange.bind(this)}
                          margin="normal"
                          autoComplete="username"
                          type="email"
                          helperText={error.email}
                        />
                        <TextField
                          error={!passwordValid}
                          name="password"
                          label={translate("signup.step1.password")}
                          disabled={isLoading}
                          className={classes.formControl}
                          value={password}
                          onChange={this.handleChange.bind(this)}
                          margin="normal"
                          autoComplete="new-password"
                          type="password"
                          helperText={error.password}
                        />
                        <TextField
                          error={!passwordRepeatValid}
                          name="passwordRepeat"
                          label={translate("signup.step1.passwordRepeat")}
                          disabled={isLoading}
                          className={classes.formControl}
                          value={passwordRepeat}
                          onChange={this.handleChange.bind(this)}
                          margin="normal"
                          autoComplete="new-password"
                          type="password"
                          helperText={error.passwordRepeat}
                        />
                      </div>
                    }
                    { (activeStep === 1) && 
                      <div>
                        <TextField
                          error={!companyValid}
                          name="company"
                          label={translate("signup.step2.company")}
                          disabled={isLoading}
                          className={classes.formControl}
                          value={company}
                          onChange={this.handleChange.bind(this)}
                          margin="normal"
                          helperText={error.company}
                        />
                        <TextField
                          error={!lastnameValid}
                          name="lastname"
                          label={translate("signup.step2.lastname")}
                          disabled={isLoading}
                          className={classes.formControl}
                          value={lastname}
                          onChange={this.handleChange.bind(this)}
                          margin="normal"
                          autoComplete="family-name"
                          helperText={error.lastname}
                        />
                        <TextField
                          error={!firstnameValid}
                          name="firstname"
                          label={translate("signup.step2.firstname")}
                          disabled={isLoading}
                          className={classes.formControl}
                          value={firstname}
                          onChange={this.handleChange.bind(this)}
                          margin="normal"
                          autoComplete="given-name"
                          helperText={error.firstname}
                        />
                        <TextField
                          name="phone"
                          label={translate("signup.step2.phone")}
                          disabled={isLoading}
                          className={classes.formControl}
                          value={phone}
                          onChange={this.handleChange.bind(this)}
                          margin="normal"
                          autoComplete="tel"
                        />
                      </div>
                    }
                    { (activeStep === 2) && 
                      <div>
                      {!this.state.jde && 
                        <TextField
                          error={!contactPersonValid}
                          name="contactPerson"
                          label={translate("signup.step3.contact")}
                          disabled={isLoading}
                          className={classes.formControl}
                          value={contactPerson}
                          onChange={this.handleChange.bind(this)}
                          margin="normal"
                          autoComplete="off"
                          helperText={error.contactPerson}
                        />
                      }
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.form.tou}
                              onChange={this.handleChange.bind(this)}
                              value="tou"
                              color="primary"
                              name="tou"
                            />
                          }
                          label={translate("signup.step3.accept")}
                        />
                      </div>
                    }
                    <div className={classes.buttons}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.backButton}
                      >
                        {translate("signup.backBtn")}
                      </Button>
                      <Button
                        disabled={(!formValid[activeStep] || isLoading)}
                        variant="contained"
                        color="primary"
                        onClick={activeStep === steps.length - 2 ? this.handleSubmit : this.handleNext}
                      >
                        {activeStep === steps.length - 2 ? translate("login.signupBtn") : isLoading ? <span><CircularProgress className={classes.spinner} color="secondary" size={20} thickness={2} /> {translate("signup.nextBtn")}</span> : translate("signup.nextBtn")}
                      </Button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
          <CardActions className={classes.actions}>
            { activeStep !== 3 && <Typography>{translate("signup.call2login")} <Link to="/login">{translate("signup.loginLink")}</Link></Typography>}
          </CardActions>
          <div>&nbsp;</div>
        </Card>
        <Footer layout="static"/>
      </div>
    );
  }
}

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    lang: state.lang,
  }
}
const Signup = connect(mapStateToProps)(withLocalize(withStyles(styles)(HorizontalLabelPositionBelowStepper)));

export { Signup }