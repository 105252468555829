import React, { Component } from 'react';
import { withRouter } from 'react-router'
// import queryString from 'query-string'
import { withLocalize } from "react-localize-redux";
import { compose } from 'redux';

// REDUX
import { connect } from 'react-redux';
import { searchFetchData } from '../../_actions/search.actions';

// MUI
import Grid from '@material-ui/core/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress';

// import './style.css'
import { GridView } from '../../components/GridView';
import { ListView } from '../../components/ListView';
import { Statusbar } from '../../components/Statusbar';
import { Headline } from '../../components/Headline';

class SearchView extends Component {
  // constructor(props){
  //   super(props);
  // }

  componentDidMount(){
    // const values = queryString.parse(this.props.location.search)
    const { query } = this.props.match.params;
    const { rootId } = this.props;
    this.props.fetchData({query, rootId});
    document.title = `JDE mediaBOX | ${this.props.translate("search.title")}`;
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.searchString !== this.props.searchString && nextProps.searchString !== ""){
      const { rootId } = this.props;
      this.props.fetchData({ query: nextProps.searchString, rootId });
    }
  }
  
  render(){
    // const values = queryString.parse(this.props.location.search)
    const { isLoading, results, view, searchString, searchHasErrored, searchFilter, translate, rootId } = this.props;
    // const { query } = this.props.match.params;
  
    let displayedResults = results.filter((item) => {
      return searchFilter.includes(item.rootId) && parseInt(item.countryId) === rootId; 
    })
    const displayedNumber = displayedResults.length;

    const statusText = (displayedNumber > 0 && !searchHasErrored) ? `${displayedNumber} ${translate("search.results")} ${searchString}` : isLoading ? translate("search.loading") : translate("search.noresults");

    return (
      <div className="root">
        <Grid container style={{flexGrow: 1}} spacing={16} >
          <Headline>{translate("search.title")}</Headline>
          <Statusbar statusText={statusText} />
          {
            searchHasErrored ?
            null :
            (view === "grid") ?
            <GridView assets={displayedResults} />
            :
            <ListView assets={displayedResults} />
          }
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    results: state.search.results,
    // numberTotal: state.search.numberTotal,
    hasErrored: state.searchHasErrored,
    isLoading: state.searchIsLoading,
    searchString: state.searchString,
    searchHasErrored: state.searchHasErrored,
    view: state.view,
    rootId: state.rootId,
    searchFilter: state.searchFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (q) => dispatch(searchFetchData(q)) // add rootId
  };
};

const Search = compose(
  withLocalize,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchView)

export { Search }
