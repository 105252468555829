// import React from 'react';
import { withLocalize } from "react-localize-redux";


function AssetNameComponent(props){
  const { translate } = props;
  const chunks = props.children.split(' ') || props.name;
  const newName = chunks.map(c => {
    const t = translate(`assetName.${String(c).toLowerCase()}`);
    if(t !== 'No default translation found! Ensure you\'ve added translations for your default langauge.')
      return t;
    else return c;
  })
  return newName.join(' ').replace('@next','');
}

const AssetName = withLocalize(AssetNameComponent);
export { AssetName }