import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tilt from 'react-tilt'

const styles = {
  card: {
    minWidth: 280,
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  media: {
    height: 140,
    textDecoration: 'none',
    color: 'inherit',
  },
};


class RootCardView extends Component {
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(id) {
    console.log(id);
    // this.refs.menu.changeActiveLinkId(id);
  }

  render() {

    
    const { classes, node, lang } = this.props;
    const title = node.name[lang] ? node.name[lang] : node.name["de"];
    return (
      <Grid key={node.id} item>
        <Tilt options={{ max : 20, scale: 1.03 }} style={{ minWidth: 280 }} >
          <Card className={classes.card} onClick={() => this.handleClick(node.id)}>
            <CardMedia
              className={classes.media}
              image={`/images/${node.id}.jpg`}
              title={title}
              href="/"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {title.replace(/#?([0-9]{3})\s/,"")}
              </Typography>
            </CardContent>
    {/*        <CardActions>
              <Button size="small" color="primary">
                Details
              </Button>
              <Button size="small" color="primary">
                Button
              </Button>
            </CardActions>*/}
          </Card>
        </Tilt>
      </Grid>
    );
  }
}

RootCardView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    lang: state.lang,
    // metisMenuStore: state.metisMenuStore,
  }
}

const RootCard = connect(mapStateToProps)(withStyles(styles)(RootCardView))

export { RootCard }