import { userService } from '../_services/user.service';

export function handleResponse(response) {
  return response.text().then(text => {
    let data = text && JSON.parse(text);
    if(data.data)
      data = data.data;
    // console.log(response);
    // console.log(data);
    if (!response.ok) {
      if(response.status === 418)
        localStorage.removeItem('user');
      if (response.status >= 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        // window.location.reload(true);
      }
      // const error = (data && data.message) || response.statusText;
      const error = data.message ? data.message : response.statusText ? response.statusText : response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}