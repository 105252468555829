import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { withRouter } from 'react-router'
import { compose } from 'redux'

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import MenuItem from '@material-ui/core/MenuItem';


// import { TextField, SelectField } from 'redux-form-material-ui';

// REDUX
import { connect } from 'react-redux';
import { orderActions } from "../../_actions";

import { Headline } from '../../components/Headline';

import { OrderAddForm } from '../../components/OrderAddForm';
// import { SnackBar } from '../../components/SnackBar';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    margin: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    width: '25%',
    display: 'flex',
  },
});



class AddOrder extends Component {
  render(){
    const { classes } = this.props;
    const values = this.props.form.orderForm ? this.props.form.orderForm.values : {};
    // const { id } = this.props.match.params;
    return(
      <div className={classes.root}>
        <Headline>Auftrag erstellen</Headline>
        <Paper className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <OrderAddForm addOne={this.props.addOne} order={values} orderResponse={this.props.orderResponse}/>
            </Grid>
          </Grid>
        </Paper>
      </div>
          
    )
  }
}


const mapStateToProps = state => {
  return {
    lang: state.lang,
    form: state.form,
    orderResponse: state.order
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addOne: (order) => dispatch(orderActions.addOne(order))
  };
};

AddOrder = compose(
 withRouter,
 withLocalize,
 withStyles(styles),
 connect(mapStateToProps, mapDispatchToProps)
)(AddOrder);

export { AddOrder }