import "isomorphic-fetch";
// import axios from 'axios';
import {
  // endpoint,
  // devheaders,
  devendpoint
} from '../api';
import { messageConstants } from '../_constants';
import { history } from '../_helpers';

function messageHasErrored(bool) {
  return {
    type: messageConstants.MESSAGE_HAS_ERRORED,
    hasErrored: bool
  };
}

function messageIsLoading(bool) {
  return {
    type: messageConstants.MESSAGE_IS_LOADING,
    isLoading: bool
  };
}

function messageFetchDataSuccess(messages) {
  return {
    type: messageConstants.MESSAGE_FETCH_DATA_SUCCESS,
    messages
  };
}

function messageAddSuccess(message) {
  return {
    type: messageConstants.MESSAGE_ADD,
    message
  };
}

export function messageAdd(message) {
  return (dispatch) => {
    dispatch(messageIsLoading(true));
    // fetch
    dispatch(messageAddSuccess(message));
    dispatch(messageIsLoading(false));
    return message;
  }
}

export function messageGetAll() {
  return (dispatch) => {
    dispatch(messageIsLoading(true));
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',  
      'Authorization': `Bearer ${user.token}`
    }
    fetch(`${devendpoint}/messages/`,{
      method: 'GET',
      headers
    })
    .then((response) => {
      if (!response.ok) {
        if(response.status === 403)
          history.push('/login');
        throw Error(response.statusText);
      }
      dispatch(messageIsLoading(false));
      return response;
    })
    .then((response) => response.json())
    .then((messages) => dispatch(messageFetchDataSuccess(messages)))
    .catch(() => dispatch(messageHasErrored(true)));
  };
}