import { assetsConstants } from '../_constants'
export function assetsHasErrored(state = false, action) {
    switch (action.type) {
        case assetsConstants.ASSETS_HAS_ERRORED:
            return action.hasErrored;
        default:
            return state;
    }
}
export function assetsIsLoading(state = false, action) {
    switch (action.type) {
        case assetsConstants.ASSETS_IS_LOADING:
            return action.isLoading;
        default:
            return state;
    }
}

export function assets(state = [], action) {
    switch (action.type) {
        case assetsConstants.ASSETS_FETCH_DATA_SUCCESS:
            return action.assets;
        default:
            return state;
    }
}