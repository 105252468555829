import React, { Component } from 'react';
// import { reduxForm, Field } from 'redux-form'
import { withLocalize } from "react-localize-redux";
import { compose } from 'redux';

import { withRouter } from 'react-router';


import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Badge from '@material-ui/core/Badge';

// import MoreIcon from '@material-ui/icons/MoreHoriz';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import DoNotDisturbIcon from '@material-ui/icons/DoNotDisturb';


// REDUX
import { connect } from 'react-redux';
import { orderActions } from "../../_actions";

// Components
import { Headline } from '../../components/Headline';
import { OrderTable } from '../../components/OrderTable';
// import { SnackBar } from '../../components/SnackBar';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    // marginTop: -20
  },
  badge: {
    top: '45%',
    right: -13,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
});

function TabContainer(props) {
  // const { classes } = props;
  return (
    <div>{props.children}</div>
  )
}

class Orders extends Component {
  // constructor(props){
  //   super(props);
  //   // this.onHover = this.onHover.bind(this);
  // }

  handleChange = (event, value) => {
    this.props.history.push('/orders/'+(value+1));
  };

  componentDidMount() {
    this.props.getAll();
  }

  render(){
    const { classes, order, isSuperUser } = this.props;
    const step = parseInt(this.props.match.params.step-1) || 0;
    const orders = order && order.orders && order.orders.length ? order.orders.filter(o => o.active) : [];

    const length = (status) => {
      return status === "" ? orders.filter(o => o.status !== "completed").length : orders.filter(o => o.status === status).length;
    }



    return(
      <div className={classes.root}>
        <Headline>Aufträge</Headline>
        <Paper className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <AppBar position="static" className={classes.appBar}>
                <Tabs value={step} onChange={this.handleChange}>
                  <Tab label={<Badge badgeContent={length("new")} color="primary" classes={{ badge: classes.badge }}><span>In Bearbeitung</span></Badge>} />
                  <Tab label={<Badge badgeContent={length("pending")} color="primary" classes={{ badge: classes.badge }}><span>Bereit zur Abnahme</span></Badge>} />
                  <Tab label={<Badge badgeContent={length("rejected")} color="primary" classes={{ badge: classes.badge }}><span>Abgelehnt</span></Badge>} />
                  <Tab label={<Badge badgeContent={length("accepted")} color="primary" classes={{ badge: classes.badge }}><span>Akzeptiert</span></Badge>} />
                  <Tab label={<Badge badgeContent={length("")} color="primary" classes={{ badge: classes.badge }}><span>Alle anzeigen</span></Badge>} />
                  { isSuperUser && <Tab label="Fertiggestellt" /> }
                </Tabs>
              </AppBar>
              {step === 0 &&
                <TabContainer>
                  <OrderTable
                    orders={orders.filter(o => o.status === "new" )}
                  />
                </TabContainer>}
              {step === 1 &&
                <TabContainer>
                  <OrderTable
                    orders={orders.filter(o => o.status === "pending" )}
                  />
                </TabContainer>}
              {step === 2 &&
                <TabContainer>
                  <OrderTable
                    orders={orders.filter(o => o.status === "rejected" )}
                  />
                </TabContainer>}
              {step === 3 &&
                <TabContainer>
                  <OrderTable
                    orders={orders.filter(o => o.status === "accepted" )}
                  />
                </TabContainer>}
              {step === 4 &&
                <TabContainer>
                  <OrderTable
                    orders={orders.filter(o => o.status !== "completed" )}
                    showAll={true}
                  />
                </TabContainer>}
              {(step === 5 && isSuperUser) &&
                <TabContainer>
                  <OrderTable
                    orders={orders.filter(o => o.status === "completed" )}
                  />
                </TabContainer>}
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang,
    order: (state.order && state.order.length > 0) ? state.order : [],
    isSuperUser: state.authentication.user.isSuperUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAll: () => dispatch(orderActions.getAll()),
  };
};

Orders = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalize,
  withRouter,
  withStyles(styles),
)(Orders);

export { Orders }