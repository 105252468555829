import { snackConstants } from '../_constants';

export const snackActions = {
  show,
  clear
};

function show(msg, variant) {
  return dispatch => {
    dispatch(success({msg, variant, open: true }));
  };
  function success(snack) { return { type: snackConstants.SNACK_SHOW, snack } }
}

function clear(variant) {
  return dispatch => {
    dispatch(success(variant));
  };
  function success(variant) { return { type: snackConstants.SNACK_CLEAR, variant } }
}
