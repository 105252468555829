import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize, Translate } from "react-localize-redux";
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

import Icon from '@material-ui/core/Icon';

import Moment from 'react-moment';
import 'moment-timezone';

import './Timeline.css';

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  stepLabel: {
    cursor: "pointer"
  }
});

const getIcon = (action) => {
  const lt = {
    "CREATED": "add_circle",
    "ACCEPTED": "check_circle",
    "REJECTED": "cancel",
    "IMAGE_ADDED": "image",
    "FILE_ADDED": "attach_file",
    "IMAGE_REMOVED": "delete",
    "FILE_REMOVED": "delete",
    "CHANGED": "edit",
    "COMPLETED": "check",
  }
  return lt[action] ? lt[action] : "info";
}

const formatContext = (context) => {
  if(context.length <= 1)
    return context[0];
  else{
    var str = "<span>";
    context.forEach((c,i) =>{
      let d = i === context.length-1 ? "" : " &"
      str+=`<p>${c}${d}</p>`;
    });
    str+="</span>";
    return str;
  }
}

class VerticalLinearStepper extends React.Component {
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  state = {
    activeStep: 0,
  };

  handleClick = (index) => {
    this.setState({ activeStep: index });
  };

  render() {
    const { classes, order } = this.props;
    let steps = [];
    if(order && order.history)
      steps = [
        {
          action: "CREATED",
          username: order.username,
          createdAt: order.createdAt,
        },
        ...order.history] || [];

    const { activeStep } = this.state;

    var arr2 = steps.reduce( (a,b) => {
        var i = a.findIndex( x => x.createdAt.substr(0,x.createdAt.length-5) === b.createdAt.substr(0,b.createdAt.length-5));
        if(i > 0 && a[i] && a[i].context)
          a[i].context.push(b.context);
        return i === -1 ? a.push({
          createdAt : b.createdAt,
          action : b.action,
          username : b.username,
          context: b.context ? [b.context] : [],
          // eslint-disable-next-line
          times : 1 }) : a[i].times++, a;
    }, []);

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {arr2.map((h, index) => (
            <Step key={index} completed>
              <StepLabel
                icon={<Icon className={classes.icon} color="primary" >{getIcon(h.action)}</Icon>}
                onMouseOver={() => this.handleClick(index)}
                className={classes.stepLabel}
              >
                <Translate id={getIcon(h.action) === "info" ? `order.history.title.INFO` : `order.history.title.${h.action}${h.times > 1 ? "_plural" : ""}`} data={h} />
                
                <Typography variant="caption">
                  <Moment format="DD.MM.YYYY HH:mm" date={h.createdAt}/>
                </Typography>
              </StepLabel>

              <StepContent>
                <Typography>
                  <Translate id={getIcon(h.action) === "info" ? `order.history.content.INFO` : `order.history.content.${h.action}`} data={{...h, context: formatContext(h.context) }} options={{ renderInnerHtml: true }} />
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

const Timeline = withLocalize(withStyles(styles)(VerticalLinearStepper));

export { Timeline }