import { combineReducers } from 'redux';
import { nodes, nodesHasErrored, nodesIsLoading, rootId } from './nodes.reducer';
import { assets, assetsHasErrored, assetsIsLoading } from './assets.reducer';
import { asset, assetHasErrored, assetIsLoading } from './asset.reducer';
import { message } from './message.reducer';
import { order } from './order.reducer';
import { snack } from './snack.reducer';
import { downloads, downloadHasErrored, downloadIsLoading } from './download.reducer';
import { search, searchString, searchHasErrored, searchIsLoading, searchFilter } from './search.reducer';
import { cart, cartHasErrored, cartIsLoading, cartCheckboxState, delItemFromCart, cartDownloadIsLoading, cartDownloadListIsLoading, cartSent } from './cart.reducer';
import { user } from './user.reducer';
import { view, lang, country, container, showSearch, searchActive, cartQuickViewOpen } from './ui.reducer';
import { authentication } from './authentication.reducer';
import metisMenuReducer from 'react-metismenu/lib/reducers';
import { localizeReducer } from "react-localize-redux";
import { reducer as formReducer } from 'redux-form';
export default combineReducers({
  authentication,
  // asset,
  user,

  nodes,
  rootId,
  nodesHasErrored,
  nodesIsLoading,

  assets,
  assetsHasErrored,
  assetsIsLoading,

  asset,
  assetHasErrored,
  assetIsLoading,

  search,
  searchString,
  searchHasErrored,
  searchIsLoading,
  searchFilter,

  cart,
  cartSent,
  cartHasErrored,
  cartIsLoading,
  delItemFromCart,
  cartDownloadIsLoading,
  cartDownloadListIsLoading,
  cartCheckboxState,

  downloads,
  downloadHasErrored,
  downloadIsLoading,

  message,
  order,

  view,
  lang,
  country,
  container,
  showSearch,
  searchActive,
  cartQuickViewOpen,
  snack,
  
  metisMenuStore: metisMenuReducer,
  form: formReducer,
  localize: localizeReducer,
});
