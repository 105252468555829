import React from 'react';
import Dropzone from 'react-dropzone';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { withLocalize } from "react-localize-redux";

const style = {
  main: {
    width: "100%",
    minHeight: 150,
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 5,
    textAlign: 'center'
  },
  dropzone: {
    width: "100%",
    minHeight: 150,
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  icon: {
    fontSize: '5em',
    color: '#666'
  }
}

class DropzoneWithPreview extends React.Component {
  constructor() {
    super()
    this.state = {
      files: []
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    // console.log(files);
    const { input } = this.props;
    this.setState({
      files: files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    });
    input.onChange(files);
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
  }

  render() {
    const { translate } = this.props;
    const { files } = this.state;
    const re = /(?:\.([^.]+))?$/;


    const thumbs = files.map(file => {
      const ext = re.exec(file.name)[1];
      return (
          <div style={style.thumb} key={file.name}>
            <div style={style.thumbInner}>
              <img
                src={file.type.indexOf("image/") >= 0 ? file.preview : "/images/mimetypes/"+ext+".png"}
                alt={file.name}
                style={style.img}
              />
            </div>
          </div>
        )
    });

    return (
      <section style={style.main}>
        <Dropzone
          accept="image/*,application/*"
          // onDrop={this.onDrop.bind(this)}
          onDrop={(filesToUpload) => this.onDrop(filesToUpload)}
        >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} style={style.dropzone}>
              <input {...getInputProps()} />
              <CloudUpload style={style.icon}/>
              <p>{translate("order.edit.tabs.files.dropzone")}</p>
            </div>
          )}
        </Dropzone>
        <aside style={style.thumbsContainer}>
          {thumbs}
        </aside>
      </section>
    );
  }
}


export default withLocalize(DropzoneWithPreview);