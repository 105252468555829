import "isomorphic-fetch";
import ReactPiwik from 'react-piwik';
// import { endpoint, headers } from '../api';
import { userConstants } from '../_constants';
import { userService } from '../_services';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    getInfo,
    setInfo
    // getAll
};

// export function userHasErrored(bool) {
//   return {
//     type: userConstants.LOGIN_ERRORED,
//     hasErrored: bool
//   };
// }

// export function userIsLoading(bool) {
//   return {
//     type: userConstants.LOGIN_IS_LOADING,
//     isLoading: bool
//   };
// }

// export function userFetchDataSuccess(user) {
//   return {
//     type: userConstants.LOGIN_SUCCESS,
//     user
//   };
// }

// export function userFetchData(q) {
//   return (dispatch) => {
//     dispatch(userIsLoading(true));
//     fetch(`${endpoint}/Assets?$user="${q}"`,{
//       method: 'GET',
//       headers
//     })
//     .then((response) => {
//       if (!response.ok) {
//         throw Error(response.statusText);
//       }
//       dispatch(userIsLoading(false));
//       return response;
//     })
//     .then((response) => response.json())
//     .then((user) => dispatch(userFetchDataSuccess(user)))
//     .catch(() => dispatch(userHasErrored(true)));
//   };
// }

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));
    userService.login(username, password)
    .then(
      user => { 
        dispatch(success(user));
        ReactPiwik.push(['setUserId', username]);
        history.push('/');
      },
      error => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

// export function getInfoCreator(user) { return { type: userConstants.INFO_SUCCESS, user } }

function getInfo() {
  return dispatch => {
    dispatch(request());
    userService.getInfo()
    .then(
      user => {
        dispatch(success(user.data.user));
      },
      error => {
        dispatch(failure(error));
      }
    );
  }
  function request() { return { type: userConstants.INFO_REQUEST } }
  function success(user) { return { type: userConstants.INFO_SUCCESS, user } }
  function failure(error) { return { type: userConstants.INFO_FAILURE, error } }  
}

function setInfo(user) {
  return dispatch => {
    dispatch(request(user));
    dispatch(success(user));
    // userService.setInfo(user)
    // .then(
    //   user => {
    //     dispatch(success(user.data.user));
    //   },
    //   error => {
    //     dispatch(failure(error));
    //   }
    // );
  }
  function request(user) { return { type: userConstants.SET_INFO_REQUEST, user } }
  function success(user) { return { type: userConstants.SET_INFO_SUCCESS, user } }
  // function failure(error) { return { type: userConstants.SET_INFO_FAILURE, error } }  
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}