import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// MUI
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// MUI Icons
import RemoveIcon from '@material-ui/icons/Remove';

// REDUX
import { connect } from "react-redux";
import {
  cartAddOneFormat,
  cartRemoveOneFormat,
  cartRemoveOne,
} from '../../_actions';

// LOCAL
import { DownloadButton } from '../DownloadButton';
import { AssetName } from '../AssetName';


class CartTableRowComponent extends Component {

  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChange(item, e) {
    if(e.target.checked)
      this.props.cartAddOneFormat(item.id, e.target.name);
    else
      this.props.cartRemoveOneFormat(item.id, e.target.name);
  }  

  handleDelete(id) {
    this.props.cartRemoveOne(id);
  }

  render(){
    const { item, lang, isLoading } = this.props;
  
    return (
      <TableRow key={item.id}>
        <TableCell>
          <IconButton onClick={() => this.handleDelete(item.id)} disabled={isLoading}>
            <RemoveIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell>
          <Link to={`/asset/${item.id}`} style={{ textDecoration: "none", color: "inherit"}}>
            <AssetName>{item.name}</AssetName>  
          </Link>
        </TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Checkbox
                name={String(item.mediaType)}
                onChange={ (e) => this.handleChange(item,e)}
                color="primary"
                checked={!!(this.props.cartCheckboxState[`${item.id}.${item.mediaType}`])}
                disabled={this.props.isLoading}                
              />
            }

            label={item[`mediaTypeName_${lang}`]}
          />
          <DownloadButton link={item.originalTicket} />
        </TableCell>
        
        <TableCell>
        { Object.keys(item.relatedAsset).length > 0 &&
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  name={String(item.relatedAsset.mediaType)}
                  onChange={ (e) => this.handleChange(item,e)}
                  color="primary"
                  checked={!!(this.props.cartCheckboxState[`${item.id}.${item.relatedAsset.mediaType}`])}
                  disabled={this.props.isLoading}
                />
              }
              label={item.relatedAsset[`mediaTypeName_${lang}`]}
            />
            <DownloadButton link={item.relatedAsset.originalTicket} />
          </div>
        }
        </TableCell>
        
      </TableRow>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang,
    isLoading: state.cartIsLoading,
    cartCheckboxState: state.cartCheckboxState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // cartAddAllFormat: (assetIds) => dispatch(cartAddAllFormat(assetIds)),
    cartAddOneFormat: (id, mediaType) => dispatch(cartAddOneFormat(id, mediaType)),
    cartRemoveOneFormat: (id, mediaType) => dispatch(cartRemoveOneFormat(id, mediaType)),
    cartRemoveOne: (id) => dispatch(cartRemoveOne(id)),
  };
};

const CartTableRow = connect(mapStateToProps, mapDispatchToProps)(CartTableRowComponent);

export { CartTableRow }

// checked={checked[`${item.id}.${item.mediaType}`]}