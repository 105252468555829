import { messageConstants } from '../_constants'

export function message(state = { messages:[] }, action) {
  switch (action.type) {
    case messageConstants.MESSAGE_FETCH_DATA_SUCCESS:
      return Object.assign({}, state, { messages: action.messages })
    case messageConstants.MESSAGE_IS_LOADING:
      return Object.assign({}, state, { isLoading: action.isLoading })
    case messageConstants.MESSAGE_HAS_ERRORED:
      return Object.assign({}, state, { hasErrored: action.hasErrored })
    case messageConstants.MESSAGE_ADD:
      return Object.assign({}, state, {
        messages: [
          ...state.messages,
          action.message
        ]
      })
    default:
      return state;
  }
}
