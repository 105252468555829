import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
// import { renderToStaticMarkup } from "react-dom/server";

// MUI
import { withStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
import CardHeader from '@material-ui/core/CardHeader';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// Components
import Logo from '../../img/logo.png';
import { Footer } from '../../components/Footer';

// Redux
// import { userActions } from '../../_actions';
import { uiSetLang } from '../../_actions/ui.actions';

const styles = theme => ({
  ...theme,
  root: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100vh',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'url(https://jde-mediabox.com/img)',
  },
  card: {
    ...theme.mixins.gutters(),
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing.unit * 2,
    float: 'none',
    minWidth: 300,
    maxWidth: 360,
    overflow: 'auto',    
    [theme.breakpoints.between('xs','sm')]: {
      marginTop: '0em',
      marginBottom: 60,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0em',
      // marginBottom: 60,
      marginBottom: 110,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '3em',
      marginBottom: 60,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '6em',
    },
    zIndex: 2,
  },
  formControl: {
    // margin: theme.spacing.unit,
    width: '100%'
  },
  button: {
    // marginTop: 20
  },
  regButton: {
    marginBottom: theme.spacing.unit*2
  },
  media: {
    height: 140,
    width: 140,
    margin: '0 auto',
    marginTop: 10
  },
  text: {
    textAlign: 'center',
    lineHeight: '1.4em',
  },
  header: {
    paddingRight: 0,
    height: 0,
  }
});

// const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

class ImprintView extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount(){
    document.title = `JDE mediaBOX | ${this.props.translate("imprint.title")}`;
    if(window.location.href.indexOf(".com") > 0 && !localStorage.getItem("lang")){
      this.props.setActiveLanguage("en");
      this.props.uiSetLang("en");
    }
    if(window.location.href.indexOf(".de") > 0 && !localStorage.getItem("lang")){
      this.props.setActiveLanguage("de");
      this.props.uiSetLang("de");
    }
  }

  render() {
    const { classes, translate, loggedIn } = this.props;
    return(
      <div className={classes.root}>
        <Card className={classes.card} elevation={1}>
          { loggedIn &&
          <CardHeader
            className={classes.header}
            action={
              <IconButton component={Link} to="/">
                <CloseIcon/>
              </IconButton>
            }
          />
          }
          <CardMedia
            className={classes.media}
            image={Logo}
            title="JDE mediaBOX"
          />
          <Typography className={classes.text} variant="h5" component="h3">
            {translate("imprint.title")}
          </Typography>
          <Typography variant="body2" className={classes.text}>{translate("imprint.serviceproviders")} § 5 {translate("imprint.telemedialaw")} (TMG):</Typography>
          <Typography variant="body2" className={classes.text}>Jacobs Douwe Egberts Deutschland GmbH</Typography>
          <Typography variant="body2" className={classes.text}>Langemarckstraße 4 – 20</Typography>
          <Typography variant="body2" className={classes.text}>28199 Bremen {translate("imprint.country")}</Typography>
          <Typography variant="body2" className={classes.text}>+49 (0)421 42789-0</Typography>
          <Typography variant="body2" className={classes.text}>{translate("imprint.managingdirector")}: Luc Van Gorp, Andreas Kaschner, Andreas Windler, Gerald Hammer, Andreas Zeitlhofer, Ulrich Ansteeg</Typography>
          <Typography variant="body2" className={classes.text}>{translate("imprint.chairman")}: Bernd Dreymüller</Typography>
          <Typography variant="body2" className={classes.text}>{translate("imprint.commercialregister")}: {translate("imprint.countycourt")} Bremen {translate("imprint.country")},  HRB-Nr. 30464 HB</Typography>
          <Typography variant="body2" className={classes.text}>{translate("imprint.vat")}.: DE122790370</Typography>
          
          <Typography variant="body2" className={classes.text}>&nbsp;</Typography>

          <Typography variant="body2" className={classes.text}>{translate("imprint.contactperson")}</Typography>
          <Typography variant="body2" className={classes.text}>Dorothea Beintken</Typography>
          <Typography variant="body2" className={classes.text}>dorothea.beintken@jdecoffee.com</Typography>

          <Typography variant="body2" className={classes.text}>&nbsp;</Typography>

          <Typography variant="body2" className={classes.text}>Jacobs Douwe Egberts CH GmbH</Typography>
          <Typography variant="body2" className={classes.text}>Thurgauerstr. 107</Typography>
          <Typography variant="body2" className={classes.text}>8152 Glattpark</Typography>
          <Typography variant="body2" className={classes.text}>{translate("imprint.switzerland")}</Typography>

          <Typography variant="body2" className={classes.text}>&nbsp;</Typography>
          
          <Typography variant="body2" className={classes.text}>Jacobs Douwe Egberts AT GmbH</Typography>
          <Typography variant="body2" className={classes.text}>Technologiestraße 5</Typography>
          <Typography variant="body2" className={classes.text}>1120 Wien</Typography>
          <Typography variant="body2" className={classes.text}>{translate("imprint.austria")}</Typography>

          <p>&nbsp;</p>

        </Card>
        <Footer layout="static" active="imprint" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authentication.loggedIn,
    lang: state.lang,
    localize: state.localize,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    uiSetLang: (lang) => dispatch(uiSetLang(lang)),
    // logout: () => dispatch(userActions.logout()),
    // login: (username,password) => dispatch(userActions.login(username,password)),
  };
};

// const Login = withStyles(styles)(ImprintView);
// export { Login }

const connectedImprintPage =
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withLocalize
  )(ImprintView)

export { connectedImprintPage as Imprint }; 