import { cartConstants } from '../_constants';
// export function cart(state = {}, action) {
//   switch(action.type) {
//     case cartConstants.CART_HAS_ERRORED:
//       return action.hasErrored;
//     case cartConstants.CART_IS_LOADING:
//       return action.isLoading;
//     case cartConstants.CART_FETCH_DATA_SUCCESS:
//       return action.cart;

//     case cartConstants.CART_GET_ONE_SUCCESS:
//       return action.cart;
//     case cartConstants.CART_GET_ALL_SUCCESS:
//       return action.cart;
//     case cartConstants.CART_ADD_ONE_SUCCESS:
//       return action.cart;
//     case cartConstants.CART_DEL_ONE_SUCCESS:
//       return action.cart;
//     case cartConstants.CART_CLEAR_SUCCESS:
//       return action.cart;
//     case cartConstants.CART_SEND_SUCCESS:
//       return action.cart;
//     case cartConstants.CART_DOWNLOAD_SUCCESS:
//       return action.cart;
//     default:
//       return state;
//   }
// }

export function cartHasErrored(state = false, action) {
  switch (action.type) {
    case cartConstants.CART_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function cartIsLoading(state = false, action) {
  switch (action.type) {
    case cartConstants.CART_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function cartDownloadIsLoading(state = false, action) {
  switch (action.type) {
    case cartConstants.CART_DOWNLOAD_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function cartDownloadListIsLoading(state = false, action) {
  switch (action.type) {
    case cartConstants.CART_DOWNLOAD_LIST_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function cart(state = { items: [] }, action) {
  switch (action.type) {
    case cartConstants.CART_FETCH_DATA_SUCCESS:
      return action.cart;
    case cartConstants.CART_ADD_ONE_SUCCESS:
      return action.cart;
    case cartConstants.CART_ADD_MANY_SUCCESS:
      return action.cart;
    case cartConstants.CART_DEL_MANY_SUCCESS:
      return action.cart;
    case cartConstants.CART_DEL_ONE_SUCCESS:
      return action.cart;
    case cartConstants.CART_CLEAR_SUCCESS:
      return action.cart;
    default:
      return state;
  }
}

export function addItemToCart(state = false, action) {
  switch (action.type) {
    case cartConstants.CART_ADD_ONE_SUCCESS:
      return action.cart;
    default:
      return state;
  }
}

export function delItemFromCart(state = false, action) {
  switch (action.type) {
    case cartConstants.CART_DEL_ONE_SUCCESS:
      return action.cart;
    default:
      return state;
  }
}

export function cartSent(state = false, action) {
  switch (action.type) {
    case cartConstants.CART_SEND_SUCCESS:
      return action.cartSent;
    default:
      return state;
  }
}

export function cartCheckboxState(state = [], action) {
  switch (action.type) {
    case cartConstants.CART_UPDATE_CHECKBOXES:
      return action.cartCheckboxState;
    case cartConstants.CART_ADD_ALL_FORMAT:
      return Object.assign({}, state, [action.cartCheckboxState]);
    case cartConstants.CART_REMOVE_ALL_FORMAT:
      return Object.assign({}, state, [action.cartCheckboxState]);
    default:
      return state;
  }
}