import { nodeConstants } from '../_constants'

export function nodesHasErrored(state = false, action) {
  switch (action.type) {
    case nodeConstants.NODE_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function nodesIsLoading(state = false, action) {
  switch (action.type) {
    case nodeConstants.NODE_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function nodes(state = [], action) {
  switch (action.type) {
    case nodeConstants.NODE_FETCH_DATA_SUCCESS:
      let nodes = [];
      nodes[action.id] = action.nodes;
      return Object.assign({}, state, nodes);
    default:
      return state;
  }
}

export function rootId(state = 1, action) {
  switch (action.type) {
    case nodeConstants.NODE_SET_ROOT:
      return action.rootId;
    default:
      return state;
  }
}
