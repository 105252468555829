import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from "react-localize-redux";
import { compose } from "redux";
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import GridOnIcon from '@material-ui/icons/GridOn';
import ListIcon from '@material-ui/icons/List';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import Button from '@material-ui/core/Button';

// REDUX
import { uiGetView, uiSetView, cartAddManyFormat, cartRemoveManyFormat } from '../../_actions/'
import { connect } from 'react-redux';


const styles = theme => ({
  root: {
    margin: theme.spacing.unit,
    // padding: theme.spacing.unit,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing.unit,
    width: '100%',
  },
  button: {
    color: theme.palette.primary.light
    // margin: theme.spacing.unit,
  },
  viewActive: {
    color: theme.palette.primary.dark
  }
});

class StatusbarComponent extends Component {
  
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleCartClick = this.handleCartClick.bind(this);
  }

  componentDidMount(){
    this.props.uiGetView();
    // let view = localStorage.getItem('view');
    // this.setState({ view });
  }

  handleClick(view) {
    // this.setState({ view }); 
    // localStorage.setItem("view", view);
    this.props.uiSetView(view);
  }

  handleCartClick(action){
    const { search, searchActive, searchFilter, assets, cart } = this.props;
    const isInCart = (id) => {
      for(let item of cart.cartItems){
        if(parseInt(item.id) === parseInt(id))
          return true;
      }
      return false;
    }
    const items = searchActive ? search.results : assets;

    let displayedResults = searchActive ? items.filter((item) => {
      return searchFilter.includes(item.rootId)// && parseInt(item.countryId) === rootId; 
    }) : assets;

    // displayedResults = items.filter((item) => {
    //   return searchFilter.includes(item.rootId)// && parseInt(item.countryId) === rootId; 
    // });

    let assetIds = action === 'add' ? displayedResults.map(i => i.id).filter(i => !isInCart(i)) : displayedResults.map(i => i.id).filter(i => isInCart(i));
    
    if(action === 'add')
      this.props.cartAddManyFormat(assetIds);
    else if(action === 'remove')
      this.props.cartRemoveManyFormat(assetIds);

    // console.log(assetIds);
  }

  render(){
    const { classes, view, statusText, translate, searchActive, assets, container, nodes, lang, rootId } = this.props;
    const blank = !!(this.props.blank)
    
    const containerName = () => {
      if(!nodes[rootId])
        return "";
      const c = nodes[rootId].filter(node => node.id === container);
      if(c[0])
        return c[0].name[lang] ? c[0].name[lang] : c[0].name["de"];
      return "";
    }

    // const { view } = this.state;
    return (
      <Paper className={classes.root}>
        <Tooltip title={translate("statusbar.gridview")} placement="bottom">
          <Button size="small" onClick={() => this.handleClick('grid')} className={(view === "grid") ? classes.viewActive : classes.button} aria-label={translate("statusbar.gridview")}>
            <GridOnIcon/>
          </Button>
        </Tooltip>

        <Tooltip title={translate("statusbar.listview")} placement="bottom">
          <Button size="small" onClick={() => this.handleClick('list')} className={(view === "list") ? classes.viewActive : classes.button} aria-label={translate("statusbar.listview")}>
            <ListIcon/>
          </Button>
        </Tooltip>

        <Tooltip title={translate("statusbar.addall")} placement="bottom">
          <Button size="small" onClick={() => this.handleCartClick('add')} className={classes.button} aria-label={translate("statusbar.addall")}>
            <AddShoppingCartIcon/>
          </Button>
        </Tooltip>

        <Tooltip title={translate("statusbar.removeall")} placement="bottom">
          <Button size="small" onClick={() => this.handleCartClick('remove')} className={classes.button} aria-label={translate("statusbar.removeall")}>
            <RemoveShoppingCartIcon/>
          </Button>
        </Tooltip>

        { (searchActive && !blank) ? 
          <span>{statusText}</span> :
          assets.length > 0 ?
          <span>{`${assets.length} ${translate("statusbar.assets")} in ${containerName()}`}</span> : null
        }
      </Paper>
    );
  }
}

StatusbarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapDispatchToProps = (dispatch) => {
  return {
    uiGetView: () => dispatch(uiGetView()),
    uiSetView: (view) => dispatch(uiSetView(view)),
    cartAddManyFormat: (assetIds, mediaType) => dispatch(cartAddManyFormat(assetIds, mediaType)),
    cartRemoveManyFormat: (assetIds, mediaType) => dispatch(cartRemoveManyFormat(assetIds, mediaType)),

  };
};

const mapStateToProps = state => {
  return {
    isLoading: state.isLoading,
    // delItemFromCart: state.delItemFromCart,
    assets: state.assets,
    container: state.container,
    cart: state.cart,
    nodes: state.nodes,
    view: state.view,
    lang: state.lang,
    search: state.search,
    searchFilter: state.searchFilter,
    searchActive: state.searchActive,
    rootId: state.rootId,
    // isLoading: state.assetsIsLoading,
    // assetsHasErrored: state.assetsHasErrored,
  }
}

let Statusbar = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withLocalize
)(StatusbarComponent);

export { Statusbar }