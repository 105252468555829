import { snackConstants } from '../_constants'

const initialState = {
  msg: "",
  open: false,
  variant: "info"
}

export function snack(state = initialState, action) {
  switch (action.type) {
    case snackConstants.SNACK_CLEAR:
      return Object.assign({}, initialState, { variant: action.variant })
    case snackConstants.SNACK_SHOW:
      return action.snack
    
    default:
      return state;
  }
}
