// modules
import React, { Component } from 'react';

// MUI
// import Button from '@material-ui/core/Button';

// REDUX
import { connect } from 'react-redux';
import {
  messageGetAll,
  // messageAdd,
} from '../../_actions/';

import { withLocalize } from "react-localize-redux";

// LOCALS
import { MessageExpansionPanel } from '../../components/MessageExpansionPanel';
import { Headline } from '../../components/Headline';

class MessagesView extends Component {
  componentDidMount(){
    document.title = `JDE mediaBOX | ${this.props.translate("messages.title")}`;
    this.props.messageGetAll();
  }

  handleClick(){
    this.props.messageAdd({ id: 1, date: 'today' })
  }

  render() {
    const { messages, translate } = this.props;
    return(
      <div className='root'>
        <Headline>{translate("messages.title")}</Headline>
        <MessageExpansionPanel>
          {messages}
        </MessageExpansionPanel>
      </div>
    )    
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    messageGetAll: () => dispatch(messageGetAll()),
    // messageAdd: (message) => dispatch(messageAdd(message)),
  };
};

const mapStateToProps = state => {
  return {
    messages: state.message.messages,
  }
}

const Messages = withLocalize(connect(mapStateToProps, mapDispatchToProps)(MessagesView));

export { Messages }